import { useQuery } from "react-query";
import createEndpointBase from "../../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../../context/notification-context";
import { useBoardContext } from "../context/board-context";

const endPointBase = createEndpointBase();

export type Balance = {
  asset: string;
  free: number;
  locked: number;
};

export const useBalance = () => {
  const asset = useAccountContext();
  const board = useBoardContext();
  const query = `/api/bcs/client/balance/${board}/${asset}`;
  const notificationDispatch = useNotificationDispatch();
  return useQuery<Balance[]>(
    [query],
    () => endPointBase.load(query, endPointBase.createGetOptions()),
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const useAllBalance = () => {
  const query = `/api/bcs/client/balance`;
  const notificationDispatch = useNotificationDispatch();
  return useQuery<Balance[]>(
    [query],
    () => endPointBase.load(query, endPointBase.createGetOptions()),
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const useTotalBalance = () => {
  const board = useBoardContext();
  const query = `/api/bcs/client/total_balance/${board}`;
  const notificationDispatch = useNotificationDispatch();
  return useQuery<number>(
    [query],
    () => endPointBase.load(`${query}`, endPointBase.createGetOptions()),
    createErrorHandlingOptions(notificationDispatch)
  );
};

export type BalanceHistory = {
  id: number;
  createAt: Date;
  balance: number;
  benchmark: number;
};
