import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { formatNumber2 } from "../../../utils/format";
import { useAssetInfo, usePriceStream } from "../../services/trade-api";
import { Checkbox } from "@mui/material";

type Props = {
  onClose: () => void;
  onSubmit: (amount: number, price?: number, asset?: string) => void;
  isLoading: boolean;
  maxValue?: number;
  title: string;
  direction: "buy" | "sell";
  q: number;
};

const BaseOrderDialog = ({
  onClose,
  onSubmit,
  title,
  isLoading,
  maxValue = 0,
  direction,
  q,
}: Props) => {
  const [amount, setAmount] = useState(q.toString());
  const value = parseFloat(amount);
  const isValid = !isNaN(value) && value > 0;
  const currentPrice = usePriceStream();
  const { lotSize, step, buyCollateral, sellCollateral, asset } = useAssetInfo()
    .data || {
    lotSize: 0,
    step: 1,
  };
  const [market, setMarket] = useState(false);
  const [price, setPrice] = useState<string>(currentPrice.toString());

  const collateral =
    (direction === "buy" && buyCollateral) ||
    (direction === "sell" && sellCollateral) ||
    null;

  const selectedPrice = market ? currentPrice : parseFloat(price);

  useEffect(() => {
    if (!parseFloat(price) && currentPrice) {
      setPrice(currentPrice.toString());
    }
  }, [price, currentPrice]);

  const submit = () => {
    onSubmit(value, !market ? selectedPrice : undefined, asset);
  };
  return (
    <Box sx={{ width: 600, color: (theme) => theme.palette.grey[400] }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          flexGrow: 1,
          flexShrink: 1,
          flex: 1,
        }}
      >
        {!market && (
          <FormControl
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              flex: 1,
            }}
          >
            <TextField
              label={`Цена`}
              autoFocus
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              inputProps={{
                step: step.toString(),
              }}
              error={!isValid}
              type="number"
              margin="dense"
            />
          </FormControl>
        )}
        <FormControl
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            flex: 1,
          }}
        >
          <TextField
            label={`Количество (≈${formatNumber2(
              collateral === null
                ? value * selectedPrice * lotSize
                : value * collateral
            )})`}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            inputProps={{
              step: q.toString(),
            }}
            error={!isValid}
            type="number"
            margin="dense"
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={market}
            onChange={(e) => setMarket(e.target.checked)}
          />
          <Box>Рыночная заявка</Box>
        </Box>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Отмена
        </Button>
        <Button
          disabled={isLoading || !isValid || !selectedPrice}
          onClick={submit}
        >
          {title}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default BaseOrderDialog;
