import { useMaxRepayable, useRepay } from "../../services/margin";
import BaseRepayDialog from "./base-repay-dialog";

type Props = {
  onClose: () => void;
  asset: string;
};

const MarginRepayDialog = ({ onClose, asset }: Props) => {
  const repay = useRepay(onClose);
  const maxValueResponse = useMaxRepayable(asset);
  return (
    <BaseRepayDialog
      onClose={onClose}
      asset={asset}
      repay={repay.execute}
      maxValue={maxValueResponse.result || 0}
      isLoading={repay.loading || maxValueResponse.loading}
    />
  );
};

export default MarginRepayDialog;
