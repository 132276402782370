import { useTradeKind } from "../services/trade-api";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const useTradingRedirect = () => {
  const kindResponse = useTradeKind();

  const navigate = useNavigate();

  useEffect(() => {
    if (kindResponse.data?.kind) {
      navigate(`/binance/trading/${kindResponse.data.kind}`);
    }
  }, [kindResponse.data?.kind, navigate]);
};
