import Box from "@mui/material/Box";
import fallbackUrl from "./unknown.svg";

type Props = {
  asset: string;
  size?: number | string;
};

const AssetIcon = ({ asset, size }: Props) => {
  const adjustedIcon = asset.replace(/^LD/, "").toLowerCase();
  return (
    <Box
      sx={{
        width: size,
        height: size,
        backgroundSize: "contain",
        borderRadius: "50%",
        backgroundImage: `url(/cryptocurrency-icons/${adjustedIcon}),
          url(https://cdn.jsdelivr.net/npm/cryptocurrency-icons@0.18.1/svg/icon/${adjustedIcon}.svg),
          url(${fallbackUrl})`,
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};

export default AssetIcon;
