import camelCase from "camelcase-keys";
import snakeCase from "snakecase-keys";

type Headers = {
  Accept?: string;
  "Content-Type"?: string;
};

const createErrorMessage = (json: any) => {
  if (
    json &&
    json.errors &&
    Array.isArray(json.errors) &&
    json.errors.length > 0
  ) {
    return json.errors.join(". ");
  }
  return "Неизвестная ошибка";
};

const buildUrl = (base: string, params: Array<[string, string]> = []) => {
  if (params.length > 0) {
    return `${base}?${new URLSearchParams(params).toString()}`;
  }
  return base;
};

const tryParseResponseJSON = async (response: Response) => {
  try {
    if (response.status !== 204) {
      const json = await response.json();
      return json;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const createEndpointBase = () => {
  const createHeaders = () => {
    const headers: Headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return { headers };
  };

  const createGetOptions = () => {
    return createHeaders();
  };

  const createPostOptions = (fields?: Record<string, any>) => {
    const headers = createHeaders();

    const options: any = {
      ...headers,
      method: "POST",
    };

    if (fields) {
      options.body = JSON.stringify(snakeCase(fields, { deep: true }));
    }

    return options;
  };

  const createPostFormOptions = (form: FormData) => {
    const headers = createHeaders();

    delete headers.headers["Content-Type"];

    const options: any = {
      ...headers,
      method: "POST",
      body: form,
    };

    return options;
  };

  const createDeleteOptions = () => {
    const headers = createHeaders();

    return {
      ...headers,
      method: "DELETE",
    };
  };

  const createPutOptions = (fields: any) => {
    const headers = createHeaders();

    return {
      ...headers,
      method: "PUT",
      body: JSON.stringify(fields),
    };
  };

  const createPatchOptions = (fields: any) => {
    const headers = createHeaders();

    return {
      ...headers,
      method: "PATCH",
      body: JSON.stringify(snakeCase(fields, { deep: true })),
    };
  };

  const load = async (url: RequestInfo, options?: any) => {
    const response = await fetch(url, options);

    if (!response.ok) {
      const json = await tryParseResponseJSON(response);
      throw new Error(createErrorMessage(json));
    }

    const json: any = await tryParseResponseJSON(response);
    if (!json) {
      return json;
    }
    return camelCase(json, { deep: true });
  };

  return {
    buildUrl,
    createGetOptions,
    createPostOptions,
    createPostFormOptions,
    createPutOptions,
    createPatchOptions,
    createDeleteOptions,
    load,
  };
};

export default createEndpointBase;
