import { useMaxTransferable, useWithdraw } from "../../services/margin";
import BaseWithdrawDialog from "./base-withdraw-dialog";

type Props = {
  onClose: () => void;
  asset: string;
};

const MarginWithdrawDialog = ({ onClose, asset }: Props) => {
  const withdraw = useWithdraw(onClose);
  const maxValueResponse = useMaxTransferable(asset);
  return (
    <BaseWithdrawDialog
      onClose={onClose}
      asset={asset}
      withdraw={withdraw.execute}
      maxValue={maxValueResponse.result || 0}
      isLoading={withdraw.loading || maxValueResponse.loading}
    />
  );
};

export default MarginWithdrawDialog;
