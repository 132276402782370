import BaseLoanDialog from "./base-loan-form";

type Props = {
  onClose: () => void;
  asset: string;
  withdraw: (asset: string, amount: number) => Promise<any>;
  isLoading: boolean;
  maxValue: number;
};

const BaseWithdrawDialog = ({
  onClose,
  asset,
  isLoading,
  withdraw,
  maxValue,
}: Props) => {
  return (
    <BaseLoanDialog
      title={`Вывести ${asset}`}
      onSubmit={(amount) => {
        withdraw(asset, amount);
      }}
      maxValue={maxValue}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default BaseWithdrawDialog;
