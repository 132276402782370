import Box from "@mui/material/Box";
import Trades from "../trades";
import BalanceTable from "./balance-table";
import { Wallet } from "../model";

type Props = {
  wallet: Wallet;
  q: number;
};

export const TradesBalance = ({ wallet, q }: Props) => {
  return (
    <Box
      style={{ display: "grid", gridTemplateColumns: "1fr 300px", flexGrow: 1 }}
    >
      <Trades q={q} wallet={wallet} />
      <BalanceTable wallet={wallet} />
    </Box>
  );
};

export default TradesBalance;
