import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";

const DefaultPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("binance/trading/algorithm2");
  }, [navigate]);
  return (
    <Layout navigation={() => <div />}>
      <main style={{ padding: "1rem" }}>
        <p>There's nothing here!</p>
      </main>
    </Layout>
  );
};

export default DefaultPage;
