import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { formatNumber5, formatDateColumn, formatNumber0 } from "./utils/format";
import { DataItem, Total } from "./services/algorithm2";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const columns = [
  { field: "k", headerName: "k", width: 90 },
  { field: "n", headerName: "N", width: 90 },
  { field: "i", headerName: "i", width: 90 },
  { field: "m", headerName: "m", width: 90 },
  {
    field: "t",
    headerName: "T",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },

  {
    field: "pok",
    headerName: "POK",
    type: "number",
    width: 80,
  },
  {
    field: "pr",
    headerName: "PR",
    type: "number",
    width: 100,
  },
  {
    field: "kpok",
    headerName: "KPOK",
    type: "number",
    width: 100,
  },
  {
    field: "kpr",
    headerName: "KPR",
    type: "number",
    width: 100,
  },
  {
    field: "bb",
    headerName: "BB",
    type: "number",
    valueFormatter: (params: any) => {
      if (typeof params.value !== "number") {
        return "";
      }
      return formatNumber5(params.value);
    },
    width: 110,
  },
  {
    field: "bv",
    headerName: "BV",
    type: "number",
    width: 110,
  },
];

type Props = {
  data: DataItem[];
  total?: Total;
  loading: boolean;
};

const Algorithm2AnalysisTable = ({ data, total, loading }: Props) => {
  return (
    <Box
      style={{
        flexGrow: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGrid
        getRowId={(x) => x.n}
        rows={data}
        columns={columns}
        disableSelectionOnClick
        loading={loading}
      />
      {total ? (
        <Paper sx={{ display: "flex", gap: 3, padding: 2 }}>
          <Typography variant="body1">
            REZ: {formatNumber0(total.rez)}
          </Typography>
          <Typography variant="body1">Z: {formatNumber0(total.z)}</Typography>
          <Typography variant="body1">MM: {formatNumber0(total.mm)}</Typography>
          <Typography variant="body1">
            Rent: {formatNumber0(total.rent)}
          </Typography>
          <Typography variant="body1">
            BBкон: {formatNumber5(total.lastBb)}
          </Typography>
          <Typography variant="body1">
            BVкон: {formatNumber0(total.lastBv)}
          </Typography>
          <Typography variant="body1">
            Xкон: {formatNumber0(total.lastX)}
          </Typography>
        </Paper>
      ) : null}
    </Box>
  );
};

export default Algorithm2AnalysisTable;
