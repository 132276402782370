import { useQuery, useQueryClient } from "react-query";
import createEndpointBase from "../../api/utils/endpoint-base";
import { useAsyncCallback } from "react-async-hook";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../../context/notification-context";
import { useBoardContext } from "../context/board-context";
const endPointBase = createEndpointBase();

export type Order = {
  id: number;
  createdAt: Date;
  price: number;
  direction: number;
  description: string;
  asset: string;
  account: string;
  transId: number;
  classCode: string;
  balance: number;
  status: number;
  stopPrice: number | null;
};

export const useOrders = (date: string, endDate: string, asset?: string) => {
  const board = useBoardContext();
  const key = `/api/bcs/orders/data/${
    asset || ""
  }?date=${date}&end=${endDate}&board=${board}`;

  return useQuery<Order[]>(key, async () => {
    return endPointBase.load(`${key}`, endPointBase.createGetOptions());
  });
};

export const useCancelOrder = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const client = useQueryClient();

  return useAsyncCallback(
    async (orderNumber: number) => {
      const key = `/api/bcs/orders/cancel/${orderNumber}`;
      return endPointBase.load(key, endPointBase.createDeleteOptions());
    },
    {
      onSuccess: () => {
        notificationDispatch({ type: "success", payload: "Заявка отменена" });
        setTimeout(() => {
          client.refetchQueries({
            predicate: (q) => {
              return q.queryKey.includes("bcs/orders/data");
            },
          });
        }, 1000);
        onSuccess();
      },
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};
