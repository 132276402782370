import Alert from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box/Box";
import { useCallback, useEffect } from "react";
import {
  NotificationType,
  useNotificationDispatch,
  useNotificationState,
} from "../context/notification-context";

const Notifications: React.FC = () => {
  const { message, type } = useNotificationState();
  const notificationDispatch = useNotificationDispatch();

  const handleClose = useCallback(() => {
    notificationDispatch({ type: "reset" });
  }, [notificationDispatch]);

  useEffect(() => {
    if (type === NotificationType.success) {
      const timeout = setTimeout(handleClose, 5000);
      return () => clearTimeout(timeout);
    }
  }, [type, handleClose]);

  if (!message) {
    return null;
  }

  const kind =
    type === NotificationType.success
      ? NotificationType.success
      : NotificationType.error;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        zIndex: 10000,
        width: "100%",
      }}
    >
      <Alert severity={kind} onClose={handleClose}>
        {message}
      </Alert>
    </Box>
  );
};

export default Notifications;
