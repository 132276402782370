import Box from "@mui/material/Box";

import { useAssetHistory } from "../../services/balance-api";

import ChartCore, {
  ArgumentAxis,
  Legend,
  IVisualRangeProps,
  ZoomAndPan,
  Tooltip,
  CommonSeriesSettings,
  Series,
} from "devextreme-react/chart";
import { useMemo, useState } from "react";
import { isToday } from "date-fns";
import PortfolioTooltipTemplate from "./portfolio-tooltip-template";
import { useInteractionContext } from "../../../context/interaction-context";

const minVisualRangeLength = { minutes: 10 };

export const AssetHistoryChart = () => {
  const { from, to } = useInteractionContext();
  const history = useAssetHistory();

  const endDate = new Date(to);

  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  const [visualRange, setVisualRange] = useState<IVisualRangeProps>({
    startValue: from,
    endValue: isToday(new Date(to))
      ? new Date().toString()
      : endDate.toString(),
  });

  const defaultVisualRange = useMemo(() => ({ startValue: from }), [from]);

  return (
    <Box
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          flexGrow: 1,
        }}
      >
        <ChartCore
          height="100%"
          dataSource={history.data || []}
          autoHidePointMarkers={false}
        >
          <CommonSeriesSettings
            argumentField="createdAt"
            valueField="amount"
            type="area"
          ></CommonSeriesSettings>
          <Series name={history.data?.at(0)?.asset || "no-data"} />

          <ZoomAndPan argumentAxis="both" />
          <ArgumentAxis
            defaultVisualRange={defaultVisualRange}
            minVisualRangeLength={minVisualRangeLength}
            visualRange={visualRange}
            onVisualRangeChange={setVisualRange}
            argumentType="datetime"
          />
          <Legend
            visible={true}
            verticalAlignment="bottom"
            horizontalAlignment="center"
          />
          <Tooltip enabled contentRender={PortfolioTooltipTemplate} />
        </ChartCore>
      </Box>
    </Box>
  );
};
