import Algorithm2AnalysisTable from "./algorithm2-analysis-table";
import { useAlgorithm2TradeDebug } from "./services/algorithm2";

export const Algorithm2TradeAnalysisTable = () => {
  const response = useAlgorithm2TradeDebug();
  return (
    <Algorithm2AnalysisTable
      data={response.data?.table || []}
      total={response.data?.total}
      loading={response.isLoading}
    />
  );
};

export default Algorithm2TradeAnalysisTable;
