import React from "react";

export enum Board {
  TQBR = "stocks",
  SPBFUT = "futures",
}

const BoardContext = React.createContext(Board.TQBR);

type Props = {
  board: Board;
};

export const BoardContextProvider: React.FC<Props> = ({ children, board }) => {
  return (
    <BoardContext.Provider value={board}>{children}</BoardContext.Provider>
  );
};

export function useBoardContext() {
  return React.useContext(BoardContext);
}
