import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import withAuthenticator from "./components/auth/with-authenticator";
import { getAppSettings, setAppSettings } from "./storage";

export const useStoreLocation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/") {
      setAppSettings({ lastPage: location.pathname });
    }
  }, [location.pathname]);

  useEffect(() => {
    const { lastPage } = getAppSettings();
    if (lastPage) {
      navigate(lastPage);
    }
  }, [navigate]);
};

const IndexPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const { lastPage } = getAppSettings();
    if (!lastPage) {
      navigate("binance/trading/algorithm2");
    }
  }, [navigate]);
  return <Outlet />;
};

export default withAuthenticator(IndexPage);
