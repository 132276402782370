import Box from "@mui/material/Box";
import Up from "@mui/icons-material/ArrowUpward";
import Down from "@mui/icons-material/ArrowDownward";

const RenderMarginCell = (params: {
  value?: number;
  formattedValue?: string;
}) => {
  const { value, formattedValue } = params;
  const margin = value || 0;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",

        color: (theme) =>
          margin < 0
            ? theme.palette.error[theme.palette.mode]
            : theme.palette.success[theme.palette.mode],
      }}
    >
      <Box>{formattedValue}</Box>
      {margin < 0 ? <Down /> : <Up />}
    </Box>
  );
};

export default RenderMarginCell;
