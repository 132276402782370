import {
  useIsolatedMaxTransferable,
  useIsolatedWithdraw,
} from "../../services/isolated-margin";
import BaseWithdrawDialog from "./base-withdraw-dialog";

type Props = {
  onClose: () => void;
  asset: string;
};

const IsolatedMarginWithdrawDialog = ({ onClose, asset }: Props) => {
  const withdraw = useIsolatedWithdraw(onClose);
  const maxValueResponse = useIsolatedMaxTransferable(asset);
  return (
    <BaseWithdrawDialog
      onClose={onClose}
      asset={asset}
      withdraw={withdraw.execute}
      maxValue={maxValueResponse.result || 0}
      isLoading={withdraw.loading || maxValueResponse.loading}
    />
  );
};

export default IsolatedMarginWithdrawDialog;
