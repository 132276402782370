import ChartCore, {
  Aggregation,
  ArgumentAxis,
  Legend,
  LoadingIndicator,
  Series,
  IVisualRangeProps,
  ZoomAndPan,
  Tooltip,
  Point,
  Animation,
} from "devextreme-react/chart";
import { OHLC, useOHLC } from "../services/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { formatISO, isToday } from "date-fns";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CandlestickChart from "@mui/icons-material/CandlestickChart";
import LineChart from "@mui/icons-material/SsidChartSharp";
import { usePriceStream } from "../services/trade-api";
import useDebounce from "../hooks/use-debounce";
import { MINUTE, MONTH, WEEK } from "../utils/constants";
import { usePriceFormatter } from "../binance/hooks/use-price-formatter";

const AggregationComponent: any = Aggregation;
const ChartComponent: any = ChartCore;
const SeriesComponent: any = Series;

const LoadingIndicatorComponent: any = LoadingIndicator;
const LegendComponent: any = Legend;
const ZoomAndPanComponent: any = ZoomAndPan;
const TooltipComponent: any = Tooltip;
const PointComponent: any = Point;
const AnimationComponent: any = Animation;

type Props = {
  date: string;
  endDate: string;
};

const MIN_ARGUMENT_RANGE = { hours: 1 };

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { padding: 0, margin: 0 },
  };
}

function TooltipTemplate(
  pointInfo: any,
  formatPrice: (value: number) => string
) {
  const isCandle =
    pointInfo.openValue ||
    pointInfo.highValue ||
    pointInfo.lowValue ||
    pointInfo.closeValue;

  return isCandle ? (
    <div className="tooltip-template">
      <div>{pointInfo.argumentText}</div>
      <div>
        <span>Open: </span>
        {formatPrice(pointInfo.openValue)}
      </div>
      <div>
        <span>High: </span>
        {formatPrice(pointInfo.highValue)}
      </div>
      <div>
        <span>Low: </span>
        {formatPrice(pointInfo.lowValue)}
      </div>
      <div>
        <span>Close: </span>
        {formatPrice(pointInfo.closeValue)}
      </div>
    </div>
  ) : (
    <div className="tooltip-template">
      <div>{pointInfo.argumentText}</div>
      <div>
        <span>Price: </span>
        {formatPrice(pointInfo.value)}
      </div>
    </div>
  );
}

const calculateCandle = (e: {
  data: OHLC[];
  intervalStart: Date;
  intervalEnd: Date;
}) => {
  if (e.data.length) {
    return {
      createdAt: new Date(
        (e.intervalStart.valueOf() + e.intervalEnd.valueOf()) / 2
      ),
      o: e.data[0].o,
      h: Math.max.apply(
        null,
        e.data.map(({ h }) => h)
      ),
      l: Math.min.apply(
        null,
        e.data.map(({ l }) => l)
      ),
      c: e.data[e.data.length - 1].c,
    };
  }
  return null;
};

const getRange = (startDate: string, endDate: string) => {
  const endValue = new Date(endDate);
  endValue.setDate(endValue.getDate() + 1);

  return {
    startValue: startDate,
    endValue: formatISO(endValue, { representation: "date" }),
  };
};

const getInterval = ({
  startValue,
  endValue,
}: {
  startValue: string;
  endValue: string;
}): string => {
  const length =
    new Date(endValue || 0).getTime() - new Date(startValue || 0).getTime();

  const preferredInterval = length / 15;

  if (preferredInterval < 5 * MINUTE) {
    return "1";
  }
  if (preferredInterval < 15 * MINUTE) {
    return "5";
  }

  if (preferredInterval < 30 * MINUTE) {
    return "15";
  }

  if (preferredInterval < 60 * MINUTE) {
    return "30";
  }

  if (preferredInterval < 120 * MINUTE) {
    return "60";
  }

  if (preferredInterval < 240 * MINUTE) {
    return "120";
  }

  if (preferredInterval < 360 * MINUTE) {
    return "240";
  }

  if (preferredInterval < WEEK) {
    return "D";
  }

  if (preferredInterval < MONTH) {
    return "W";
  }

  return "M";
};

const Chart = ({ date, endDate }: Props) => {
  const endValue = new Date(endDate);
  endValue.setDate(endValue.getDate() + 1);
  const [visualRange, setVisualRange] = useState<IVisualRangeProps>(
    getRange(date, endDate)
  );

  const wholeRange = useMemo(() => getRange(date, endDate), [date, endDate]);

  const memoRange = useMemo(() => {
    return {
      startValue: formatISO(new Date(visualRange.startValue), {
        representation: "complete",
      }),
      endValue: formatISO(new Date(visualRange.endValue), {
        representation: "complete",
      }),
      interval: getInterval({
        startValue: visualRange.startValue,
        endValue: visualRange.endValue,
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualRange.startValue.toString(), visualRange.endValue.toString()]);

  const range = useDebounce(memoRange, 300);

  const data = useOHLC(range.startValue, range.endValue, range.interval);

  const price = usePriceStream();
  const formatPrice = usePriceFormatter();

  const today = useMemo(() => isToday(new Date(endDate)), [endDate]);

  const [store, setStore] = useState(
    new CustomStore({
      load: () => data.result || [],
      key: "key",
    })
  );

  useEffect(() => {
    const endTime = new Date(visualRange.endValue).getTime();
    const now = new Date().getTime();

    if (price && endTime > now && !data.loading) {
      setStore((store) => {
        store.push([
          {
            type: "insert",
            key: new Date().toString(),
            data: {
              createdAt: new Date(),
              o: price,
              h: price,
              l: price,
              c: price,
              ticks: 1,
            },
          },
        ]);
        return store;
      });
    }
  }, [price, visualRange.endValue, data.loading]);

  useEffect(() => {
    if (data.result && !data.loading) {
      const newStore = new CustomStore({
        load: () => data.result || [],
        key: "key",
      });
      setStore(() => newStore);
      newStore.load();
    }
  }, [data.result, data.loading]);

  const renderTooltip = useCallback(
    (pointInfo: any) => {
      return TooltipTemplate(pointInfo, formatPrice);
    },
    [formatPrice]
  );

  const [tabValue, setTabValue] = useState(0);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
      <Tabs
        value={tabValue}
        onChange={(_event, newValue) => {
          setTabValue(newValue);
        }}
        aria-label="chart tabs"
        sx={{ padding: 0, margin: 0 }}
      >
        <Tab {...a11yProps(0)} icon={<LineChart />} />
        <Tab {...a11yProps(1)} icon={<CandlestickChart />} />
      </Tabs>
      <Box sx={{ flexGrow: 1 }}>
        <ChartComponent height="100%" dataSource={store}>
          <AnimationComponent enabled={false} />
          {tabValue === 0 && (
            <SeriesComponent
              argumentField="createdAt"
              valueField="c"
              type="line"
            >
              <PointComponent visible={false} />
              <AggregationComponent
                enabled={true}
                method="custom"
                calculate={calculateCandle}
              />
            </SeriesComponent>
          )}
          {tabValue === 1 && (
            <SeriesComponent
              argumentField="createdAt"
              openValueField="o"
              closeValueField="c"
              lowValueField="l"
              highValueField="h"
              type="CandleStick"
            >
              <AggregationComponent
                enabled={true}
                method="custom"
                calculate={calculateCandle}
              />
            </SeriesComponent>
          )}
          <ZoomAndPanComponent argumentAxis="both" />
          <ArgumentAxis
            minVisualRangeLength={MIN_ARGUMENT_RANGE}
            visualRange={visualRange}
            wholeRange={wholeRange}
            visualRangeUpdateMode="keep"
            onVisualRangeChange={setVisualRange}
            argumentType="datetime"
          />
          <LoadingIndicatorComponent enabled={true} />
          <LegendComponent visible={false} />
          <TooltipComponent
            enabled
            shared
            argumentFormat="shortDateShortTime"
            contentRender={renderTooltip}
          />
        </ChartComponent>
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2, marginTop: "auto" }}>
        <Typography variant="body1">
          Цена:{" "}
          {formatPrice(
            today ? price : data.result?.[data.result?.length - 1]?.c || 0
          )}
        </Typography>
      </Paper>
    </Box>
  );
};

export default Chart;
