import React, { useState } from "react";
import { getAppSettings, setAppSettings } from "../storage";

const AccountContext = React.createContext(1);

const AccountStateDispatchContext = React.createContext<React.Dispatch<number>>(
  () => {}
);

export const AccountContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(getAppSettings().accountId || 1);

  return (
    <AccountContext.Provider value={state}>
      <AccountStateDispatchContext.Provider
        value={(value) => {
          setState(value);
          setAppSettings({ accountId: value });
        }}
      >
        {children}
      </AccountStateDispatchContext.Provider>
    </AccountContext.Provider>
  );
};

export function useAccountContext() {
  return React.useContext(AccountContext);
}

export function useAccountDispatch() {
  return React.useContext(AccountStateDispatchContext);
}
