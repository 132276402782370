import Can from "../components/auth/can";
import Layout from "../Layout";
import { AccountContextProvider } from "./context/account-context";
import Navigation from "./navigation";
import { Outlet } from "react-router-dom";

const BcsApp = () => {
  return (
    <Can perform="bcs">
      <AccountContextProvider initValue="SBER" storeRecord="bcsAccount">
        <Layout navigation={<Navigation />}>
          {/* <BcsTradeApp /> */}
          <Outlet />
        </Layout>
      </AccountContextProvider>
    </Can>
  );
};

export default BcsApp;
