import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useAddBenchmarkValue } from "../services/balance-api";

type Props = {
  onClose: () => void;
};

const AddSurBenchmarkDialog = ({ onClose }: Props) => {
  const [value, setValue] = useState("");

  const add = useAddBenchmarkValue(onClose);

  return (
    <Box sx={{ width: 400 }}>
      <DialogTitle sx={{ color: (theme) => theme.palette.grey[400] }}>
        Добавить значение
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Значение"
          autoFocus
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          margin="dense"
        ></TextField>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Отмена
        </Button>
        <Button
          disabled={add.loading || isNaN(parseFloat(value))}
          onClick={() => {
            add.execute(parseFloat(value), "SUR");
          }}
        >
          Добавить
        </Button>
      </DialogActions>
    </Box>
  );
};

export default AddSurBenchmarkDialog;
