import { useRecharge } from "../../services/margin";
import BaseRechargeDialog from "./base-recharge-dialog";

type Props = {
  onClose: () => void;
  asset: string;
};

const MarginRechargeDialog = ({ onClose, asset }: Props) => {
  const recharge = useRecharge(onClose);
  return (
    <BaseRechargeDialog
      asset={asset}
      onClose={onClose}
      recharge={recharge.execute}
      isLoading={recharge.loading}
    />
  );
};

export default MarginRechargeDialog;
