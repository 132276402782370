import BaseLoanDialog from "./base-loan-form";

type Props = {
  onClose: () => void;
  asset: string;
  repay: (asset: string, amount: number) => Promise<any>;
  maxValue: number;
  isLoading: boolean;
};

const BaseRepayDialog = ({
  onClose,
  asset,
  repay,
  isLoading,
  maxValue,
}: Props) => {
  return (
    <BaseLoanDialog
      title={`Вернуть ${asset}`}
      onSubmit={(amount) => {
        repay(asset, amount);
      }}
      maxValue={maxValue}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default BaseRepayDialog;
