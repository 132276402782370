import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { capitalize } from "@mui/material/utils";
import { SxProps, Theme } from "@mui/material/styles";
import { Exchange } from "./constants";
import ExchangeIcon from "./exchange-icon";
import Box from "@mui/material/Box";

const TITLE = "Биржа";

const items = [Exchange.Binance, Exchange.ByBit];

type Props = {
  value: string;
  onValueChange: (value: string) => void;
  sx?: SxProps<Theme>;
};

const ExchangeSelector = ({ value, onValueChange, sx }: Props) => {
  return (
    <FormControl sx={sx}>
      <InputLabel focused variant="outlined">
        {TITLE}
      </InputLabel>
      <Select
        labelId="exchange"
        value={value}
        margin="dense"
        size="small"
        sx={{ height: 56 }}
        onChange={({ target: { value } }) => {
          if (typeof value === "string") {
            onValueChange(value);
          }
        }}
        input={<OutlinedInput label={TITLE} />}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <ExchangeIcon exchange={item} size={20} />
              <ListItemText primary={capitalize(item)} />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ExchangeSelector;
