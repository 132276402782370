import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCancelOrder } from "../services/orders-api";
import Cross from "@mui/icons-material/Cancel";

const RenderOrderStatusCell = (params: any) => {
  const cancel = useCancelOrder(() => {});
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Box>{params.formattedValue}</Box>
      {params.value === "NEW" ? (
        <Button
          onClick={() => {
            cancel.execute(params.row.orderId);
          }}
        >
          <Cross />
        </Button>
      ) : null}
    </Box>
  );
};

export default RenderOrderStatusCell;
