import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import Algorithm1Params, { useAlgorithm1Params } from "../../algorithm1-params";
import {
  useTradeState,
  useTradeStart,
  useTradeStop,
  useTradeParams,
} from "../../services/trade-api";
import TradeAnalysisTable from "../../algorithm1-trade-analysis-table";
import Chart from "../../components/chart";
import TextField from "@mui/material/TextField";
import BalanceHistoryChart from "../../components/balance-history-chart";
import { useAccountContext } from "../../context/account-context";
import { useTradingRedirect } from "../../hooks/use-trade-algorithm";
import OrdersBalance from "../../components/trades-balance";
import DateRangeSelector from "../../components/date-range-selector";
import { useInteractionContext } from "../../context/interaction-context";
import { Wallet } from "../../model";
import ConfirmDialog, {
  useConfirmDialog,
} from "../../components/confirm-dialog";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function App() {
  const { result: currentParams } = useTradeParams();
  const params = useAlgorithm1Params(currentParams);

  const [q, setQ] = useState("0.001");
  const stateResponse = useTradeState();
  const start = useTradeStart();
  const stop = useTradeStop();
  const accountId = useAccountContext();
  const { from, to } = useInteractionContext();

  const isStarted = stateResponse.data?.state !== 0;

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (currentParams?.q) {
      setQ(currentParams?.q.toString());
    }
  }, [currentParams?.q]);

  useTradingRedirect();

  const [confirmStopProps, openStopConfirmDialog] = useConfirmDialog(
    async () => {
      await stop.execute(accountId);

      await stateResponse.refetch();
    },
    "Остановить торговлю?"
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          marginBottom: 1,
        }}
      >
        <DateRangeSelector />
        <Algorithm1Params {...params} disabled={isStarted} />
        <TextField
          disabled={isStarted}
          label="q"
          type={"number"}
          value={q}
          onChange={(e) => setQ(e.target.value)}
          inputProps={{ step: "0.001" }}
        />
        <Button
          disabled={stateResponse.isLoading || start.loading || stop.loading}
          onClick={async () => {
            if (!isStarted) {
              await start.execute(
                Number(params.f),
                Number(params.a),
                Number(params.b),
                Number(q),
                accountId
              );
              await stateResponse.refetch();
            } else {
              openStopConfirmDialog();
            }
          }}
        >
          {isStarted ? "Остановить" : "Начать"}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          marginBottom: 1,
        }}
      ></Box>
      <Box sx={{ marginBottom: 1 }}>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="trading tabs"
        >
          <Tab label="Сделки" {...a11yProps(0)} />
          <Tab label="Анализ" {...a11yProps(1)} />
          <Tab label="График" {...a11yProps(2)} />
          <Tab label="Баланс" {...a11yProps(3)} />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <OrdersBalance q={parseFloat(q)} wallet={Wallet.SPOT} />
      )}
      {tabValue === 1 && <TradeAnalysisTable />}
      {tabValue === 2 && <Chart date={from} endDate={to} />}
      {tabValue === 3 && <BalanceHistoryChart date={from} endDate={to} />}

      <ConfirmDialog {...confirmStopProps} />
    </Box>
  );
}

export default App;
