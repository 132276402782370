import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useAuthenticate, useTwoFactorAuthCallback } from "../../services/auth";
import CircularProgress from "@mui/material/CircularProgress";

const LoginForm = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const authenticate = useAuthenticate();
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const twoFactorAuthEnabledCallback = useTwoFactorAuthCallback();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    await twoFactorAuthEnabledCallback.execute(login).then(setTwoFactorEnabled);

    await authenticate.execute({
      login,
      password,
    });
  };

  if (twoFactorEnabled && authenticate.loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Ожидание подтверждения входа
        <CircularProgress sx={{ marginLeft: "auto" }} size={20} />
      </Box>
    );
  }

  return (
    <form className="form" onSubmit={onSubmit}>
      <TextField
        id="login"
        label="Логин"
        autoFocus
        value={login}
        fullWidth
        type={"email"}
        margin="normal"
        size="small"
        onChange={(e) => setLogin(e.target.value)}
        required
      />
      <TextField
        id="password"
        label="Пароль"
        type={"password"}
        autoComplete="current-password"
        value={password}
        fullWidth
        size="small"
        margin="normal"
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          sx={{ marginLeft: "auto" }}
          disabled={authenticate.loading}
          type="submit"
          color="primary"
        >
          Войти
        </Button>
      </Box>
    </form>
  );
};

export default LoginForm;
