import { formatNumber0 } from "../../../utils/format";
import AssetIcon from "../asset-icon";
import Box from "@mui/material/Box";

export default function VolumeChartTooltipTemplate(info: any) {
  console.log(info);

  const buyPoint = info.points.find((p: any) => p.seriesName === "Buy");
  const sellPoint = info.points.find((p: any) => p.seriesName === "Sell");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.25rem",
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        <AssetIcon size={20} asset={info.argument} />
        <Box>{info.argument}</Box>
      </Box>

      {buyPoint && <div>Покупка: {formatNumber0(buyPoint.value)}</div>}
      {sellPoint && <div>Продажа: {formatNumber0(sellPoint.value)}</div>}
    </Box>
  );
}
