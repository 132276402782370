import ChartCore, {
  Aggregation,
  ArgumentAxis,
  Legend,
  LoadingIndicator,
  Series,
  IVisualRangeProps,
  ZoomAndPan,
  Tooltip,
  Point,
  Animation,
  Label,
  ValueAxis,
  ConstantLine,
} from "devextreme-react/chart";
import { useCallback, useEffect, useMemo, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { isToday } from "date-fns";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CandlestickChart from "@mui/icons-material/CandlestickChart";
import LineChart from "@mui/icons-material/SsidChartSharp";
import { usePriceStream } from "../../services/trade-api";
import { OHLC, useAvgPrice, useOHLC } from "../../services/asset";
import { useAccountContext } from "../../context/account-context";
import { useInteractionContext } from "../../../context/interaction-context";
import { useTheme } from "@mui/material";
import { getAppSettings, setAppSettings } from "../../../storage";
import ButtonIcon from "@mui/icons-material/PriceCheck";
import { usePriceFormatter } from "../../hooks/use-price-formatter";

const minVisualRangeLength = { minutes: 10 };

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { padding: 0, margin: 0 },
  };
}

const useTooltipFormatter = () => {
  const formatter = usePriceFormatter();

  return useCallback(
    function TooltipTemplate(pointInfo: any) {
      const isCandle =
        pointInfo.openValue ||
        pointInfo.highValue ||
        pointInfo.lowValue ||
        pointInfo.closeValue;

      return isCandle ? (
        <div className="tooltip-template">
          <div>{pointInfo.argumentText}</div>
          <div>
            <span>Open: </span>
            {formatter(pointInfo.openValue)}
          </div>
          <div>
            <span>High: </span>
            {formatter(pointInfo.highValue)}
          </div>
          <div>
            <span>Low: </span>
            {formatter(pointInfo.lowValue)}
          </div>
          <div>
            <span>Close: </span>
            {formatter(pointInfo.closeValue)}
          </div>
        </div>
      ) : (
        <div className="tooltip-template">
          <div>{pointInfo.argumentText}</div>
          <div>
            <span>Price: </span>
            {formatter(pointInfo.value)}
          </div>
        </div>
      );
    },
    [formatter]
  );
};

const calculateCandle = (e: {
  data?: any[];
  intervalStart?: Date;
  intervalEnd?: Date;
}) => {
  const data: OHLC[] | undefined = e.data as OHLC[];

  if (!data || !e.intervalEnd || !e.intervalStart) {
    return {};
  }

  if (data.length) {
    return {
      createdAt: new Date(
        (e.intervalStart.valueOf() + e.intervalEnd.valueOf()) / 2
      ),
      o: data[0].o,
      h: Math.max.apply(
        null,
        data.map(({ h }) => h)
      ),
      l: Math.min.apply(
        null,
        data.map(({ l }) => l)
      ),
      c: data[data.length - 1].c,
    };
  }
  return {};
};

const OhlcChart = () => {
  const [visualRange, setVisualRange] = useState<IVisualRangeProps>();
  const length = visualRange
    ? new Date(visualRange.endValue || 0).getTime() -
      new Date(visualRange.startValue || 0).getTime()
    : 1000 * 60 * 60 * 24;

  const theme = useTheme();

  const [isInit, setIsInit] = useState(false);

  const interval = length > 5 * 1000 * 60 * 60 || !isInit ? "hour" : "minute";
  const asset = useAccountContext();

  const { from: date, to: endDate } = useInteractionContext();

  const data = useOHLC(date, endDate, interval, asset);

  const price = usePriceStream();
  const avgPrice = useAvgPrice(asset).result || null;
  const priceFormatter = usePriceFormatter();

  const today = useMemo(() => isToday(new Date(endDate)), [endDate]);

  const TooltipTemplate = useTooltipFormatter();

  const [store, setStore] = useState(
    new CustomStore({
      load: () => data.result || [],
      key: "key",
    })
  );

  useEffect(() => {
    if (price && today && !data.loading) {
      setStore((store) => {
        store.push([
          {
            type: "insert",
            key: new Date(),
            data: {
              createdAt: new Date(),
              o: price,
              h: price,
              l: price,
              c: price,
              ticks: 1,
            },
          },
        ]);
        return store;
      });
    }
  }, [price, today, data.loading]);

  useEffect(() => {
    if (data.result && !data.loading) {
      const newStore = new CustomStore({
        load: () => data.result || [],
        key: "key",
      });
      setStore(() => newStore);
      newStore.load().then(() => setIsInit(true));
    }
  }, [data.result, data.loading]);

  const [tabValue, setTabValue] = useState(0);

  const [showAvgPrice, setShowAvgPrice] = useState(
    getAppSettings().showAvgPrice
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
      <Tabs
        value={tabValue}
        onChange={(_event, newValue) => {
          setTabValue(newValue);
        }}
        aria-label="chart tabs"
        sx={{ padding: 0, margin: 0 }}
      >
        <Tab {...a11yProps(0)} icon={<LineChart />} />
        <Tab {...a11yProps(1)} icon={<CandlestickChart />} />
      </Tabs>
      <Box sx={{ flexGrow: 1 }}>
        <ChartCore height="100%" dataSource={store}>
          <Animation enabled={false} />
          {tabValue === 0 && (
            <Series argumentField="createdAt" valueField="c" type="line">
              <Point visible={false} />
              <Aggregation
                enabled={true}
                method="custom"
                calculate={calculateCandle}
              />
            </Series>
          )}
          {tabValue === 1 && (
            <Series
              argumentField="createdAt"
              openValueField="o"
              closeValueField="c"
              lowValueField="l"
              highValueField="h"
              type="candlestick"
            >
              <Aggregation
                enabled={true}
                method="custom"
                calculate={calculateCandle}
              />
            </Series>
          )}
          <ZoomAndPan argumentAxis="both" />
          <ArgumentAxis
            minVisualRangeLength={minVisualRangeLength}
            visualRange={visualRange}
            onVisualRangeChange={setVisualRange}
            argumentType="datetime"
          />
          <LoadingIndicator enabled={true} />
          <Legend visible={false} />
          <Tooltip
            enabled
            shared
            argumentFormat="shortDateShortTime"
            contentRender={TooltipTemplate}
          />
          {avgPrice !== null && (
            <ValueAxis>
              <ConstantLine
                width={2}
                value={avgPrice}
                color={
                  price > avgPrice
                    ? theme.palette.success[theme.palette.mode]
                    : theme.palette.error[theme.palette.mode]
                }
                extendAxis={showAvgPrice}
                dashStyle="dash"
              >
                <Label text="Цена покупки" />
              </ConstantLine>
            </ValueAxis>
          )}
        </ChartCore>
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2, marginTop: "auto" }}>
        <Typography variant="body1">
          Цена:{" "}
          {priceFormatter(
            today ? price : data.result?.[data.result?.length - 1]?.c || 0
          )}
        </Typography>

        <Box sx={{ marginLeft: "auto" }}>
          <Button
            size="small"
            variant={showAvgPrice ? "contained" : "outlined"}
            sx={{
              padding: 0,
              margin: 0,
              border: "none",
              ":hover": { border: "none" },
            }}
            onClick={() => {
              setShowAvgPrice((state) => {
                setAppSettings({ showAvgPrice: !state });
                return !state;
              });
            }}
          >
            <ButtonIcon sx={{ width: 0.4 }} />
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default OhlcChart;
