import Box from "@mui/material/Box/Box";
import { useAssetInfo, usePrice, useTrades } from "../services/trade-api";
import { useInteractionContext } from "../../context/interaction-context";
import TextField from "@mui/material/TextField/TextField";
import { formatNumber0, formatNumber2 } from "../../utils/format";
import { useState } from "react";
import { Paper, Typography } from "@mui/material";
import { adjustNumber } from "../../utils/number";

type Props = {
  q: number;
  f: number;
};

const Balancer = (props: Props) => {
  const { from, to } = useInteractionContext();
  const trades = useTrades(from, to).data;
  const [q, setQ] = useState(props.q.toString());
  const [f, setF] = useState(props.f.toString());
  const price = usePrice().data || 0;
  const info = useAssetInfo().data;

  const qValue = Number(q);
  const fValue = Number(f);

  const startPrice = trades?.[0]?.price || 0;
  const endPrice = trades?.slice().reverse()[0]?.price || price;

  const expectedBalance =
    adjustNumber((startPrice - endPrice) / fValue, 1) * qValue;
  const currentBalance =
    (trades
      ?.slice(1)
      .reduce((sum, t) => sum + (t.value / t.price) * t.direction, 0) || 0) /
    (info?.lotSize || 1);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <TextField
          label={`q (≈${formatNumber2(
            qValue * (info?.lotSize || 0) * (price || 0)
          )})`}
          type={"number"}
          value={q}
          onChange={(e) => setQ(e.target.value)}
          inputProps={{ step: "1" }}
        />
        <TextField
          label={`f${
            price ? ` (≈${formatNumber0((fValue / price) * 100)}%)` : ""
          }`}
          type={"number"}
          value={f}
          onChange={(e) => setF(e.target.value)}
          inputProps={{ step: info?.step }}
        />
      </Box>
      <Paper>
        <Typography>expected: {formatNumber0(expectedBalance)}</Typography>
        <Typography>fact: {formatNumber0(currentBalance)}</Typography>
        <Typography>
          balance: {formatNumber0(expectedBalance - currentBalance)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default Balancer;
