import Box from "@mui/material/Box";
import {
  useInteractionContext,
  useInteractionDispatch,
} from "../context/interaction-context";
import DatePicker from "./date-picker";

const DateRangeSelector = () => {
  const { from, to } = useInteractionContext();
  const dispatch = useInteractionDispatch();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        marginBottom: 1,
      }}
    >
      <DatePicker
        label="Start"
        value={from}
        onValueChange={(payload) => {
          dispatch({
            type: "SET_FROM_VALUE",
            payload,
          });
        }}
      />
      <DatePicker
        label="End"
        value={to}
        onValueChange={(payload) => {
          dispatch({
            type: "SET_TO_VALUE",
            payload,
          });
        }}
      />
    </Box>
  );
};

export default DateRangeSelector;
