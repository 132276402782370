import Box from "@mui/material/Box";
import ChartCore, {
  ArgumentAxis,
  Legend,
  IVisualRangeProps,
  ZoomAndPan,
  Point,
  Series,
  CommonSeriesSettings,
} from "devextreme-react/chart";
import { useInteractionContext } from "../context/interaction-context";
import { useState } from "react";
import { isToday } from "date-fns";
import { useApiLimitsData } from "../services/binance";
import DateRangeSelector from "../components/date-range-selector";

const minVisualRangeLength = { minutes: 10 };

function LimitsApp() {
  const { from, to } = useInteractionContext();

  const response = useApiLimitsData(from, to);

  const endDate = new Date(to);

  const [visualRange, setVisualRange] = useState<IVisualRangeProps>({
    startValue: from,
    endValue: isToday(new Date(to))
      ? new Date().toString()
      : endDate.toString(),
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <DateRangeSelector />
      <ChartCore
        height="100%"
        dataSource={response.data || []}
        onLegendClick={(e: any) => {
          e.target.isVisible() ? e.target.hide() : e.target.show();
        }}
        autoHidePointMarkers={false}
      >
        <Series valueField="sapiUsed1M" name="sapiUsed1M"></Series>

        <Series valueField="mbxUsed" name="mbxUsed"></Series>

        <Series valueField="mbxUsed1M" name="mbxUsed1M"></Series>

        <CommonSeriesSettings
          argumentField="createdAt"
          valueField="amount"
          type={"line"}
          ignoreEmptyPoints={true}
        >
          <Point visible={false} />
        </CommonSeriesSettings>

        <ZoomAndPan argumentAxis="both" />
        <ArgumentAxis
          minVisualRangeLength={minVisualRangeLength}
          visualRange={visualRange}
          onVisualRangeChange={setVisualRange}
          argumentType="datetime"
        />
        <Legend
          visible={true}
          verticalAlignment="bottom"
          horizontalAlignment="center"
        />
      </ChartCore>
    </Box>
  );
}

export default LimitsApp;
