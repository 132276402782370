import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useAddAccount } from "../services/accounts";
import ExchangeSelector from "../binance/components/exchange-selector/exchange-selector";
import { Exchange } from "../binance/components/exchange-selector/constants";

type Props = {
  onClose: () => void;
};

const AddAccountDialog = ({ onClose }: Props) => {
  const [name, setName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [exchange, setExchange] = useState<string>(Exchange.Binance);

  const addAccount = useAddAccount(onClose);

  return (
    <Box sx={{ width: 800 }}>
      <DialogTitle sx={{ color: (theme) => theme.palette.grey[400] }}>
        Добавить аккаунт
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <TextField
            label="Имя"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="dense"
          ></TextField>
          <ExchangeSelector
            value={exchange}
            onValueChange={setExchange}
            sx={{ width: 300 }}
          />
        </Box>
        <TextField
          label="Api Key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          fullWidth
          margin="dense"
        ></TextField>

        <TextField
          label="Secret Key"
          value={secretKey}
          onChange={(e) => setSecretKey(e.target.value)}
          fullWidth
          margin="dense"
        ></TextField>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Отмена
        </Button>
        <Button
          disabled={addAccount.loading || !name || !secretKey || !apiKey}
          onClick={() => {
            addAccount.execute({
              apiKey,
              secretKey,
              name,
              notificationsEnabled: true,
              exchange,
            });
          }}
        >
          Добавить
        </Button>
      </DialogActions>
    </Box>
  );
};

export default AddAccountDialog;
