import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useAllBalance, useTotalBalance } from "../services/client";
import {
  createNumberColumnFormatter,
  formatNumber5,
  formatNumber8,
} from "../../utils/format";
import renderAssetCell from "./render-asset-cell";

const columns = [
  {
    field: "asset",
    headerName: "Код",
    width: 100,
    type: "string",
    renderCell: renderAssetCell,
  },
  {
    field: "free",
    headerName: "Доступно",
    width: 170,
    type: "number",
    valueFormatter: createNumberColumnFormatter(formatNumber8),
  },
];

export const AllBalanceTable = () => {
  const balanceResponse = useAllBalance();

  const totalResponse = useTotalBalance();

  const balance = balanceResponse.data || [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box style={{ flexGrow: 1, paddingLeft: 4 }}>
        <DataGrid
          getRowId={(x) => x.asset}
          rows={balance.filter((b) => !b.asset.startsWith("Всего"))}
          columns={columns}
          disableSelectionOnClick
          loading={balanceResponse.isLoading}
        />
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2 }}>
        <Typography variant="body1">
          Всего (SUR): {formatNumber5(totalResponse.data || 0)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default AllBalanceTable;
