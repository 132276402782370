import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import CanceledIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import ActiveIcon from "@mui/icons-material/PlayCircle";
import { Button } from "@mui/material";
import { useState } from "react";
import Modal from "../../components/modal";
import CloseOrderDialog from "./order-dialog/close-order-form";

export enum OrderStatus {
  Active = 1,
  Canceled = 2,
  Executed = 3 /* any */,
  NotPlaced = 4,
}

const getIcon = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.Canceled: {
      return (
        <CanceledIcon
          sx={{ color: (theme) => theme.palette.error[theme.palette.mode] }}
        />
      );
    }
    case OrderStatus.NotPlaced: {
      return (
        <ErrorIcon
          sx={{ color: (theme) => theme.palette.error[theme.palette.mode] }}
        />
      );
    }
    case OrderStatus.Active: {
      return (
        <ActiveIcon
          sx={{ color: (theme) => theme.palette.warning[theme.palette.mode] }}
        />
      );
    }
    default: {
      return (
        <DoneIcon
          sx={{ color: (theme) => theme.palette.success[theme.palette.mode] }}
        />
      );
    }
  }
};

const RenderOrderStatusCell = (params: any) => {
  const icon = getIcon(params.value);
  const [dialogIsOpen, setDialogOpen] = useState<number | null>(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      {params.value === OrderStatus.Active ? (
        <Button
          sx={{ padding: 0, margin: 0, minWidth: 0 }}
          size="small"
          onClick={() => setDialogOpen(params.row.id)}
        >
          {icon}
        </Button>
      ) : (
        icon
      )}
      {dialogIsOpen && (
        <Modal
          isOpen={dialogIsOpen !== null}
          onClose={() => setDialogOpen(null)}
        >
          <CloseOrderDialog
            orderNumber={dialogIsOpen}
            onClose={() => setDialogOpen(null)}
          />
        </Modal>
      )}
    </Box>
  );
};

export default RenderOrderStatusCell;
