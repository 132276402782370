import { useEffect, useState } from "react";
import { useDataIsCompleted } from "../services/trade-api";
import renderDirectionCell from "./render-direction-cell";
import { useCancelUnknownWalletOrder } from "../services/orders-api";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Cross from "@mui/icons-material/Cancel";

const RenderLimitDirectionCell = (params: any) => {
  const isCompletedCallback = useDataIsCompleted().execute;
  const [completed, setCompleted] = useState<boolean>(params.row.completed);

  const cancelOrder = useCancelUnknownWalletOrder(() => {
    setTimeout(() => {
      isCompletedCallback(params.id).then(({ state }) => {
        setCompleted(state);
      });
    }, 1000);
  });

  useEffect(() => {
    if (!completed) {
      isCompletedCallback(params.id).then(({ state }) => {
        setCompleted(state);
      });
    }
  }, [completed, isCompletedCallback, params.id]);

  useEffect(() => {
    setCompleted(params.row.completed);
  }, [params.row.completed]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
      }}
    >
      {renderDirectionCell({
        ...params,
        row: {
          ...params.row,
          completed,
        },
      })}
      {!completed && (
        <Button
          sx={{ padding: 0, margin: 0, minWidth: 0 }}
          size="small"
          onClick={() => cancelOrder.execute(params.row.id)}
        >
          <Cross />
        </Button>
      )}
    </Box>
  );
};

export default RenderLimitDirectionCell;
