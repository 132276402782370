import { Wallet } from "../../model";
import { useBuy } from "../../services/binance";
import BaseOrderDialog from "./base-order-form";

type Props = {
  onClose: () => void;
  asset: string;
  wallet: Wallet;
  q: number;
};

const BuyDialog = ({ onClose, asset, wallet, q }: Props) => {
  const buy = useBuy(onClose);

  return (
    <BaseOrderDialog
      q={q}
      title={`Купить ${asset}`}
      initialWallet={wallet}
      onSubmit={(amount, price, wallet, orderType) => {
        buy.execute(wallet, price, amount, orderType);
      }}
      isLoading={buy.loading}
      onClose={onClose}
    />
  );
};

export default BuyDialog;
