import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import AlgorithmParams, {
  useAlgorithmParams,
} from "./components/algorithm-params";

import { useSetTradeParams, useTradeParams } from "./services/trade-api";

type Props = {
  onUpdated: () => void;
};

const BcsTradeSetParams = ({ onUpdated }: Props) => {
  const { result: currentParams, execute, loading } = useTradeParams();
  const params = useAlgorithmParams(currentParams);
  const [sd, setSd] = useState("");

  useEffect(() => {
    if (currentParams) {
      setSd(currentParams.sd.toString());
    }
  }, [currentParams]);

  const setParams = useSetTradeParams(() => {
    execute();
    onUpdated();
  });

  const isValidValue = (v: string) => !isNaN(Number(v));

  const disabled =
    loading ||
    setParams.loading ||
    !isValidValue(params.f) ||
    !isValidValue(sd) ||
    !isValidValue(params.q) ||
    (Number(params.f) === currentParams?.f &&
      Number(params.q) === currentParams?.q &&
      Number(sd) === currentParams?.sd &&
      params.orderType === currentParams?.orderType);
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, marginTop: 2 }}>
        <AlgorithmParams {...params} />
        <TextField
          disabled={loading || setParams.loading}
          label="SD"
          type={"number"}
          value={sd}
          onChange={(e) => setSd(e.target.value)}
          inputProps={{ step: "1" }}
        />
        <Button
          disabled={disabled}
          variant="contained"
          sx={{
            marginLeft: "auto",
          }}
          onClick={() => {
            if (!currentParams) {
              return;
            }
            setParams.execute({
              f: Number(params.f),
              q: Number(params.q),
              sd: Number(sd),
              orderType: params.orderType,
            });
          }}
        >
          Установить
        </Button>
      </Box>
    </Box>
  );
};

export default BcsTradeSetParams;
