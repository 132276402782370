import { currentTheme, refreshTheme } from "devextreme/viz/themes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo } from "react";

const useChartTheme = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useMemo(() => {
    currentTheme("generic", prefersDarkMode ? "dark" : "light");
    refreshTheme();
  }, [prefersDarkMode]);
};

export default useChartTheme;
