import Box from "@mui/material/Box";
import AnalysisNavigation from "./analysys-navigation";
import AccountSelector from "./account-selector";
import TradeNavigation from "./trade-navigation";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import WalletNavigationSelector from "./wallet-navigation-selector";
import Can from "../components/auth/can";
import TickerSelector from "../components/ticker-selector";

const Navigation = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 4,
        marginBottom: 2,
        alignItems: "center",
      }}
    >
      <AnalysisNavigation />
      <TradeNavigation />

      <WalletNavigationSelector />

      <Link component={NavLink} underline="hover" to={`/binance/fee/`}>
        Вознаграждение
      </Link>

      <Link component={NavLink} underline="hover" to={`/binance/limits/`}>
        Лимиты API
      </Link>

      <Can perform="bcs">
        <Link component={NavLink} underline="hover" to={`/bcs/trading`}>
          Bcs
        </Link>
      </Can>

      <Box
        sx={{
          marginLeft: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TickerSelector />
        <AccountSelector />
      </Box>
    </Box>
  );
};

export default Navigation;
