import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useCreateAccount } from "../../services/auth";

const SignUpForm = () => {
  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const createAccount = useCreateAccount();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    createAccount.execute({
      login,
      password,
      name,
    });
    e.preventDefault();
  };

  return (
    <form className="form" onSubmit={onSubmit}>
      <TextField
        label="Имя"
        autoFocus
        value={name}
        fullWidth
        margin="normal"
        size="small"
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="Email"
        autoFocus
        value={login}
        fullWidth
        type={"email"}
        margin="normal"
        size="small"
        onChange={(e) => setLogin(e.target.value)}
        required
      />
      <TextField
        label="Пароль"
        autoFocus
        type={"password"}
        value={password}
        fullWidth
        size="small"
        margin="normal"
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          sx={{ marginLeft: "auto" }}
          disabled={createAccount.loading}
          type="submit"
          color="primary"
        >
          Зарегистрироваться
        </Button>
      </Box>
    </form>
  );
};

export default SignUpForm;
