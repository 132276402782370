import Box from "@mui/material/Box";
import TradesTable from "./components/trades-table";
import DateRangeSelector from "../components/date-range-selector";
import BalanceTable from "./components/balance-table";
import AlgorithmParams, {
  useAlgorithmParams,
} from "./components/algorithm-params";
import {
  useTradeParams,
  useTradeStart,
  useTradeStop,
} from "./services/trade-api";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BcsTradeSetParams from "./bcs-trade-set-params";
import { useEffect, useState } from "react";
import OrdersTable from "./components/orders-table";
import { useAccountContext } from "./context/account-context";
import Balancer from "./components/balancer";
import { AssetHistoryChart } from "./components/chart/asset-history-chart";
import ConfirmDialog, { useConfirmDialog } from "../components/confirm-dialog";
import { AssetTotalMarginChart } from "./components/chart/asset-total-margin-chart";
import IisOhlcChart from "./components/chart/iis-ohlc-chart";

function a11yProps(index: number, visible = true) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      visibility: visible ? "visible" : "hidden",
      ...(!visible && { display: "none" }),
    },
  };
}

const BcsTradeApp = () => {
  const paramsResponse = useTradeParams();
  const currentParams = paramsResponse.result || null;
  const params = useAlgorithmParams(currentParams);
  const start = useTradeStart();
  const stop = useTradeStop();
  const account = useAccountContext();

  const isStarted = !!currentParams;
  const [tabValue, setTabValue] = useState(0);

  const [confirmStopProps, openStopConfirmDialog] = useConfirmDialog(
    async () => {
      await stop.execute();
      await paramsResponse.execute();
    },
    "Остановить торговлю?"
  );

  useEffect(() => {
    if (!isStarted && tabValue === 3) {
      setTabValue(0);
    }
  }, [tabValue, isStarted]);

  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
        }}
      >
        <DateRangeSelector />
        <AlgorithmParams
          {...params}
          disabled={
            isStarted || paramsResponse.loading || start.loading || stop.loading
          }
        />
        <Button
          disabled={paramsResponse.loading || start.loading || stop.loading}
          onClick={async () => {
            if (!isStarted) {
              await start.execute(
                Number(params.f),
                Number(params.q),
                params.orderType
              );
              await paramsResponse.execute();
            } else {
              openStopConfirmDialog();
            }
          }}
        >
          {isStarted ? "Остановить" : "Начать"}
        </Button>
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <Tabs
          value={tabValue}
          onChange={(_event, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="trading tabs"
        >
          <Tab label="Сделки" {...a11yProps(0)} />
          <Tab label="Заявки" {...a11yProps(1)} />
          <Tab label="График" {...a11yProps(2)} />
          <Tab label={`Изменить параметры`} {...a11yProps(3, isStarted)} />
          <Tab label={`Выравнивание`} {...a11yProps(4)} />
          <Tab label={`График объема`} {...a11yProps(5)} />
          <Tab label={`График прибыли`} {...a11yProps(6)} />
        </Tabs>
      </Box>
      <Box
        style={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        {tabValue === 0 && (
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 300px",
              flexGrow: 1,
            }}
          >
            <TradesTable q={1} />
            <BalanceTable />
          </Box>
        )}
        {tabValue === 1 && <OrdersTable asset={account} />}
        {tabValue === 2 && <IisOhlcChart />}
        {tabValue === 3 && (
          <BcsTradeSetParams
            onUpdated={() => {
              paramsResponse.execute();
            }}
          />
        )}
        {tabValue === 4 && (
          <Balancer q={Number(params.q)} f={Number(params.f)} />
        )}
        {tabValue === 5 && <AssetHistoryChart />}
        {tabValue === 6 && <AssetTotalMarginChart />}
      </Box>

      <ConfirmDialog {...confirmStopProps} />
    </Box>
  );
};

export default BcsTradeApp;
