import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import { formatNumber0 } from "../../utils/format";

type Props = {
  sx?: SxProps<Theme>;
  buyVolume: number;
  sellVolume: number;
};

const BASE_VALUE_SX: SxProps<Theme> = { height: "100%" };

const row: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
};

const mlAuto: SxProps<Theme> = {
  marginLeft: "auto",
};

const TradeVolume = ({ sx, buyVolume, sellVolume }: Props) => {
  const volume = buyVolume + sellVolume;

  const buyWidth = Math.round((buyVolume / volume) * 100);

  const diff = sellVolume - buyVolume;

  const tooltipLabel = (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={row}>
        <Box>Покупка</Box>
        <Box sx={mlAuto}> {formatNumber0(buyVolume)}</Box>
      </Box>
      <Box sx={row}>
        <Box>Продажа</Box>
        <Box sx={mlAuto}> {formatNumber0(sellVolume)}</Box>
      </Box>

      <Box sx={{ ...row, fontWeight: 700 }}>
        <Box>Всего</Box>
        <Box sx={mlAuto}> {formatNumber0(sellVolume + buyVolume)}</Box>
      </Box>
      <Box
        sx={{
          ...row,
          fontWeight: 700,
        }}
      >
        <Box>Баланс</Box>
        <Box sx={mlAuto}> {formatNumber0(diff)}</Box>
      </Box>
    </Box>
  );

  return (
    <Tooltip placement="top" title={tooltipLabel}>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          flexDirection: "row",
          height: "20px",
          borderRadius: "4px",
          overflow: "hidden",
          ...sx,
        }}
      >
        <Box
          sx={{
            ...BASE_VALUE_SX,
            backgroundColor: (t) => t.palette.success[t.palette.mode],
            width: `${buyWidth}%`,
            borderRight: "1px solid black",
          }}
        />
        <Box
          sx={{
            ...BASE_VALUE_SX,
            flexGrow: 1,
            backgroundColor: (t) => t.palette.error[t.palette.mode],
            borderLeft: "1px solid black",
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default TradeVolume;
