import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useNetworkList } from "../../services/api";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
    },
  },
};

type Props = {
  asset: string;
  value: string;
  onValueChange: (value: string) => void;
};

const NetworkSelector = ({ asset, value, onValueChange }: Props) => {
  const listResponse = useNetworkList(asset);
  const list = listResponse.isLoading === false ? listResponse.data || [] : [];

  useEffect(() => {
    const defaultNetwork = listResponse.data?.find((n) => n.isDefault);
    if (defaultNetwork) {
      onValueChange(defaultNetwork.network);
    }
  }, [listResponse.data, onValueChange]);

  useEffect(() => {
    if (listResponse.isLoading) {
      onValueChange("");
    }
  }, [listResponse.isLoading, onValueChange]);

  return (
    <FormControl
      sx={{
        minWidth: 170,
      }}
      error={!listResponse.isLoading && list.length === 0}
    >
      <InputLabel focused variant="outlined">
        Сеть
      </InputLabel>
      <Select
        labelId="Wallet"
        value={listResponse.isLoading === false ? value : "Загрузка"}
        size="small"
        disabled={listResponse.isLoading}
        onChange={({ target: { value } }) => {
          if (typeof value === "string") {
            onValueChange(value);
          }
        }}
        input={<OutlinedInput label="Сеть" />}
        MenuProps={MenuProps}
      >
        {list.map((network) => (
          <MenuItem key={network.network} value={network.network}>
            <ListItemText primary={network.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NetworkSelector;
