import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Arrow from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { formatWallet } from "../components/wallet-selector";

import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { Wallet } from "../model";
import { useInteractionContext } from "../context/interaction-context";

export const WALLETS = [
  { id: Wallet.SPOT, path: "/binance/spot_wallet" },
  { id: Wallet.MARGIN, path: "/binance/margins" },
  { id: Wallet.ISOLATED, path: "/binance/isolated_wallet" },
];

const WalletNavigationSelector = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { allowedWallets } = useInteractionContext();
  const open = Boolean(anchorEl);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 0.4 }}>
      <Box>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="small"
          sx={{
            margin: 0,
            padding: 0,
            textTransform: "none",
          }}
          endIcon={<Arrow />}
        >
          <Typography variant="body1">Кошелек</Typography>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {WALLETS.filter((w) => allowedWallets.includes(w.id)).map(
            (wallet) => {
              return (
                <MenuItem
                  key={wallet.id}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <Link component={NavLink} underline="hover" to={wallet.path}>
                    {formatWallet(wallet.id)}
                  </Link>
                </MenuItem>
              );
            }
          )}
        </Menu>
      </Box>
    </Box>
  );
};

export default WalletNavigationSelector;
