import { useAsync, useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import { Params } from "../algorithm1-params";
import createEndpointBase from "../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import { DataItem } from "./api";
import { useInteractionContext } from "../context/interaction-context";
import { useEffect, useState } from "react";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../context/notification-context";

const endPointBase = createEndpointBase();

export type State = {
  state: number;
};

export type KindResponse = {
  kind: "algorithm1" | "algorithm2" | null;
};

export type Fee = {
  id: number;
  name: string;
  fee: number;
  count: number;
};

export type Trade = {
  id: number;
  createdAt: Date;
  price: number;
  direction: number;
  value: number;
  comment: string | null;
  commission: number;
  commissionAsset: string;
} & { fee: number | null };

export const useTradeState = () => {
  const key = "/api/trading/state";
  const { ticker } = useInteractionContext();
  const accountId = useAccountContext();
  const { data, isLoading, error, refetch } = useQuery<State>(
    [key, accountId, ticker],
    () => {
      return endPointBase.load(
        `${key}/${ticker}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    }
  );

  return { data, isLoading, error, refetch };
};

export const useTradeStateByAccount = (
  accountId: number,
  forcedTicker?: string
) => {
  const key = "/api/trading/common-params";
  const { ticker } = useInteractionContext();
  const { data, isLoading, error, refetch } = useQuery<{
    warning?: boolean;
  } | null>([key, accountId, ticker, forcedTicker], () => {
    return endPointBase.load(
      `${key}/${forcedTicker || ticker}?account_id=${accountId}`,
      endPointBase.createGetOptions()
    );
  });

  return { data, isLoading, error, refetch };
};

export const useTradeKind = () => {
  const key = "/api/trading/kind";
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  const state = useTradeState();
  const { data, isLoading, error, refetch } = useQuery<KindResponse>(
    [key, accountId, state.data?.state, ticker],
    () => {
      return endPointBase.load(
        `${key}/${ticker}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    }
  );

  return { data, isLoading, error, refetch };
};

export const useTradeStart = () => {
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    async (
      f: number,
      a: number,
      b: number,
      q: number,
      accountId: number
    ): Promise<State | null> => {
      return endPointBase.load(
        `/api/trading/start/${ticker}?f=${f}&a=${a}&b=${b}&q=${q}&account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    }
  );
};

export const useTradeStop = () => {
  const { ticker } = useInteractionContext();
  return useAsyncCallback(async (accountId: number): Promise<State | null> => {
    return endPointBase.load(
      `/api/trading/stop/${ticker}?account_id=${accountId}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useData = (date: string, endDate: string) => {
  const key = "/api/trading/data";
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useQuery<Trade[]>(
    [key, date, endDate, accountId, ticker],
    async () => {
      return endPointBase.load(
        `${key}/${ticker}?date=${date}&end=${endDate}&account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    }
  );
};

export const useDataIsCompleted = () => {
  const accountId = useAccountContext();
  const { ticker, wallet } = useInteractionContext();
  const notificationDispatch = useNotificationDispatch();

  return useAsyncCallback<{ state: boolean }>(async (id: number) => {
    return endPointBase.load(
      `/api/trading/data/${id}/${ticker}/${wallet}/is_completed?account_id=${accountId}`,
      endPointBase.createGetOptions()
    );
  }, createErrorHandlingOptions(notificationDispatch));
};

export const useDataUpdateCommission = (onSuccess: () => void) => {
  const accountId = useAccountContext();
  const { ticker, wallet } = useInteractionContext();
  const notificationDispatch = useNotificationDispatch();

  return useAsyncCallback<Trade>(
    async (id: number) => {
      return endPointBase.load(
        `/api/trading/data/${id}/${ticker}/${wallet}/update_commission?account_id=${accountId}`,
        endPointBase.createPatchOptions({})
      );
    },
    { ...createErrorHandlingOptions(notificationDispatch), onSuccess }
  );
};

export const useFee = (date: string, endDate: string) => {
  const key = "/api/trading/fee";
  return useQuery<Fee[]>([key, date, endDate], async () => {
    return endPointBase.load(
      `${key}?date=${date}&end=${endDate}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useTradeParams = () => {
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsync(
    (): Promise<(Params & { q: number; warning?: boolean }) | null> =>
      endPointBase.load(
        `/api/trading/params/${ticker}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      ),
    [accountId]
  );
};

export const useTradeDebug = () => {
  const key = "/api/trading/debug";
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useQuery<DataItem[] | null>([key, accountId, ticker], () =>
    endPointBase.load(
      `${key}/${ticker}?account_id=${accountId}`,
      endPointBase.createGetOptions()
    )
  );
};

export const usePrice = () => {
  const { ticker } = useInteractionContext();
  const accountId = useAccountContext();
  return useAsync(
    (): Promise<{ price: number | null }> =>
      endPointBase.load(
        `/api/trading/price/${ticker}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      ),
    [ticker, accountId]
  );
};

export const usePriceStream = () => {
  const { ticker } = useInteractionContext();
  const [price, setPrice] = useState(0);
  const accountId = useAccountContext();

  useEffect(() => {
    const response = fetch(
      `/api/trading/price-stream/${ticker}?account_id=${accountId}`
    )
      .then((response) => response.body)
      .then((body) => {
        const reader = body?.getReader();
        if (reader) {
          new ReadableStream({
            start(controller) {
              const pump = () => {
                return reader.read().then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  if (value) {
                    const data = String.fromCharCode
                      .apply(null, Array.from(value))
                      .split("|");
                    const price = Number(data[data.length - 1]);
                    setPrice(price);
                  }
                  pump();
                });
              };
              return pump();
            },
          });
        }
        return reader;
      });

    return () => {
      response.then((r) => {
        r?.cancel();
      });
    };
  }, [ticker, accountId]);

  return price;
};

export const useMarketPrice = (...symbols: string[]) => {
  const params = symbols.map((s) => `symbol[]=${s}_USDT`).join("&");
  return useAsync((): Promise<Array<number | null>> => {
    return endPointBase.load(
      `/api/market-data/price?${params}`,
      endPointBase.createGetOptions()
    );
  }, [params]);
};
