import {
  createNumberColumnFormatter,
  formatDateColumn,
  formatNumber2,
} from "../../utils/format";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { Trade, useAllTrades } from "../services/trade-api";
import { useInteractionContext } from "../../context/interaction-context";
import renderAssetCell from "./render-asset-cell";
import renderDirectionCell from "../../components/render-direction-cell";
import { useAccountDispatch } from "../context/account-context";
import { useNavigate } from "react-router-dom";
import TradeVolume from "./trade-volume";

const columns = [
  {
    field: "asset",
    headerName: "Код",
    width: 120,
    type: "string",
    renderCell: renderAssetCell,
  },
  {
    field: "createdAt",
    headerName: "Дата Время",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },
  {
    field: "price",
    headerName: "Цена",
    type: "number",
    width: 100,
  },
  {
    headerName: "Кол-во",
    field: "q",
    type: "number",
    width: 100,
    renderCell: (params: any) => {
      return Math.round(params.row.value / params.row.price);
    },
  },
  {
    field: "value",
    headerName: "Объем",
    type: "number",
    width: 110,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "direction",
    headerName: "Направление",
    type: "number",
    width: 110,
    valueFormatter: (params: any) => {
      if (params.value === 0) {
        return "ERROR";
      }
      if (!params.value) {
        return "";
      }
      return params.value === -1 ? "SELL" : "BUY";
    },
    renderCell: renderDirectionCell,
  },
  {
    field: "commission",
    headerName: "Комиссия",
    type: "number",
    width: 100,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "margin",
    headerName: "Прибыль",
    type: "number",
    width: 100,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "comment",
    headerName: "Комментарий",
    type: "string",
    width: 180,
  },
];

export const AllTradesTable = () => {
  const { from, to } = useInteractionContext();
  const setAccount = useAccountDispatch();
  const dataResponse = useAllTrades(from, to);
  const navigate = useNavigate();

  const data = dataResponse.data || [];

  const commission = data.reduce((sum, d) => sum + d.commission, 0);

  const result = data.reduce(
    (
      result: Record<
        string,
        {
          startPrice: number;
          endPrice: number;
          q: number;
          position: number;
        }
      >,
      d
    ) => {
      const assetResult = result[d.asset] || {
        startPrice: 0,
        endPrice: 0,
        q: 0,
        position: 0,
      };
      return {
        ...result,
        [d.asset]: {
          ...assetResult,
          startPrice: assetResult.startPrice || d.price,
          endPrice: d.price,
          q: assetResult.q + (d.value / d.price) * d.direction,
          position: assetResult.position - d.value * d.direction,
        },
      };
    },
    {}
  );

  const margin = Object.values(result).reduce((sum, d) => {
    return sum + d.position + d.endPrice * d.q;
  }, 0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          getRowId={(x) => x.id}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          loading={dataResponse.isLoading}
          onRowClick={(e: GridRowParams<Trade>) => {
            setAccount(e.row.asset);
            navigate("/bcs/trading");
          }}
        />
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2, alignItems: "center" }}>
        <Typography variant="body1">
          Комиссия: {formatNumber2(commission)}
        </Typography>

        <TradeVolume
          sx={{ width: "70px" }}
          buyVolume={data.reduce(
            (sum, d) => sum + (d.direction === 1 ? d.value : 0),
            0
          )}
          sellVolume={data.reduce(
            (sum, d) => sum + (d.direction === -1 ? d.value : 0),
            0
          )}
        />

        <Typography variant="body1">
          Margin: {formatNumber2(margin - commission)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default AllTradesTable;
