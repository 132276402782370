import CircleIcon from "@mui/icons-material/Circle";
import { Theme } from "@mui/material";
import { useTradeStateByAsset } from "../../services/trade-api";

const getColor = (theme: Theme, state?: { warning?: boolean } | null) => {
  if (!state) {
    return theme.palette.error[theme.palette.mode];
  }

  if (state.warning) {
    return theme.palette.info[theme.palette.mode];
  }

  return theme.palette.success[theme.palette.mode];
};
type Props = {
  asset: string;
};
const TradeIndicator = ({ asset }: Props) => {
  const state = useTradeStateByAsset(asset).data || null;
  return (
    <CircleIcon
      sx={{
        fontSize: "0.8rem",
        color: (theme) => getColor(theme, state),
      }}
    />
  );
};

export default TradeIndicator;
