import { useInteractionContext } from "../../context/interaction-context";
import { useTickerInfo } from "../../services/api";
import { getPrecision } from "../../utils/number";
import { useFormatNumber } from "../../utils/format";

export const usePriceFormatter = () => {
  const { ticker } = useInteractionContext();
  const tickerInfoResponse = useTickerInfo(ticker);
  const tickerInfo = tickerInfoResponse.data;

  const step = tickerInfo?.tickSize || tickerInfo?.stepSize || 1;

  return useFormatNumber(getPrecision(step));
};
