import {
  useIsolatedMaxRepayable,
  useIsolatedRepay,
} from "../../services/isolated-margin";
import BaseRepayDialog from "./base-repay-dialog";

type Props = {
  onClose: () => void;
  asset: string;
};

const IsolatedMarginRepayDialog = ({ onClose, asset }: Props) => {
  const repay = useIsolatedRepay(onClose);
  const maxValueResponse = useIsolatedMaxRepayable(asset);
  return (
    <BaseRepayDialog
      onClose={onClose}
      asset={asset}
      repay={repay.execute}
      maxValue={maxValueResponse.result || 0}
      isLoading={repay.loading || maxValueResponse.loading}
    />
  );
};

export default IsolatedMarginRepayDialog;
