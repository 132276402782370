import Box from "@mui/material/Box";
import { useState } from "react";
import Modal from "../components/modal";
import BorrowDialog from "../components/loan-dialog/isolated-margin-borrow-dialog";
import MarginBalanceTable from "../margin-app/balance-table";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatNumber0 } from "../utils/format";
import AutoLoansRatio from "../margin-app/auto-loans-ratio";
import {
  useIsolatedBalance,
  useIsolatedTotalBalance,
} from "../services/isolated-margin";
import { useQueryClient } from "react-query";
import IsolatedMarginRechargeDialog from "../components/loan-dialog/isolated-margin-recharge-dialog";
import IsolatedMarginRepayDialog from "../components/loan-dialog/isolated-margin-repay-dialog";
import IsolatedMarginWithdrawDialog from "../components/loan-dialog/isolated-margin-withdraw-dialog";
import { Wallet } from "../model";
import OrderDialog from "../components/order-dialog";

function App() {
  const [borrowDialogOpen, setBorrowDialogOpen] = useState("");
  const [repayDialogOpen, setRepayDialogOpen] = useState("");
  const [rechargeDialogOpen, setRechargeDialogOpen] = useState("");
  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState("");
  const totalBalance = useIsolatedTotalBalance();
  const response = useIsolatedBalance();
  const queryClient = useQueryClient();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <AutoLoansRatio
          totalBalance={totalBalance.data || 0}
          multiplier={9}
          wallet={Wallet.ISOLATED}
        />
      </Box>
      <MarginBalanceTable
        data={response.data || []}
        isLoading={response.isLoading}
        onBorrowClick={(asset) => setBorrowDialogOpen(asset)}
        onRepayClick={(asset) => setRepayDialogOpen(asset)}
        onRechargeClick={(asset) => setRechargeDialogOpen(asset)}
        onWithdrawClick={(asset) => setWithdrawDialogOpen(asset)}
      />
      <Paper sx={{ display: "flex", gap: 3, padding: 2 }}>
        <Typography variant="body1">
          Цена ликвидации:{" "}
          {formatNumber0((response.data || [])[0]?.liquidatePrice || 0)}
        </Typography>
        <Typography variant="body1">
          Собственные средства: {formatNumber0(totalBalance.data || 0)} USDT
        </Typography>
        <Box sx={{ marginLeft: "auto" }}>
          <OrderDialog q={0} onClose={() => {}} wallet={Wallet.ISOLATED} />
        </Box>
      </Paper>
      <Modal
        isOpen={!!borrowDialogOpen}
        onClose={() => setBorrowDialogOpen("")}
      >
        <BorrowDialog
          asset={borrowDialogOpen}
          onClose={() => {
            setBorrowDialogOpen("");
            queryClient.invalidateQueries();
          }}
        />
      </Modal>
      <Modal isOpen={!!repayDialogOpen} onClose={() => setRepayDialogOpen("")}>
        <IsolatedMarginRepayDialog
          asset={repayDialogOpen}
          onClose={() => {
            setRepayDialogOpen("");
            queryClient.invalidateQueries();
          }}
        />
      </Modal>
      <Modal
        isOpen={!!rechargeDialogOpen}
        onClose={() => setRechargeDialogOpen("")}
      >
        <IsolatedMarginRechargeDialog
          asset={rechargeDialogOpen}
          onClose={() => {
            setRechargeDialogOpen("");
            queryClient.invalidateQueries();
          }}
        />
      </Modal>
      <Modal
        isOpen={!!withdrawDialogOpen}
        onClose={() => setWithdrawDialogOpen("")}
      >
        <IsolatedMarginWithdrawDialog
          asset={withdrawDialogOpen}
          onClose={() => {
            setWithdrawDialogOpen("");
            queryClient.invalidateQueries();
          }}
        />
      </Modal>
    </Box>
  );
}

export default App;
