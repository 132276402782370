export function getPrecision(value: number) {
  const str = value.toString();

  if (str.indexOf("e-") > 0) {
    const value = Number(str.split("e-")[1]);

    return Number.isNaN(value) ? 0 : value;
  }

  if (str.indexOf(".") < 0) {
    return 0;
  }

  const mantissa = str.split(".");
  const positionOfDelimiter = mantissa[1].indexOf("e");

  return positionOfDelimiter >= 0 ? positionOfDelimiter : mantissa[1].length;
}

export const adjustNumber = (value: number, by?: number) => {
  const precision = by !== undefined ? getPrecision(by) : 5;
  return Number(value.toFixed(precision));
};

export const floor = (value: number, interval: number) => {
  if (interval > 1) {
    return Math.floor(value / interval) * interval;
  }
  return Math.floor(value / interval) / (1 / interval);
};
