import Box from "@mui/material/Box";
import { Exchange } from "./constants";
import exchangeIconURI from "./icons/commex.png";
import binanceIconURI from "./icons/binance.png";
import byBitIconURI from "./icons/by-bit.png";

type Props = {
  exchange: string;
  size?: number | string;
};

const getUrl = (exchange: string) => {
  switch (exchange) {
    case Exchange.CommEx: {
      return exchangeIconURI;
    }

    case Exchange.ByBit: {
      return byBitIconURI;
    }

    default: {
      return binanceIconURI;
    }
  }
};

const ExchangeIcon = ({ exchange, size }: Props) => {
  return (
    <Box
      component={"img"}
      src={getUrl(exchange)}
      sx={{ width: size, height: size, borderRadius: "50%" }}
    />
  );
};

export default ExchangeIcon;
