import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import LoginForm from "../components/auth/login-form";
import SignUpForm from "../components/auth/sign-up-form";

const LoginPage = () => {
  const [signUp, setSignUp] = useState(false);

  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        borderRadius: 0,
      }}
    >
      <Card sx={{ maxWidth: 465, margin: "0 auto", padding: 4 }}>
        {!signUp ? <LoginForm /> : <SignUpForm />}
        <Typography variant="body2" style={{ display: "none" }}>
          {!signUp ? "Нет аккаунта? " : "Уже есть аккаунт? "}
        </Typography>
        <Link
          component={Box}
          sx={{ cursor: "pointer", display: "none" /*"inline"*/ }}
          onClick={() => setSignUp((state) => !state)}
        >
          {signUp ? "Войти" : "Зарегистрироваться"}
        </Link>
      </Card>
    </Paper>
  );
};

export default LoginPage;
