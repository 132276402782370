import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { formatNumber8 } from "../../utils/format";

type Props = {
  onClose: () => void;
  onSubmit: (amount: number) => void;
  isLoading: boolean;
  maxValue?: number;
  title: string;
};

const BaseLoanDialog = ({
  onClose,
  onSubmit,
  title,
  isLoading,
  maxValue = 0,
}: Props) => {
  const [amount, setAmount] = useState("");
  const value = parseFloat(amount);
  const isValid = !isNaN(value) && value > 0 && value <= maxValue;
  return (
    <Box sx={{ width: 600 }}>
      <DialogTitle sx={{ color: (theme) => theme.palette.grey[400] }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <TextField
          label={`Количество (Max: ${formatNumber8(maxValue)})`}
          autoFocus
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
          inputProps={{
            step: "0.001",
          }}
          error={!isValid}
          type="number"
          margin="dense"
        ></TextField>
        <Button
          onClick={() => {
            setAmount(maxValue.toString());
          }}
        >
          Макс
        </Button>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Отмена
        </Button>
        <Button
          disabled={isLoading || !isValid}
          onClick={() => onSubmit(value)}
        >
          {title}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default BaseLoanDialog;
