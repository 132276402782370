import Box from "@mui/material/Box";

type Props = {
  asset: string;
  size?: number | string;
};

const adustAsset = (asset: string) => {
  switch (asset) {
    case "SUR": {
      return "RUB";
    }

    case "USD000000TOD": {
      return "USD";
    }

    default: {
      return asset.replace(/[A-Z]\d$/, "");
    }
  }
};

export const getAssetIconLink = (asset: string) => {
  const adjustedAsset = adustAsset(asset);
  return `https://295628.selcdn.ru/mybroker/FinInstrumentLogo/${adjustedAsset}.png`;
};

const AssetIcon = ({ asset, size }: Props) => {
  return (
    <Box
      component={"img"}
      src={getAssetIconLink(asset)}
      sx={{ width: size, height: size, borderRadius: "50%" }}
    />
  );
};

export default AssetIcon;
