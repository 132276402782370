import { Refresh } from "@mui/icons-material";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { useState } from "react";
import { useCan } from "../services/auth";
import { useDataUpdateCommission } from "../services/trade-api";
import { formatNumber5 } from "../utils/format";

const RenderCommissionCell = (params: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAllowed = useCan("admin").data;

  const [cell, setCell] = useState<{
    formattedValue: string;
    commissionAsset: string;
  }>({
    formattedValue: params.formattedValue,
    commissionAsset: params.row.commissionAsset,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateData = useDataUpdateCommission(handleClose);

  return (
    <div>
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isAllowed) {
            setAnchorEl(e.currentTarget);
          }
        }}
      >
        {cell.formattedValue}
        {cell.formattedValue !== "0" ? ` ${cell.commissionAsset}` : ""}
      </div>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <MenuItem
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
          onClick={async () => {
            try {
              const data = await updateData.execute(params.row.id);
              setCell({
                formattedValue: formatNumber5(data.commission),
                commissionAsset: data.commissionAsset,
              });
            } catch (e) {
              return;
            }
          }}
        >
          <Refresh
            sx={{
              transitionDuration: "10s",
              transitionProperty: "transform",
              animationIterationCount: "infinite",

              ...(updateData.loading
                ? {
                    transform: "rotate(3600deg)",
                  }
                : {}),
            }}
          />
          <span> Обновить данные</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default RenderCommissionCell;
