import { useBuy } from "../../services/trade-api";
import BaseOrderDialog from "./base-order-form";

type Props = {
  onClose: () => void;
  asset: string;
  q: number;
};

const BuyDialog = ({ onClose, asset, q }: Props) => {
  const buy = useBuy(onClose);

  return (
    <BaseOrderDialog
      q={q}
      title={`Купить ${asset}`}
      onSubmit={(amount, price, secCode) => {
        buy.execute(amount, secCode || asset, price);
      }}
      isLoading={buy.loading}
      onClose={onClose}
      direction="buy"
    />
  );
};

export default BuyDialog;
