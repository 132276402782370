import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
    },
  },
};

export enum OrderType {
  Limit = "LIMIT",
  Market = "MARKET",
}

type Props = {
  disabled?: boolean;
  orderType: OrderType;
  onOrderTypeChange: (orderType: OrderType) => void;
};

export const ORDER_TYPES = [OrderType.Limit, OrderType.Market];

export const formatOrderType = (orderType: string) => {
  switch (orderType) {
    case OrderType.Limit: {
      return "Лимитная";
    }
    case OrderType.Market: {
      return "Рыночная";
    }
    default: {
      return "Не задано";
    }
  }
};

const OrderTypeSelector = ({
  disabled,
  orderType,
  onOrderTypeChange,
}: Props) => {
  return (
    <FormControl
      sx={{
        minWidth: 170,
      }}
    >
      <InputLabel focused variant="outlined">
        Заявка
      </InputLabel>
      <Select
        disabled={disabled}
        labelId="Wallet"
        value={orderType}
        onChange={({ target: { value } }) => {
          if (typeof value === "string") {
            onOrderTypeChange(value as OrderType);
          }
        }}
        input={<OutlinedInput label="Заявка" />}
        renderValue={formatOrderType}
        MenuProps={MenuProps}
      >
        {ORDER_TYPES.map((t) => (
          <MenuItem key={t} value={t}>
            <ListItemText primary={formatOrderType(t)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrderTypeSelector;
