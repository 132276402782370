import { useSell } from "../../services/trade-api";
import BaseOrderDialog from "./base-order-form";

type Props = {
  onClose: () => void;
  asset: string;
  q: number;
};

const SellDialog = ({ onClose, asset, q }: Props) => {
  const sell = useSell(onClose);
  return (
    <BaseOrderDialog
      q={q}
      title={`Продать ${asset}`}
      onSubmit={(amount, price, secCode) => {
        sell.execute(amount, secCode || asset, price);
      }}
      direction="sell"
      isLoading={sell.loading}
      onClose={onClose}
    />
  );
};

export default SellDialog;
