import { Outlet } from "react-router-dom";
import { AccountContextProvider } from "../context/account-context";
import Layout from "../Layout";
import Navigation from "../navigation";

const BinanceApp = () => {
  return (
    <AccountContextProvider>
      <Layout navigation={<Navigation />}>
        <Outlet />
      </Layout>
    </AccountContextProvider>
  );
};

export default BinanceApp;
