import Box from "@mui/material/Box";
import DateRangeSelector from "../components/date-range-selector";
import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { PortfolioChart } from "./components/chart/portfolio-chart";
import AssetAnalysisTable from "./components/asset-analysis-table";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { padding: 0, margin: 0 },
  };
}

const BcsPortfolioApp = () => {
  const [tabValue, setTabValue] = useState(1);

  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
        }}
      >
        <DateRangeSelector />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="trading tabs"
        >
          <Tab label="Состав" {...a11yProps(0)} />
          <Tab label="Анализ" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {tabValue === 0 && <PortfolioChart />}
      {tabValue === 1 && <AssetAnalysisTable />}
    </Box>
  );
};

export default BcsPortfolioApp;
