import AnalysisTable from "./algorithm1-analysis-table";
import { useTradeDebug } from "./services/trade-api";

export const TradeAnalysisTable = () => {
  const response = useTradeDebug();
  return (
    <AnalysisTable data={response.data || []} loading={response.isLoading} />
  );
};

export default TradeAnalysisTable;
