import Box from "@mui/material/Box";
import { DataItem } from "./services/api";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import {
  formatNumber5,
  formatNumber0,
  formatNumber2,
  formatDateColumn,
  createNumberColumnFormatter,
} from "./utils/format";

const columns = [
  { field: "m", headerName: "m", width: 90 },
  { field: "n", headerName: "N", width: 90 },
  {
    field: "t",
    headerName: "T",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },
  {
    field: "eb",
    headerName: "EB",
    type: "number",
    width: 80,
  },
  {
    field: "eh",
    headerName: "EH",
    type: "number",
    width: 110,
  },
  {
    field: "pok",
    headerName: "POK",
    type: "number",
    width: 160,
  },
  {
    field: "pr",
    headerName: "PR",
    type: "number",
    width: 110,
  },
  {
    field: "pu",
    headerName: "PU",
    type: "number",
    width: 110,
    valueFormatter: createNumberColumnFormatter(formatNumber0),
  },
  {
    field: "pup",
    headerName: "PUP",
    type: "number",
    valueFormatter: createNumberColumnFormatter(formatNumber2),
    width: 110,
  },
  {
    field: "bb",
    headerName: "BB",
    type: "number",
    valueFormatter: createNumberColumnFormatter(formatNumber5),
    width: 110,
  },
  {
    field: "bv",
    headerName: "BV",
    type: "number",
    width: 110,
  },
];

const calculateREZ = (
  lastData: DataItem | undefined,
  firstData: DataItem | undefined
) => {
  if (!lastData || !firstData) {
    return 0;
  }

  const { bb, bv, pok, pr } = lastData;
  const x = (pok || pr) + (firstData.pok || firstData.pr);

  return bv + (bb * x) / 2;
};

type Props = {
  data: DataItem[];
  loading: boolean;
};

const AnalysisTable = ({ data, loading }: Props) => {
  const lastData: DataItem | undefined = data[data.length - 1];

  return (
    <Box
      style={{
        flexGrow: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGrid
        getRowId={(x) => x.m}
        rows={data}
        columns={columns}
        disableSelectionOnClick
        loading={loading}
      />
      <Paper sx={{ display: "flex", gap: 3, padding: 2 }}>
        <Typography variant="body1">
          Sum(PUP):{" "}
          {formatNumber2(data.reduce((sum, item) => sum + (item.pup || 0), 0))}
        </Typography>
        <Typography variant="body1">
          Sum(PU):{" "}
          {formatNumber0(data.reduce((sum, item) => sum + (item.pu || 0), 0))}
        </Typography>
        <Typography variant="body1">
          Min(BB):{" "}
          {formatNumber5(data.reduce((min, item) => Math.min(min, item.bb), 0))}
        </Typography>
        <Typography variant="body1">
          Min(BV):{" "}
          {formatNumber0(data.reduce((min, item) => Math.min(min, item.bv), 0))}
        </Typography>
        <Typography variant="body1">
          REZ: {formatNumber0(calculateREZ(lastData, data[0]))}
        </Typography>
        <Typography variant="body1">
          BBкон: {formatNumber5(lastData?.bb || 0)}
        </Typography>
        <Typography variant="body1">
          BVкон: {formatNumber0(lastData?.bv || 0)}
        </Typography>
        <Typography variant="body1">
          Xкон: {formatNumber0(lastData?.pok || lastData?.pr)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default AnalysisTable;
