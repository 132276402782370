import {
  createNumberColumnFormatter,
  formatDateColumn,
  formatNumber0,
  formatNumber2,
  useFormatNumber,
} from "../../utils/format";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import {
  useAssetInfo,
  useTrades as useData,
  usePriceStream,
} from "../services/trade-api";
import { isToday } from "date-fns/esm";
import OrderDialog from "./order-dialog";
import { useInteractionContext } from "../../context/interaction-context";
import renderDirectionCell from "../../components/render-direction-cell";
import { getPrecision } from "../../utils/number";
import TradeVolume from "./trade-volume";
import PriceSparkline from "./price-sparkline";

const columns = [
  { field: "id", headerName: "N", width: 120, type: "number" },
  {
    field: "createdAt",
    headerName: "Дата Время",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },
  {
    field: "price",
    headerName: "Цена",
    type: "number",
    width: 80,
  },
  {
    headerName: "Кол-во",
    field: "q",
    type: "number",
    width: 80,
    renderCell: (params: any) => {
      return Math.round(params.row.value / params.row.price);
    },
  },
  {
    field: "value",
    headerName: "Объем",
    type: "number",
    width: 100,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "direction",
    headerName: "Направление",
    type: "number",
    width: 110,
    valueFormatter: (params: any) => {
      if (params.value === 0) {
        return "ERROR";
      }
      if (!params.value) {
        return "";
      }
      return params.value === -1 ? "SELL" : "BUY";
    },
    renderCell: renderDirectionCell,
  },
  {
    field: "commission",
    headerName: "Комиссия",
    type: "number",
    width: 100,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "margin",
    headerName: "Прибыль",
    type: "number",
    width: 100,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "comment",
    headerName: "Комментарий",
    type: "string",
    width: 180,
  },
];

type Props = {
  q: number;
};

export const TradesTable = ({ q }: Props) => {
  const { from, to } = useInteractionContext();
  const dataResponse = useData(from, to);
  const precision = getPrecision(useAssetInfo().data?.step || 1);

  const formatPrice = useFormatNumber(precision);

  const data = dataResponse.data || [];

  const quantity = data.reduce(
    (sum, item) => sum + (item.direction * item.value) / item.price,
    0
  );
  const positionValue = data.reduce(
    (sum, item) => sum - item.value * item.direction,
    0
  );

  const price = data[data.length - 1]?.price || 0;
  const currentPrice = usePriceStream();
  const commission = data.reduce((sum, d) => sum + d.commission, 0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          getRowId={(x) => x.id}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          loading={dataResponse.isLoading}
        />
      </Box>
      <Paper sx={{ display: "flex", alignItems: "center", gap: 3, padding: 2 }}>
        <Typography variant="body1">
          {formatNumber0(quantity)}
          {Math.abs(quantity) < 0.0001
            ? ""
            : ` x ${formatNumber2(Math.abs(positionValue / quantity))}`}
        </Typography>
        <TradeVolume
          sx={{ width: "70px" }}
          buyVolume={data.reduce(
            (sum, d) => sum + (d.direction === 1 ? d.value : 0),
            0
          )}
          sellVolume={data.reduce(
            (sum, d) => sum + (d.direction === -1 ? d.value : 0),
            0
          )}
        />
        <Typography variant="body1">
          Оценка:{" "}
          {formatNumber2(
            positionValue +
              (isToday(new Date(to)) ? currentPrice : price) * quantity -
              commission
          )}
        </Typography>
        <Typography variant="body1">
          Комиссия: {formatNumber2(commission)}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <PriceSparkline
            precision={precision}
            trades={data}
            sx={{ width: "100px" }}
            currentPrice={currentPrice}
          />
          <Typography variant="body1">{formatPrice(currentPrice)}</Typography>
        </Box>

        <Typography variant="body1">
          Прибыль:{" "}
          {formatNumber2(data.reduce((sum, item) => sum + item.margin, 0))}
        </Typography>

        <Box sx={{ marginLeft: "auto" }}>
          <OrderDialog
            q={q}
            onClose={() => {
              setTimeout(() => dataResponse.refetch(), 1000);
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default TradesTable;
