import { formatDateColumn } from "../../utils/format";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import renderDirectionCell from "../../components/render-direction-cell";
import { useOrders } from "../services/order-api";
import { useInteractionContext } from "../../context/interaction-context";
import renderAssetCell from "./render-asset-cell";
import renderOrderStatusCell from "./render-order-status-cell";
import Paper from "@mui/material/Paper";
import OrderDialog from "./order-dialog";

const columns = [
  { field: "id", headerName: "N", width: 120, type: "number" },
  {
    field: "asset",
    headerName: "Asset",
    width: 130,
    type: "string",
    renderCell: renderAssetCell,
  },
  {
    field: "createdAt",
    headerName: "Дата Время",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },
  {
    field: "price",
    headerName: "Цена",
    type: "number",
    width: 130,
  },
  {
    field: "balance",
    headerName: "Баланс",
    type: "number",
    width: 130,
  },
  {
    field: "direction",
    headerName: "Направление",
    type: "number",
    width: 110,
    valueFormatter: (params: any) => {
      if (params.value === 0) {
        return "ERROR";
      }
      if (!params.value) {
        return "";
      }
      return params.value === -1 ? "SELL" : "BUY";
    },
    renderCell: renderDirectionCell,
  },
  {
    field: "comment",
    headerName: "Комментарий",
    type: "string",
    width: 180,
  },
  {
    field: "status",
    headerName: "Статус",
    type: "string",
    width: 100,
    renderCell: renderOrderStatusCell,
  },
];

type Props = {
  asset?: string;
};

export const OrdersTable = ({ asset }: Props) => {
  const { from, to } = useInteractionContext();
  const dataResponse = useOrders(from, to, asset);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          getRowId={(x) => x.id}
          rows={dataResponse.data || []}
          columns={columns}
          disableSelectionOnClick
          loading={dataResponse.isLoading}
        />
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2 }}>
        <Box sx={{ marginLeft: "auto" }}>
          <OrderDialog
            q={1}
            onClose={() => {
              setTimeout(() => dataResponse.refetch(), 1000);
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default OrdersTable;
