import { useMemo } from "react";

export const adjustTimeFormat = (value: number) =>
  value < 10 ? `0${value}` : value.toString();

export const formatNumber5 = new Intl.NumberFormat("ru-RU", {
  maximumFractionDigits: 5,
}).format;

export const formatNumber8 = new Intl.NumberFormat("ru-RU", {
  maximumFractionDigits: 8,
}).format;

export const formatNumber0 = new Intl.NumberFormat("ru-RU", {
  maximumFractionDigits: 1,
}).format;

export const formatNumber2 = new Intl.NumberFormat("ru-RU", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}).format;

export const useFormatNumber = (precision: number) => {
  return useMemo(
    () =>
      new Intl.NumberFormat("ru-RU", {
        maximumFractionDigits: precision,
      }).format,
    [precision]
  );
};

export const createNumberColumnFormatter = (
  formatter: (value: number) => string
) => {
  return (params: any) => {
    return formatter(Number(params.value));
  };
};

export const formatDateColumn = (params: any) => {
  if (!params.value) {
    return "";
  }

  const date = new Date(params.value);

  return `${adjustTimeFormat(date.getDate())}.${adjustTimeFormat(
    date.getMonth() + 1
  )} ${adjustTimeFormat(date.getHours())}:${adjustTimeFormat(
    date.getMinutes()
  )}:${adjustTimeFormat(date.getSeconds())}`;
};
