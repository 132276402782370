import Box from "@mui/material/Box";
import Cross from "@mui/icons-material/CancelPresentation";
import Circle from "@mui/icons-material/Circle";
import { CircularProgress, Theme } from "@mui/material";

const getColor = (theme: Theme, value: number) => {
  if (value === 1) {
    return theme.palette.success[theme.palette.mode];
  }

  return theme.palette.error[theme.palette.mode];
};

const renderDirectionCell = (params: any) => {
  const DirectionIndicator =
    params.row.completed === false ? CircularProgress : Circle;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Box>{params.formattedValue}</Box>
      {params.value === 0 ? (
        <Cross sx={{ color: (theme) => getColor(theme, params.value) }} />
      ) : (
        <DirectionIndicator
          sx={{ color: (theme) => getColor(theme, params.value) }}
          size={20}
        />
      )}
    </Box>
  );
};

export default renderDirectionCell;
