import Box from "@mui/material/Box";
import DateRangeSelector from "../components/date-range-selector";
import Button from "@mui/material/Button";
import {
  useBalanceSnapshot,
  useBalanceHistory,
  useDeleteBalanceHistory,
} from "./services/balance-api";
import { useInteractionContext } from "../context/interaction-context";
import ChartCore, {
  ArgumentAxis,
  Legend,
  Series,
  IVisualRangeProps,
  ZoomAndPan,
  Tooltip,
  Aggregation,
  Point,
} from "devextreme-react/chart";
import { useState } from "react";
import { isToday } from "date-fns";
import { BalanceHistory } from "./services/client";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Margin from "@mui/icons-material/Margin";
import Difference from "@mui/icons-material/Difference";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PlusOne from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Delete";
import { formatNumber0 } from "../utils/format";
import Modal from "../components/modal";
import AddSurBenchmarkDialog from "./components/add-sur-benchmark-form";
import ConfirmDialog, { useConfirmDialog } from "../components/confirm-dialog";
import { formatDate } from "devextreme/localization";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { padding: 0, margin: 0 },
  };
}

const minVisualRangeLength = { minutes: 10 };

const calculateDiff = (e: {
  data?: any[] | undefined;
  intervalStart?: Date;
  intervalEnd?: Date;
}) => {
  const items: BalanceHistory[] = e.data as BalanceHistory[];
  if (!items) {
    return {};
  }
  if (items.length) {
    return items.map((b) => {
      return {
        ...b,
        diff: Number((b.balance - b.benchmark).toFixed(2)),
      };
    });
  }
  return {};
};

const BcsBalanceApp = () => {
  const { from, to } = useInteractionContext();
  const snapshot = useBalanceSnapshot();
  const history = useBalanceHistory(from, to);
  const removeBalanceHistory = useDeleteBalanceHistory(() => {
    history.refetch();
  });
  const [tabValue, setTabValue] = useState(1);
  const endDate = new Date(to);

  const [addBenchmarkVisible, setAddBenchmarkVisible] = useState(false);

  const [confirmProps, openSnapshotConfirm] = useConfirmDialog(
    snapshot.execute,
    "Use current balance as benchmark"
  );

  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  const [visualRange, setVisualRange] = useState<IVisualRangeProps>({
    startValue: from,
    endValue: isToday(new Date(to))
      ? new Date().toString()
      : endDate.toString(),
  });

  const [removeDialogProps, openRemoveDialog] = useConfirmDialog(
    () => {
      removeBalanceHistory.execute(
        visualRange.startValue,
        visualRange.endValue
      );
    },
    visualRange.startValue instanceof Date &&
      visualRange.endValue instanceof Date
      ? `Удалить данные за ${formatDate(
          visualRange.startValue,
          "dd.MM.yy hh:mm"
        )} - ${formatDate(visualRange.endValue, "dd.MM.yy hh:mm")}`
      : "Не выбран интервал"
  );

  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
        }}
      >
        <DateRangeSelector />
        <Button
          disabled={snapshot.loading}
          onClick={() => {
            openSnapshotConfirm();
          }}
        >
          <PhotoCamera />
        </Button>
        <Button
          disabled={snapshot.loading}
          onClick={() => {
            setAddBenchmarkVisible(true);
          }}
          aria-label="Add SUR benchmark"
        >
          <PlusOne />
        </Button>
        <Button
          disabled={snapshot.loading}
          onClick={() => {
            openRemoveDialog();
          }}
          aria-label="Delete history"
        >
          <RemoveIcon />
        </Button>
      </Box>
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
        aria-label="chart tabs"
        sx={{ padding: 0, marginBottom: 1 }}
      >
        <Tab {...a11yProps(0)} icon={<Difference />} />
        <Tab {...a11yProps(1)} icon={<Margin />} />
      </Tabs>
      <Box
        style={{
          flexGrow: 1,
        }}
      >
        <ChartCore height="100%" dataSource={history.data || []}>
          {tabValue === 0 && (
            <Series
              argumentField="createdAt"
              valueField="balance"
              type="line"
              name="Balance"
            >
              <Point visible={false} />
            </Series>
          )}
          {tabValue === 0 && (
            <Series
              argumentField="createdAt"
              valueField="benchmark"
              type="line"
              name="Benchmark"
            >
              <Point visible={false} />
            </Series>
          )}
          {tabValue === 1 && (
            <Series
              argumentField="createdAt"
              valueField="diff"
              type="line"
              name="Gross margin"
            >
              <Aggregation
                enabled={true}
                method="custom"
                calculate={calculateDiff}
              />
              <Point visible={false} />
            </Series>
          )}

          <ZoomAndPan argumentAxis="both" />
          <ArgumentAxis
            minVisualRangeLength={minVisualRangeLength}
            visualRange={visualRange}
            onVisualRangeChange={setVisualRange}
            argumentType="datetime"
          />
          <Legend
            visible={true}
            verticalAlignment="bottom"
            horizontalAlignment="center"
          />
          <Tooltip
            enabled
            format={formatNumber0}
            argumentFormat={"dd.mm.yyy"}
          />
        </ChartCore>
      </Box>

      <ConfirmDialog {...confirmProps} />

      {addBenchmarkVisible ? (
        <Modal
          isOpen={addBenchmarkVisible}
          onClose={() => setAddBenchmarkVisible(false)}
        >
          <AddSurBenchmarkDialog
            onClose={() => {
              setAddBenchmarkVisible(false);
            }}
          />
        </Modal>
      ) : null}
      <ConfirmDialog {...confirmProps} />
      <ConfirmDialog {...removeDialogProps} />
    </Box>
  );
};

export default BcsBalanceApp;
