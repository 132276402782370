import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useTradeState } from "../../services/trade-api";
import Chart from "../../components/chart";
import BalanceHistoryChart from "../../components/balance-history-chart";
import { useAccountContext } from "../../context/account-context";
import Algorithm2TradeAnalysisTable from "../../algorithm2-trade-analysis-table";
import Algorithm2Params, { useAlgorithm2Params } from "../../algorithm2-params";
import {
  useAlgorithm2TradeParams,
  useAlgorithm2TradeStart,
  useAlgorithm2TradeStop,
} from "../../services/algorithm2";
import { useTradingRedirect } from "../../hooks/use-trade-algorithm";
import TradesBalance from "../../components/trades-balance";
import DateRangeSelector from "../../components/date-range-selector";
import { useInteractionContext } from "../../context/interaction-context";
import { formatWallet } from "../../components/wallet-selector";
import IsolatedWalletApp from "../../isolated-wallet-app/isolated-wallet-app";
import MarginApp from "../../margin-app/margin-app";
import Algorithm2SetParams from "../../algorithm2-set-params";
import { Wallet } from "../../model";
import OrdersBalance from "../../components/orders-balance";
import ConfirmDialog, {
  useConfirmDialog,
} from "../../components/confirm-dialog";

function a11yProps(index: number, visible = true) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      visibility: visible ? "visible" : "hidden",
      ...(!visible && { display: "none" }),
    },
  };
}

function App() {
  const { result: currentParams, execute: updated } =
    useAlgorithm2TradeParams();
  const params = useAlgorithm2Params(currentParams);
  const stateResponse = useTradeState();
  const start = useAlgorithm2TradeStart();
  const stop = useAlgorithm2TradeStop();
  const accountId = useAccountContext();
  const { from, to, ticker } = useInteractionContext();

  const isStarted = stateResponse.data?.state !== 0;

  const [tabValue, setTabValue] = useState(0);

  useTradingRedirect();

  useEffect(() => {
    if (tabValue === 5 && params.wallet === Wallet.SPOT) {
      setTabValue(0);
    }
  }, [params.wallet, tabValue]);

  useEffect(() => {
    if (tabValue === 6 && !isStarted) {
      setTabValue(0);
    }
  }, [isStarted, tabValue]);

  const [confirmStopProps, openStopConfirmDialog] = useConfirmDialog(
    async () => {
      await stop.execute(accountId, ticker, params.wallet);

      await stateResponse.refetch();
    },
    "Остановить торговлю?"
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          marginBottom: 1,
        }}
      >
        <DateRangeSelector />
        <Algorithm2Params {...params} disabled={isStarted} />
        <Button
          disabled={stateResponse.isLoading || start.loading || stop.loading}
          onClick={async () => {
            if (!isStarted) {
              await start.execute(accountId, ticker, {
                f: Number(params.f),
                pok: Number(params.pok),
                pr: Number(params.pr),
                q: Number(params.q),
                wallet: params.wallet,
                orderType: params.orderType,
              });
              await stateResponse.refetch();
            } else {
              openStopConfirmDialog();
            }
          }}
        >
          {isStarted ? "Остановить" : "Начать"}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          marginBottom: 1,
        }}
      ></Box>
      <Box sx={{ marginBottom: 1 }}>
        <Tabs
          value={tabValue}
          onChange={(_event, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="trading tabs"
        >
          <Tab label="Сделки" {...a11yProps(0)} />
          <Tab label="Заявки" {...a11yProps(1)} />
          <Tab label="Анализ" {...a11yProps(2)} />
          <Tab label="График" {...a11yProps(3)} />
          <Tab label="Баланс" {...a11yProps(4)} />
          <Tab
            label={`${formatWallet(params.wallet)} кошелёк`}
            {...a11yProps(5, params.wallet !== Wallet.SPOT)}
          />
          <Tab label={`Изменить параметры`} {...a11yProps(6, isStarted)} />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <TradesBalance q={parseFloat(params.q)} wallet={params.wallet} />
      )}
      {tabValue === 1 && (
        <OrdersBalance q={parseFloat(params.q)} wallet={params.wallet} />
      )}
      {tabValue === 2 && <Algorithm2TradeAnalysisTable />}
      {tabValue === 3 && <Chart date={from} endDate={to} />}
      {tabValue === 4 && <BalanceHistoryChart date={from} endDate={to} />}
      {tabValue === 5 && (
        <Box sx={{ marginTop: 1, display: "flex", flex: 1 }}>
          {params.wallet === Wallet.MARGIN ? (
            <MarginApp />
          ) : (
            <IsolatedWalletApp />
          )}
        </Box>
      )}
      {tabValue === 6 && <Algorithm2SetParams onUpdated={updated} />}

      <ConfirmDialog {...confirmStopProps} />
    </Box>
  );
}

export default App;
