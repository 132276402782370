import { Route, Routes } from "react-router-dom";
import Algorithm2App from "./algorithm2-app";
import Algorithm1AnalysisApp from "./algorithm1-analysis-app";
import Trading from "./pages/trading/algorithm1-trade-app";
import Algorithm2Trading from "./pages/trading/algorithm2-trade-app";
import MarginApp from "./margin-app/margin-app";
import IndexPage, { useStoreLocation } from "./index-page";
import FeeApp from "./fee-app/fee-app";
import IsolatedWalletApp from "./isolated-wallet-app/isolated-wallet-app";
import BcsApp from "./bcs";
import BcsFutures from "./bcs/futures";
import BinanceApp from "./binance";
import BcsTradeApp from "./bcs/bcs-trade-app";
import BcsBalanceApp from "./bcs/bcs-balance-app";
import BcsAllTradesApp from "./bcs/bcs-all-trades.app";
import BcsPortfolioApp from "./bcs/bcs-portfolio-app";
import LimitsApp from "./limits-app/limits-app";
import SpotWalletApp from "./spot-wallet-app/spot-wallet-app";
import BcsFuturesTradeApp from "./bcs/futures/bcs-futures-trade-app";
import DefaultPage from "./default-page";

const Routing = () => {
  useStoreLocation();

  return (
    <Routes>
      <Route path="/" element={<IndexPage />}>
        <Route path="/binance" element={<BinanceApp />}>
          <Route path="/binance/trading" element={<Trading />}>
            <Route path="/binance/trading/algorithm1" element={<Trading />} />
          </Route>
          <Route
            path="/binance/trading/algorithm2"
            element={<Algorithm2Trading />}
          />
          <Route
            path="/binance/analysis/algorithm1"
            element={<Algorithm1AnalysisApp />}
          ></Route>
          <Route path="/binance/limits" element={<LimitsApp />}></Route>
          <Route path="/binance/algorithm2" element={<Algorithm2App />}></Route>
          <Route path="/binance/margins" element={<MarginApp />}></Route>
          <Route
            path="/binance/isolated_wallet"
            element={<IsolatedWalletApp />}
          ></Route>
          <Route
            path="/binance/spot_wallet"
            element={<SpotWalletApp />}
          ></Route>
          <Route path="/binance/fee" element={<FeeApp />}></Route>
        </Route>
        <Route path="/bcs" element={<BcsApp />}>
          <Route path="/bcs/trading" element={<BcsTradeApp />} />
          <Route path="/bcs/balance" element={<BcsBalanceApp />} />
          <Route path="/bcs/trades" element={<BcsAllTradesApp />} />
          <Route path="/bcs/portfolio" element={<BcsPortfolioApp />} />
        </Route>
        <Route path="/bcs/futures" element={<BcsFutures />}>
          <Route path="/bcs/futures/trading" element={<BcsFuturesTradeApp />} />
        </Route>
        <Route path="*" element={<DefaultPage />} />
      </Route>
    </Routes>
  );
};

export default Routing;
