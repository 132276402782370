import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { CircularProgress, FormControl, Typography } from "@mui/material";
import NetworkSelector from "./network-selector";
import { CopyAll } from "@mui/icons-material";
import { useDepositAddress } from "../../services/api";
import copyTextToClipboard from "../../utils/copy-text-to-clipboard";
import { useNotificationDispatch } from "../../context/notification-context";

type Props = {
  onClose: () => void;

  asset: string;
};

const DepositDialog = ({ onClose, asset }: Props) => {
  const [network, setNetwork] = useState("");
  const addressResponse = useDepositAddress(asset, network);
  const address =
    addressResponse.isLoading === false
      ? addressResponse.data?.address || ""
      : "";
  const notificationDispatch = useNotificationDispatch();

  return (
    <Box sx={{ width: 600 }}>
      <DialogTitle
        sx={{
          color: (theme) => theme.palette.grey[400],
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>Ввод {asset}</Box>
        {addressResponse.isLoading && <CircularProgress size={16} />}
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px 20px 24px",
          gap: 2,
        }}
      >
        <NetworkSelector
          asset={asset}
          value={network}
          onValueChange={setNetwork}
        />
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
        >
          <TextField
            label="Адрес"
            size="medium"
            variant="outlined"
            disabled
            value={address}
            sx={{ flexGrow: 1 }}
            helperText={
              addressResponse.data?.url ? (
                <Box
                  target="__blank"
                  href={addressResponse.data.url}
                  component={"a"}
                  sx={{
                    textDecoration: "none",
                    color: (theme) => theme.palette.grey[400],
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {addressResponse.data.url}
                  </Typography>
                </Box>
              ) : null
            }
            FormHelperTextProps={{
              sx: {
                position: "absolute",
                bottom: -24,
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />
          <Button
            disabled={address.trim() === ""}
            onClick={async () => {
              try {
                await copyTextToClipboard(address);
                notificationDispatch({
                  type: "success",
                  payload: "Адрес скопирован в буффер обмена",
                });
              } catch (e) {
                notificationDispatch({
                  type: "error",
                  payload:
                    "Ошибка при копировании. Необходимо скопировать адрес вручную",
                });
              }
            }}
          >
            <CopyAll />
          </Button>
        </FormControl>
      </Box>

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Закрыть
        </Button>
      </DialogActions>
    </Box>
  );
};

export default DepositDialog;
