import {
  Field,
  dxPivotGridSummaryCell,
} from "devextreme/ui/pivot_grid/data_source";
import { Trade } from "../services/trade-api";

const hasDuplicatedColumn = (e: dxPivotGridSummaryCell) => {
  if (
    e.children("column").length &&
    e.parent("column")?.children("column").length === 1
  ) {
    return true;
  }

  return false;
};

const hideDuplicatedColumn = (e: dxPivotGridSummaryCell) => {
  if (hasDuplicatedColumn(e)) {
    return null;
  }
  return e.value();
};

const hideTotalRow = (e: dxPivotGridSummaryCell) => {
  if (!e.parent("row")) {
    return null;
  }
  if (hasDuplicatedColumn(e)) {
    return null;
  }
  return e.value();
};

export const fields: Field[] = [
  {
    caption: "Asset",
    dataField: "asset",
    area: "row",
  },
  {
    caption: "Направление",
    dataField: "direction",
    area: "row",
    format: (v: number) => {
      return v === -1 ? "SELL" : "BUY";
    },
  },
  {
    dataField: "createdAt",
    dataType: "date",
    area: "column",
    expanded: true,
  },
  {
    groupName: "createdAt",
    groupInterval: "quarter",
    visible: false,
  },
  {
    groupName: "createdAt",
    groupInterval: "day",
    dataType: "date",
    groupIndex: 4,
  },
  {
    displayFolder: "Measures",
    isMeasure: true,
    name: "Delta Count",
    caption: "Изм. поз.",
    dataType: "number",
    summaryType: "sum",
    format: {
      precision: 0,
    },
    area: "data",
    selector(data: Trade) {
      return (data.value / data.price) * data.direction;
    },
    calculateSummaryValue: hideTotalRow,
  },
  {
    displayFolder: "Measures",
    isMeasure: true,
    caption: "Объем",
    dataField: "value",
    dataType: "number",
    summaryType: "sum",
    format: {
      precision: 0,
    },
    area: "data",
    calculateSummaryValue: hideDuplicatedColumn,
  },
  {
    displayFolder: "Measures",
    isMeasure: true,
    caption: "Прибыль",
    dataType: "number",
    summaryType: "custom",
    format: {
      precision: 0,
    },
    area: "data",
    calculateCustomSummary: (options) => {
      switch (options.summaryProcess) {
        case "start":
          options.totalValue = {
            startPrice: 0,
            endPrice: 0,
            q: 0,
            position: 0,
            commission: 0,
          };
          break;
        case "calculate":
          const d = options.value;
          options.totalValue = {
            ...options.totalValue,
            startPrice: options.totalValue.startPrice || d.price,
            endPrice: d.price,
            q: options.totalValue.q + (d.value / d.price) * d.direction,
            position: options.totalValue.position - d.value * d.direction,
            commission: options.totalValue.commission + d.commission,
          };
          break;
        case "finalize":
          options.totalValue =
            options.totalValue.position +
              options.totalValue.endPrice * options.totalValue.q -
              options.totalValue.commission || 0;
          break;
      }
    },

    calculateSummaryValue(e) {
      if (hasDuplicatedColumn(e)) {
        return null;
      }

      if (e.children("row").length) {
        return e.children("row").reduce((sum, e) => {
          return sum + (e.value() || 0);
        }, 0);
      }

      return e.value() || 0;
    },
  },
  {
    displayFolder: "Measures",
    isMeasure: true,
    caption: "Коммиссия",
    dataField: "commission",
    dataType: "number",
    summaryType: "sum",
    format: {
      precision: 2,
    },
    area: "data",
    calculateSummaryValue: hideDuplicatedColumn,
  },
];
