import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { CoinInfo } from "../services/api";
import AssetIcon from "./asset-icon";
import Box from "@mui/material/Box/Box";
import Input from "@mui/material/Input";

import { useState } from "react";
import Search from "@mui/icons-material/Search";
import useDebounce from "../hooks/use-debounce";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxWidth: 200,
      maxHeight: ITEM_HEIGHT * 6.8 + ITEM_PADDING_TOP,
    },
  },
};

type Props = {
  disabled?: boolean;
  value?: string;
  label: string;
  options?: CoinInfo[];
  onValueChange: (string: string) => void;
};

const CoinSelector = ({
  disabled,
  value,
  onValueChange,
  label,
  options,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 100);
  return (
    <FormControl
      sx={{
        width: "100%",
      }}
    >
      <InputLabel focused variant="outlined">
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        labelId="coin"
        value={value}
        onChange={({ target: { value } }) => {
          if (typeof value === "string") {
            onValueChange(value);
          }
        }}
        margin="dense"
        input={<OutlinedInput label={label} />}
        MenuProps={MenuProps}
      >
        <Input
          id="asset-search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: "100%",
            padding: "0 1rem",
            position: "static",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => e.stopPropagation()}
          startAdornment={<Search />}
        />

        {(options || [])
          .filter(({ coin }) =>
            coin.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
          )
          .map(({ coin }) => (
            <MenuItem key={coin} value={coin}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <AssetIcon asset={coin} size={18} />
                <ListItemText primary={coin} />
              </Box>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CoinSelector;
