import ruLocale from "date-fns/locale/ru";
import {
  DatePicker as DatePickerComponent,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatISO, parseISO } from "date-fns";

type Props = {
  value: string;
  label?: string;
  onValueChange: (value: string) => void;
};

const DatePicker = ({ value, label, onValueChange }: Props) => {
  return (
    <LocalizationProvider adapterLocale={ruLocale} dateAdapter={AdapterDateFns}>
      <DatePickerComponent
        label={label}
        value={parseISO(value)}
        onChange={(newValue) => {
          if (newValue) {
            onValueChange(formatISO(newValue, { representation: "date" }));
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
