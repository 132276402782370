import { formatNumber0 } from "../../../utils/format";
import AssetIcon from "../asset-icon";
import Box from "@mui/material/Box";

export default function PortfolioTooltipTemplate(info: any) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.25rem",
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        <AssetIcon size={20} asset={info.seriesName} />
        <Box>{info.seriesName}</Box>
      </Box>

      <div>Стоимость: {formatNumber0(info.value)}</div>
      <div>Доля: {info.percentText}</div>
    </Box>
  );
}
