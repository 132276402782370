import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
    },
  },
};

export type Currency = "SUR" | "USD";

type Props = {
  value: Currency;
  onChange: (value: Currency) => void;
};

const CurrencySelector = ({ value, onChange }: Props) => {
  return (
    <FormControl
      sx={{
        width: "100%",
      }}
    >
      <InputLabel focused variant="outlined">
        Валюта
      </InputLabel>
      <Select
        labelId="Currency"
        value={value}
        onChange={({ target: { value } }) => {
          onChange(value as Currency);
        }}
        input={<OutlinedInput label="Валюта" />}
        MenuProps={MenuProps}
      >
        <MenuItem value={"SUR"}>
          <ListItemText primary={"SUR"} />
        </MenuItem>
        <MenuItem value={"USD"}>
          <ListItemText primary={"USD"} />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default CurrencySelector;
