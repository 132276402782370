import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Outlet } from "react-router-dom";
import Routing from "./routing";
import ContextProviders from "./context/context-providers";
import Notifications from "./components/notification";

ReactDOM.render(
  <React.StrictMode>
    <>
      <BrowserRouter>
        <ContextProviders>
          <Notifications />
          <Routing />
          <Outlet />
        </ContextProviders>
      </BrowserRouter>
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
