import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

import {
  useAccountContext,
  useAccountDispatch,
} from "../../context/account-context";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Arrow from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import Modal from "../../../components/modal";
import Box from "@mui/material/Box";
import NotificationsOff from "@mui/icons-material/NotificationsOff";
import NotificationsActive from "@mui/icons-material/NotificationsNone";
import TradeIndicator from "./trade-indicator";
import AssetIcon from "../../components/asset-icon";
import TextField from "@mui/material/Input";
import Search from "@mui/icons-material/Search";
import {
  useAccounts,
  Account,
  useSetAccountNotifications,
} from "../services/accounts";
import AddAccountDialog from "../components/add-account-form";

const renderNotificationIcon = (account?: Account, fontSize?: string) => {
  return account?.notificationsEnabled ? (
    <NotificationsActive sx={{ fontSize: fontSize }} />
  ) : (
    <NotificationsOff
      sx={{
        color: (theme) => theme.palette.error[theme.palette.mode],
        fontSize: fontSize,
      }}
    />
  );
};

const AccountSelector = () => {
  const response = useAccounts();

  const accounts = response.data || [];

  const selectedAccountId = useAccountContext();

  const selectedAccount = accounts.find((x) => x.asset === selectedAccountId);

  const [searchTerm, setSearchTerm] = useState("");

  const setValue = useAccountDispatch();

  const setNotificationsValue = useSetAccountNotifications(() => {
    response.refetch();
  });

  const toggleNotifications = () => {
    if (!selectedAccount || response.isLoading) {
      return;
    }

    if (
      selectedAccount.notificationsEnabled &&
      window.confirm(`Отключить уведомления для ${selectedAccount.asset}?`)
    ) {
      setNotificationsValue.execute({
        asset: selectedAccountId,
        notificationsEnabled: false,
      });
    } else {
      setNotificationsValue.execute({
        asset: selectedAccountId,
        notificationsEnabled: true,
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setAddDialogIsOpen(false);
    response.refetch();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 0.4,
        alignItems: "center",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <AssetIcon asset={selectedAccountId} size={"1.5rem"} />
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              textTransform: "none",
            }}
            endIcon={<Arrow />}
          >
            <Typography variant="body1">{selectedAccountId}</Typography>
          </Button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <TextField
            id="asset-search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: "100%",
              padding: "0 1rem",
            }}
            startAdornment={<Search />}
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "min-content min-content",
            }}
          >
            {accounts
              ?.filter((account) => {
                return account.asset
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase());
              })
              .map((account) => {
                if (account.asset === selectedAccountId) {
                  return null;
                }
                return (
                  <MenuItem
                    key={account.asset}
                    onClick={() => {
                      setValue(account.asset);
                      handleClose();
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 0.6,
                        flex: 1,
                        alignItems: "center",
                      }}
                    >
                      <TradeIndicator asset={account.asset} />
                      <AssetIcon asset={account.asset} size={"1rem"} />
                      <span>{account.asset}</span>
                      <Box sx={{ marginLeft: "auto" }}>
                        {renderNotificationIcon(account, "1rem")}
                      </Box>
                    </Box>
                  </MenuItem>
                );
              })}
          </Box>
          <MenuItem
            sx={{ justifyContent: "center" }}
            onClick={() => {
              setAddDialogIsOpen(true);
            }}
          >
            Добавить
          </MenuItem>
        </Menu>
      </Box>
      <Button
        id="notification"
        onClick={toggleNotifications}
        size="small"
        sx={{
          margin: 0,
          padding: 0,
          textTransform: "none",
          minWidth: 0,
        }}
        endIcon={renderNotificationIcon(selectedAccount)}
      />
      <Modal isOpen={addDialogIsOpen} onClose={handleDialogClose}>
        <AddAccountDialog onClose={handleDialogClose} />
      </Modal>
    </Box>
  );
};

export default AccountSelector;
