import Box from "@mui/material/Box";
import AssetIcon from "./asset-icon";

const renderAssetCell = (params: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      <AssetIcon asset={params.value} size="2rem" />
      <Box>{params.value}</Box>
    </Box>
  );
};

export default renderAssetCell;
