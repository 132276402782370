import Box from "@mui/material/Box";

import { usePortfolioHistory } from "../../services/balance-api";

import ChartCore, {
  ArgumentAxis,
  Legend,
  IVisualRangeProps,
  ZoomAndPan,
  Tooltip,
  Point,
  SeriesTemplate,
  CommonSeriesSettings,
  Aggregation,
} from "devextreme-react/chart";
import { useMemo, useState } from "react";
import { isToday } from "date-fns";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stacked from "@mui/icons-material/StackedBarChart";
import Difference from "@mui/icons-material/ShowChart";
import PortfolioTooltipTemplate from "./portfolio-tooltip-template";
import { useInteractionContext } from "../../../context/interaction-context";

const palette = [
  "#e81e63",
  "#9c27b0",
  "#673ab7",
  "#4b51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#f44336",
  "#cc495b",
  "#795548",
  "#9e9e9e",
  "#607d8b",
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { padding: 0, margin: 0 },
  };
}

const minVisualRangeLength = { minutes: 10 };

export const PortfolioChart = () => {
  const { from, to } = useInteractionContext();
  const history = usePortfolioHistory(from, to);
  const [tabValue, setTabValue] = useState(1);
  const endDate = new Date(to);

  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  const [visualRange, setVisualRange] = useState<IVisualRangeProps>({
    startValue: from,
    endValue: isToday(new Date(to))
      ? new Date().toString()
      : endDate.toString(),
  });

  const defaultVisualRange = useMemo(() => ({ startValue: from }), [from]);

  return (
    <Box
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
        aria-label="chart tabs"
        sx={{ padding: 0, marginBottom: 1 }}
      >
        <Tab {...a11yProps(0)} icon={<Difference />} />
        <Tab {...a11yProps(1)} icon={<Stacked />} />
      </Tabs>
      <Box
        style={{
          flexGrow: 1,
        }}
      >
        <ChartCore
          height="100%"
          dataSource={history.data || []}
          onLegendClick={(e: any) => {
            e.target.isVisible() ? e.target.hide() : e.target.show();
          }}
          palette={palette}
          autoHidePointMarkers={false}
        >
          <SeriesTemplate nameField="asset" />

          <CommonSeriesSettings
            argumentField="createdAt"
            valueField="amount"
            type={tabValue === 0 ? "stackedarea" : "fullstackedarea"}
          >
            <Point visible={false} />
            <Aggregation enabled method="avg" />
          </CommonSeriesSettings>

          <ZoomAndPan argumentAxis="both" />
          <ArgumentAxis
            defaultVisualRange={defaultVisualRange}
            minVisualRangeLength={minVisualRangeLength}
            visualRange={visualRange}
            onVisualRangeChange={setVisualRange}
            argumentType="datetime"
          />
          <Legend
            visible={true}
            verticalAlignment="bottom"
            horizontalAlignment="center"
          />
          <Tooltip enabled contentRender={PortfolioTooltipTemplate} />
        </ChartCore>
      </Box>
    </Box>
  );
};
