import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { SxProps, Theme } from "@mui/material/styles";
import { useState } from "react";
import Modal from "./modal";

type Props = {
  isVisible: boolean;
  text: string;
  onClose: () => void;
  onConfirm: () => void;
  sx?: SxProps<Theme>;
};

export const useConfirmDialog = (
  onConfirm: () => void,
  text: string = ""
): [Props, () => void] => {
  const [isVisible, setIsVisible] = useState(false);

  return [
    {
      isVisible,
      onClose: () => setIsVisible(false),
      onConfirm,
      text,
    },
    () => setIsVisible(true),
  ];
};

const ConfirmDialog = ({ onClose, onConfirm, text, sx, isVisible }: Props) => {
  return isVisible ? (
    <Modal isOpen={isVisible} onClose={onClose}>
      <Box sx={{ ...sx }}>
        <DialogTitle sx={{ color: (theme) => theme.palette.grey[400] }}>
          {text}
        </DialogTitle>

        <DialogActions>
          <Button onClick={onClose}>Нет</Button>
          <Button
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Да
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  ) : null;
};

export default ConfirmDialog;
