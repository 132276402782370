import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useAssetInfo, usePrice } from "../services/trade-api";
import { formatNumber0, formatNumber2 } from "../../utils/format";
import OrderTypeSelector, { OrderType } from "./order-type-selector";

export type Params = {
  f: number;
  q: number;
  orderType: OrderType;
  price?: number;
  lotSize?: number;
  step?: number;
  collateral?: number;
};

export const floor = (value: number, interval: number) => {
  if (interval > 1) {
    return Math.floor(value / interval) * interval;
  }
  return Math.floor(value / interval) / (1 / interval);
};

export const useAlgorithmParams = (params?: Params | null) => {
  const [f, setF] = useState("200");
  const [q, setQ] = useState("1");
  const price = usePrice().data || 0;
  const [orderType, setOrderType] = useState<OrderType>(
    params?.orderType || OrderType.Market
  );
  const { lotSize, step, sellCollateral, buyCollateral } = useAssetInfo()
    .data || { lotSize: 0, step: 0 };
  const collateral =
    sellCollateral && buyCollateral
      ? Math.max(sellCollateral, buyCollateral)
      : undefined;

  useEffect(() => {
    if (params) {
      setF(params.f.toString());
      setQ(params.q.toString());
      setOrderType(params.orderType);
    }
  }, [params]);

  useEffect(() => {
    if (!params && price && step) {
      setF((f) => (f === "200" ? floor(price * 0.01, step).toString() : f));
    }
  }, [price, params, step]);

  return {
    f,
    q,
    price,
    lotSize,
    step,
    orderType,
    collateral,
    onOrderTypeChange: setOrderType,
    onFChange: setF,
    onQChange: setQ,
  };
};

type Props = {
  f: string;
  q: string;
  price?: number;
  lotSize?: number;
  step?: number;
  collateral?: number;
  orderType: OrderType;
  onQChange: (value: string) => void;
  onFChange: (value: string) => void;
  onOrderTypeChange: (value: OrderType) => void;
  disabled?: boolean;
};

const AlgorithmParams = (props: Props) => {
  const qValue =
    props.collateral === undefined
      ? Number(props.q) * (props.lotSize || 0) * (props.price || 0)
      : Number(props.q) * props.collateral;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      <TextField
        disabled={props.disabled}
        label={`f${
          props.price
            ? ` (≈${formatNumber0((Number(props.f) / props.price) * 100)}%)`
            : ""
        }`}
        type={"number"}
        value={props.f}
        onChange={(e) => props.onFChange(e.target.value)}
        inputProps={{ step: props.step, min: "1" }}
        error={parseFloat(props.f) <= 0}
      />
      <TextField
        disabled={props.disabled}
        label={`q (≈${formatNumber2(qValue)})`}
        type={"number"}
        value={props.q}
        onChange={(e) => props.onQChange(e.target.value)}
        inputProps={{ step: "1", min: "1" }}
        error={parseInt(props.q) < 1}
      />
      <OrderTypeSelector
        disabled={props.disabled}
        orderType={props.orderType}
        onOrderTypeChange={props.onOrderTypeChange}
      />
    </Box>
  );
};

export default AlgorithmParams;
