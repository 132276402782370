import Box from "@mui/material/Box";

const Layout: React.FC<{
  navigation: React.ReactNode;
}> = ({ children, navigation }) => {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: (theme) => theme.palette.background.default,
        flexDirection: "column",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {navigation}
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
