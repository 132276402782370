import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import {
  Account,
  useAccounts,
  useSetAccountNotifications,
} from "../services/accounts";
import {
  useAccountContext,
  useAccountDispatch,
} from "../context/account-context";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Arrow from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import Modal from "../components/modal";
import AddAccountDialog from "../components/add-account-form";
import Box from "@mui/material/Box";
import NotificationsOff from "@mui/icons-material/NotificationsOff";
import NotificationsActive from "@mui/icons-material/NotificationsNone";
import TradeIndicator from "../components/trade-indicator";
import ExchangeIcon from "../binance/components/exchange-selector/exchange-icon";
import { useInteractionDispatch } from "../context/interaction-context";
import { Wallet } from "../model";

const renderNotificationIcon = (account?: Account, fontSize?: string) => {
  return account?.notificationsEnabled ? (
    <NotificationsActive sx={{ fontSize: fontSize }} />
  ) : (
    <NotificationsOff
      sx={{
        color: (theme) => theme.palette.error[theme.palette.mode],
        fontSize: fontSize,
      }}
    />
  );
};

export const getAllowedWallets = (account?: Account) => {
  const allowedWallets =
    account?.exchange === "binance"
      ? [Wallet.SPOT, Wallet.MARGIN, Wallet.ISOLATED]
      : [Wallet.SPOT];

  return allowedWallets;
};

const AccountSelector = () => {
  const response = useAccounts();

  const accounts = response.data || [];

  const selectedAccountId = useAccountContext();
  const interactionDispatch = useInteractionDispatch();

  const selectedAccount = accounts.find((x) => x.id === selectedAccountId);

  const setValue = useAccountDispatch();

  const setNotificationsValue = useSetAccountNotifications(() => {
    response.refetch();
  });

  useEffect(() => {
    interactionDispatch({
      type: "SET_ALLOWED_WALLETS",
      payload: getAllowedWallets(selectedAccount),
    });
  }, [interactionDispatch, selectedAccount]);

  const format = (value: number) => {
    const data = accounts?.find((a) => a.id === value);
    if (!data) {
      return "";
    }
    return data.name;
  };

  const toggleNotifications = () => {
    if (!selectedAccount || response.isLoading) {
      return;
    }

    if (
      selectedAccount.notificationsEnabled &&
      window.confirm(`Отключить уведомления для ${selectedAccount.name}?`)
    ) {
      setNotificationsValue.execute({
        id: selectedAccountId,
        notificationsEnabled: false,
      });
    } else {
      setNotificationsValue.execute({
        id: selectedAccountId,
        notificationsEnabled: true,
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setAddDialogIsOpen(false);
    response.refetch();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 0.4,
        alignItems: "center",
      }}
    >
      <Box>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="small"
          sx={{
            margin: 0,
            padding: 0,
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
          endIcon={<Arrow />}
        >
          <ExchangeIcon exchange={selectedAccount?.exchange || ""} size={22} />
          <Typography variant="body1">{format(selectedAccountId)}</Typography>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {accounts?.map((account) => {
            if (account.id === selectedAccountId) {
              return null;
            }
            return (
              <MenuItem
                key={account.id}
                onClick={() => {
                  setValue(account.id);
                  handleClose();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 0.6,
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <ExchangeIcon exchange={account.exchange} size={15} />
                  <TradeIndicator accountId={account.id} />
                  <span>{account.name}</span>
                  <Box sx={{ marginLeft: "auto" }}>
                    {renderNotificationIcon(account, "1rem")}
                  </Box>
                </Box>
              </MenuItem>
            );
          })}
          <MenuItem
            onClick={() => {
              setAddDialogIsOpen(true);
            }}
          >
            Добавить
          </MenuItem>
        </Menu>
      </Box>
      <Button
        id="notification"
        onClick={toggleNotifications}
        size="small"
        sx={{
          margin: 0,
          padding: 0,
          textTransform: "none",
          minWidth: 0,
        }}
        endIcon={renderNotificationIcon(selectedAccount)}
      />
      <Modal isOpen={addDialogIsOpen} onClose={handleDialogClose}>
        <AddAccountDialog onClose={handleDialogClose} />
      </Modal>
    </Box>
  );
};

export default AccountSelector;
