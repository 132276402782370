import Box from "@mui/material/Box";
import DateRangeSelector from "../components/date-range-selector";
import AllTradesTable from "./components/all-trades-table";
import AllBalanceTable from "./components/all-balance-table";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import AllTradesPivotTable from "./components/all-trades-pivot";
import OrdersTable from "./components/orders-table";
import VolumeChart from "./components/chart/volume-chart";
import { TotalMarginChart } from "./components/chart/total-margin-chart";

function a11yProps(index: number, visible = true) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      visibility: visible ? "visible" : "hidden",
      ...(!visible && { display: "none" }),
    },
  };
}

const BcsAllTradesApp = () => {
  const [tabValue, setTabValue] = useState(0);
  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
        }}
      >
        <DateRangeSelector />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <Tabs
          value={tabValue}
          onChange={(_event, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="trading tabs"
        >
          <Tab label="Сделки" {...a11yProps(0)} />
          <Tab label={`Заявки`} {...a11yProps(1)} />
          <Tab label={`Сводная таблица`} {...a11yProps(2)} />
          <Tab label={`График объема`} {...a11yProps(3)} />
          <Tab label={`График прибыли`} {...a11yProps(4)} />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 300px",
            flexGrow: 1,
          }}
        >
          <AllTradesTable />
          <AllBalanceTable />
        </Box>
      )}
      {tabValue === 1 && (
        <Box
          style={{
            flexGrow: 1,
          }}
        >
          <OrdersTable />
        </Box>
      )}
      {tabValue === 2 && (
        <Box
          style={{
            flexGrow: 1,
          }}
        >
          <AllTradesPivotTable />
        </Box>
      )}
      {tabValue === 3 && (
        <Box
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <VolumeChart />
        </Box>
      )}
      {tabValue === 4 && (
        <Box
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TotalMarginChart />
        </Box>
      )}
    </Box>
  );
};

export default BcsAllTradesApp;
