import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NotificationProvider } from "./notification-context";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMemo } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import useChartTheme from "../hooks/use-chart-theme";
import { InteractionContextProvider } from "./interaction-context";

const queryClient = new QueryClient();

const ContextProviders: React.FC = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const mdTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  useChartTheme();

  return (
    <ThemeProvider theme={mdTheme}>
      <QueryClientProvider client={queryClient}>
        <InteractionContextProvider>
          <NotificationProvider>{children}</NotificationProvider>
        </InteractionContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default ContextProviders;
