import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { useOrderTrades } from "./services/orders-api";
import { DataGrid } from "@mui/x-data-grid";
import {
  createNumberColumnFormatter,
  formatDateColumn,
  formatNumber2,
  formatNumber5,
} from "./utils/format";

type Props = {
  orderId: string;
  onClose: () => void;
};

const columns = [
  {
    field: "time",
    headerName: "Дата Время",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },
  {
    field: "price",
    headerName: "Цена",
    type: "number",
    width: 120,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "qty",
    headerName: "Количество",
    type: "number",
    width: 120,
    renderCell: (params: any) => {
      return params.value;
    },
  },
  {
    field: "commission",
    headerName: "Комиссия",
    type: "number",
    width: 150,
    valueFormatter: createNumberColumnFormatter(formatNumber5),
    renderCell: (params: any) => {
      return `${params.formattedValue} ${params.row.commissionAsset}`;
    },
  },
];

export const OrderTradesTable = ({ orderId, onClose }: Props) => {
  const response = useOrderTrades(orderId);

  return (
    <Box
      sx={{
        width: 600,
        display: "flex",
        flexDirection: "column",
        height: "60vh",
        color: (theme) => theme.palette.grey[400],
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        Сделки
        <Button
          aria-label="Закрыть"
          sx={{ marginLeft: "auto", padding: 0, minWidth: "auto" }}
          size="small"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent sx={{ flex: 1, flexGrow: 1 }}>
        <DataGrid
          getRowId={(x) => x.orderId}
          rows={response.data || []}
          columns={columns}
          disableSelectionOnClick
          loading={response.isLoading}
          hideFooter
        />
      </DialogContent>
    </Box>
  );
};
