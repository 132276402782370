import BaseLoanDialog from "./base-loan-form";

type Props = {
  onClose: () => void;
  asset: string;
  borrow: (asset: string, amount: number) => void;
  isLoading: boolean;
  maxBorrowable: number;
};

const BaseBorrowDialog = ({
  onClose,
  asset,
  borrow,
  isLoading,
  maxBorrowable,
}: Props) => {
  return (
    <BaseLoanDialog
      title={`Взять ${asset}`}
      onSubmit={(amount) => {
        borrow(asset, amount);
      }}
      maxValue={maxBorrowable}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default BaseBorrowDialog;
