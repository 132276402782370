import ChartCore, {
  Legend,
  Tooltip,
  Animation,
  SeriesTemplate,
  CommonSeriesSettings,
  ArgumentAxis,
  Label,
} from "devextreme-react/chart";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { useVolume } from "../../services/asset";
import { useInteractionContext } from "../../../context/interaction-context";
import { formatNumber0 } from "../../../utils/format";
import TradeVolume from "../trade-volume";
import { getAssetIconLink } from "../asset-icon";
import { useTradeMargin } from "../../services/trade-api";
import VolumeChartTooltipTemplate from "./volume-chart-tooltip-template";

const customizeSeries = (direction: string) => {
  const isSell = direction.toString() === "-1";
  return {
    name: isSell ? "Sell" : "Buy",
    color: isSell ? "#d32f2f" : "#388e3c",
  };
};

const ArgumentAxisLabel = (data: { value: any; valueText: string }) => {
  return (
    <svg overflow="visible">
      <image
        clipPath="url(#asset-clip)"
        y="0"
        width="50"
        height="30"
        href={getAssetIconLink(data.valueText)}
      ></image>
      <text
        className="template-text"
        x="25"
        y="49"
        textAnchor="middle"
        fill="currentColor"
        fontSize="12px"
      >
        {data.valueText}
      </text>
    </svg>
  );
};

const VolumeChart = () => {
  const { from: date, to: endDate } = useInteractionContext();

  const marginResponse = useTradeMargin(date, endDate);

  const data = useVolume(date, endDate).result || [];
  const commonBuyValue = data.reduce(
    (sum, d) => sum + (d.direction === 1 ? d.value : 0),
    0
  );

  const commonSellValue = data.reduce(
    (sum, d) => sum + (d.direction === -1 ? d.value : 0),
    0
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
      <svg width="0" height="0">
        <defs>
          <clipPath id="asset-clip">
            <circle x={25} y={15} r={15} transform="translate(25, 15)" />
          </clipPath>
        </defs>
      </svg>
      <Box sx={{ flexGrow: 1, color: (theme) => theme.palette.text.secondary }}>
        <ChartCore height="100%" dataSource={data}>
          <Animation enabled={false} />

          <ArgumentAxis>
            <Label render={ArgumentAxisLabel} />
          </ArgumentAxis>

          <CommonSeriesSettings
            valueField="value"
            argumentField="asset"
            type="stackedbar"
          />
          <SeriesTemplate
            nameField="direction"
            customizeSeries={customizeSeries}
          />
          <Legend visible={false} />
          <Tooltip
            enabled
            shared
            format={formatNumber0}
            contentRender={VolumeChartTooltipTemplate}
          />
        </ChartCore>
      </Box>

      <Paper sx={{ display: "flex", gap: 5, padding: 2, marginTop: "auto" }}>
        <Box sx={{ display: "flex", gap: 1, padding: 0, alignItems: "center" }}>
          <Typography variant="body1">
            {formatNumber0(commonBuyValue)}
          </Typography>

          <TradeVolume
            sx={{ width: "70px" }}
            buyVolume={data.reduce(
              (sum, d) => sum + (d.direction === 1 ? d.value : 0),
              0
            )}
            sellVolume={data.reduce(
              (sum, d) => sum + (d.direction === -1 ? d.value : 0),
              0
            )}
          />
          <Typography variant="body1">
            {formatNumber0(commonSellValue)}
          </Typography>
        </Box>
        <Typography variant="body1">
          {formatNumber0(marginResponse.data?.fifo || 0)}
        </Typography>
        <Box sx={{ marginLeft: "auto" }}></Box>
      </Paper>
    </Box>
  );
};

export default VolumeChart;
