import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  createNumberColumnFormatter,
  formatNumber0,
  formatNumber8,
} from "../utils/format";

import { Wallet } from "../model";
import { DataGrid, GridApi } from "@mui/x-data-grid";
import { useBalance } from "../services/api";
import OrderDialog from "../components/order-dialog";
import { useMemo, useState } from "react";
import renderAssetCell from "../components/grid/render-asset-cell";
import Button from "@mui/material/Button/Button";
import Modal from "../components/modal";
import DepositDialog from "../components/deposit-dialog/deposit-dialog";

const columns = [
  {
    field: "asset",
    headerName: "Монета",
    width: 130,
    type: "string",
    renderCell: renderAssetCell,
  },
  {
    field: "free",
    headerName: "Доступно",
    type: "number",
    width: 150,
    valueFormatter: createNumberColumnFormatter(formatNumber8),
  },
  {
    field: "locked",
    headerName: "Заблокировано",
    type: "number",
    width: 150,
    valueFormatter: createNumberColumnFormatter(formatNumber8),
  },
];

const useColumns = ({ onDeposit }: { onDeposit: (asset: string) => void }) => {
  const columnsWithAction = [
    ...columns,
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 450,
      renderCell: (params: any) => {
        const getAsset = (
          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ): string => {
          e.stopPropagation(); // don't select this row after clicking

          const api: GridApi = params.api;
          const thisRow: Record<string, string> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          return thisRow.asset?.toString() || "";
        };

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Button
              onClick={(e) => {
                const asset = getAsset(e);
                onDeposit(asset);
              }}
            >
              Ввод
            </Button>
          </Box>
        );
      },
    },
  ];

  return columnsWithAction;
};

function App() {
  const response = useBalance();
  const totalBalance =
    (response.data || []).find((b) => b.asset.includes("Всего"))?.free || 0;

  const data = useMemo(() => {
    return (response.data || []).filter((b) => !b.asset.includes("Всего"));
  }, [response.data]);

  const [depositDialog, setDepositDialogOpen] = useState("");

  const columns = useColumns({ onDeposit: setDepositDialogOpen });

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          getRowId={(x) => x.asset}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          loading={response.isLoading}
        />
      </Box>
      <Paper sx={{ display: "flex", gap: 3, padding: 2 }}>
        <Typography variant="body1">
          Всего: {formatNumber0(totalBalance)} USDT
        </Typography>
        <Box sx={{ marginLeft: "auto" }}>
          <OrderDialog q={0} onClose={() => {}} wallet={Wallet.SPOT} />
        </Box>
      </Paper>
      <Modal isOpen={!!depositDialog} onClose={() => setDepositDialogOpen("")}>
        <DepositDialog
          asset={depositDialog}
          onClose={() => {
            setDepositDialogOpen("");
          }}
        />
      </Modal>
    </Box>
  );
}

export default App;
