import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatNumber0 } from "../utils/format";
import { createNumberColumnFormatter, formatNumber2 } from "../utils/format";
import { DataGrid } from "@mui/x-data-grid";
import { useFee } from "../services/trade-api";
import { useInteractionContext } from "../context/interaction-context";
import DateRangeSelector from "../components/date-range-selector";

const columns = [
  {
    field: "name",
    headerName: "Аккаунт",
    width: 170,
    type: "string",
  },
  {
    field: "count",
    headerName: "Кол-во сделок",
    type: "number",
    width: 190,
  },
  {
    field: "fee",
    headerName: "Вознаграждение",
    type: "number",
    width: 190,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
];

function FeeApp() {
  const { from, to } = useInteractionContext();
  const response = useFee(from, to);

  const data = response.data || [];

  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  useEffect(() => {
    setSelectionModel((response.data || []).map((x) => x.id));
  }, [response.data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <DateRangeSelector />
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          checkboxSelection
          onSelectionModelChange={(x) => setSelectionModel(x as number[])}
          selectionModel={selectionModel}
          getRowId={(x) => x.id}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          loading={response.isLoading}
        />
      </Box>
      <Paper sx={{ display: "flex", gap: 3, padding: 2 }}>
        <Typography variant="body1">
          Всего:{" "}
          {formatNumber0(
            data
              .filter((x) => selectionModel.includes(x.id))
              .reduce((sum, x) => sum + x.fee, 0)
          )}{" "}
          USDT
        </Typography>
      </Paper>
    </Box>
  );
}

export default FeeApp;
