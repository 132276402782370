import Box from "@mui/material/Box";
import Sparkline, { Tooltip } from "devextreme-react/sparkline";
import { SxProps, Theme } from "@mui/material/styles";
import { getPrecision } from "../../utils/number";
import { Trade, useAssetInfo } from "../services/trade-api";

type Props = {
  sx?: SxProps<Theme>;
  currentPrice: number | null;
  trades: Trade[];
  precision: number;
};

const PriceSparkline = ({ sx, currentPrice, trades }: Props) => {
  const precision = getPrecision(useAssetInfo().data?.step || 1);

  const data = [
    ...trades.map((d) => {
      return { createdAt: new Date(d.createdAt).getTime(), c: d.price };
    }),
    { createdAt: new Date().getTime(), c: currentPrice },
  ];

  return (
    <Box
      sx={{
        height: "20px",
        ...sx,
      }}
    >
      <Sparkline
        dataSource={data}
        showMinMax={true}
        className="sparkline"
        argumentField="createdAt"
        valueField={"c"}
        size={{ height: 20 }}
        type="line"
      >
        <Tooltip
          format={{ precision: precision }}
          paddingLeftRight={4}
          paddingTopBottom={4}
        />
      </Sparkline>
    </Box>
  );
};

export default PriceSparkline;
