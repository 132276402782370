import Box from "@mui/material/Box";
import { usePagedSourceData } from "./services/api";
import { DataGrid } from "@mui/x-data-grid";
import { formatNumber0, formatDateColumn } from "./utils/format";
import { useEffect, useState } from "react";
import useDebounce from "./hooks/use-debounce";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";

const columns = [
  {
    field: "n",
    headerName: "N",
    type: "number",
  },
  {
    field: "createdAt",
    headerName: "T",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },
  {
    field: "price",
    headerName: "X",
    type: "number",
    width: 110,
    valueFormatter: (params: any) => {
      if (typeof params.value !== "number") {
        return "";
      }
      return formatNumber0(params.value);
    },
  },
];

type Props = {
  date: string;
  endDate: string;
};

const SourceTable = ({ date, endDate }: Props) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const debouncedPage = useDebounce(page, 500);
  const debouncedPageSize = useDebounce(pageSize, 500);

  const [rowCountState, setRowCountState] = useState(0);

  const response = usePagedSourceData(
    date,
    endDate,
    debouncedPage,
    debouncedPageSize
  );

  useEffect(() => {
    if (response.result?.totalCount) {
      setRowCountState(response.result.totalCount);
    }
  }, [response.result?.totalCount]);
  return (
    <Box
      style={{
        flexGrow: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGrid
        getRowId={(x) => x.id}
        rows={response.result?.data || []}
        rowCount={rowCountState}
        columns={columns}
        rowsPerPageOptions={[50, 100]}
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        disableSelectionOnClick
        loading={response.loading}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          paddingTop: 1,
        }}
      >
        <TextField
          label="page"
          size="small"
          type={"number"}
          value={page}
          onChange={(e) => setPage(Number(e.target.value))}
        />
        <Slider
          size="small"
          value={page}
          min={0}
          max={Math.floor(rowCountState / pageSize || 1)}
          step={1}
          aria-label="Small"
          onChange={(e, value) => typeof value === "number" && setPage(value)}
          valueLabelDisplay="off"
        />
      </Box>
    </Box>
  );
};

export default SourceTable;
