import { useIsolatedRecharge } from "../../services/isolated-margin";
import BaseRechargeDialog from "./base-recharge-dialog";

type Props = {
  onClose: () => void;
  asset: string;
};

const IsolatedMarginRechargeDialog = ({ onClose, asset }: Props) => {
  const recharge = useIsolatedRecharge(onClose);
  return (
    <BaseRechargeDialog
      asset={asset}
      onClose={onClose}
      recharge={recharge.execute}
      isLoading={recharge.loading}
    />
  );
};

export default IsolatedMarginRechargeDialog;
