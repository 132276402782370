import React, { useState } from "react";
import { AppSettings, getAppSettings, setAppSettings } from "../../storage";

const INIT_VALUE = "SBER";

const AccountContext = React.createContext(INIT_VALUE);

const AccountStateDispatchContext = React.createContext<React.Dispatch<string>>(
  () => {}
);

type Props = {
  initValue: string;
  storeRecord: keyof Pick<AppSettings, "bcsAccount" | "bcsFuturesAccount">;
};

export const AccountContextProvider: React.FC<Props> = ({
  initValue,
  storeRecord,
  children,
}) => {
  const [state, setState] = useState(
    getAppSettings()[storeRecord] || initValue
  );

  return (
    <AccountContext.Provider value={state}>
      <AccountStateDispatchContext.Provider
        value={(value) => {
          setState(value);
          setAppSettings({ [storeRecord]: value });
        }}
      >
        {children}
      </AccountStateDispatchContext.Provider>
    </AccountContext.Provider>
  );
};

export function useAccountContext() {
  return React.useContext(AccountContext);
}

export function useAccountDispatch() {
  return React.useContext(AccountStateDispatchContext);
}
