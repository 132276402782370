import { useAsync } from "react-async-hook";
import createEndpointBase from "../../api/utils/endpoint-base";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../../context/notification-context";
import { useQuery } from "react-query";

export type OHLC = {
  o: number;
  h: number;
  l: number;
  c: number;
  createdAt: Date;
  ticks: number;
};

const endPointBase = createEndpointBase();

export const useOHLC = (
  date: string,
  endDate: string,
  interval: string,
  asset: string
) => {
  return useAsync(
    (
      date: string,
      endDate: string,
      interval: string,
      asset: string
    ): Promise<OHLC[]> => {
      return endPointBase.load(
        `/api/bcs/assets/ohlc/${asset}?date=${date}&end=${endDate}&interval=${interval}`,
        endPointBase.createGetOptions()
      );
    },
    [date, endDate, interval, asset]
  );
};

export const useIISOHLC = (
  date: string,
  endDate: string,
  interval: number,
  asset: string
) => {
  return useAsync(
    (
      date: string,
      endDate: string,
      interval: number,
      asset: string
    ): Promise<OHLC[]> => {
      return endPointBase.load(
        `/api/bcs/assets/iis/ohlc/${asset}?date=${date}&end=${endDate}&interval=${interval}`,
        endPointBase.createGetOptions()
      );
    },
    [date, endDate, interval, asset]
  );
};

export type VolumeDataItem = {
  asset: string;
  direction: -1 | 1;
  value: number;
  margin: number;
};

export const useVolume = (date: string, endDate: string) => {
  return useAsync(
    (date: string, endDate: string): Promise<VolumeDataItem[]> => {
      return endPointBase.load(
        `/api/bcs/trading/volume?date=${date}&end=${endDate}`,
        endPointBase.createGetOptions()
      );
    },
    [date, endDate]
  );
};

export const useAvgPrice = (asset: string) => {
  return useAsync(
    (asset: string): Promise<number> => {
      return endPointBase.load(
        `/api/bcs/assets/avg_price/${asset}`,
        endPointBase.createGetOptions()
      );
    },
    [asset]
  );
};

export type PortfolioLimit = {
  createdAt: Date;
  free: number;
  locked: number;
  asset: string;
  assetType: "asset" | "currency";
  avgPrice: number;
  price: number | null;
  margin: number | null;
};

export const usePortfolioLimits = () => {
  const query = `/api/bcs/assets/limits`;
  const notificationDispatch = useNotificationDispatch();
  return useQuery<PortfolioLimit[]>(
    [query],
    () => endPointBase.load(query, endPointBase.createGetOptions()),
    createErrorHandlingOptions(notificationDispatch)
  );
};
