import {
  useIsolatedBorrow,
  useIsolatedMaxBorrowable,
} from "../../services/isolated-margin";
import BaseBorrowDialog from "./base-borrow-dialog";

type Props = {
  onClose: () => void;
  asset: string;
};

const IsolatedBorrowDialog = ({ onClose, asset }: Props) => {
  const borrow = useIsolatedBorrow(onClose);
  const maxValueResponse = useIsolatedMaxBorrowable(asset);

  return (
    <BaseBorrowDialog
      onClose={onClose}
      asset={asset}
      borrow={borrow.execute}
      isLoading={borrow.loading || maxValueResponse.loading}
      maxBorrowable={maxValueResponse.result || 0}
    />
  );
};

export default IsolatedBorrowDialog;
