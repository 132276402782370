import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";
import Modal from "../../../components/modal";
import { useAccountContext } from "../../context/account-context";

import BuyDialog from "./buy-dialog";
import SellDialog from "./sell-dialog";

type Props = {
  q: number;
  onClose: () => void;
};

const OrderDialog = ({ onClose, q }: Props) => {
  const [buyDialogOpen, setBuyDialogOpen] = useState(false);
  const [sellDialogOpen, setSellDialogOpen] = useState(false);
  const asset = useAccountContext();
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Button
        size="small"
        variant="contained"
        color="success"
        sx={{ padding: 0, margin: 0 }}
        onClick={() => {
          setBuyDialogOpen(true);
        }}
      >
        Buy
      </Button>
      <Button
        sx={{ padding: 0, margin: 0 }}
        size="small"
        variant="contained"
        color="error"
        onClick={() => {
          setSellDialogOpen(true);
        }}
      >
        Sell
      </Button>
      <Modal isOpen={buyDialogOpen} onClose={() => setBuyDialogOpen(false)}>
        <BuyDialog
          asset={asset}
          q={q}
          onClose={() => {
            setBuyDialogOpen(false);
            onClose();
          }}
        />
      </Modal>
      <Modal isOpen={!!sellDialogOpen} onClose={() => setSellDialogOpen(false)}>
        <SellDialog
          asset={asset}
          q={q}
          onClose={() => {
            setSellDialogOpen(false);
            onClose();
          }}
        />
      </Modal>
    </Box>
  );
};

export default OrderDialog;
