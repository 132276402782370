import { Wallet } from "../../model";
import { useSell } from "../../services/binance";
import BaseOrderDialog from "./base-order-form";

type Props = {
  onClose: () => void;
  asset: string;
  wallet: Wallet;
  q: number;
};

const SellDialog = ({ onClose, asset, wallet, q }: Props) => {
  const sell = useSell(onClose);
  return (
    <BaseOrderDialog
      q={q}
      title={`Продать ${asset}`}
      initialWallet={wallet}
      onSubmit={(amount, price, wallet, orderType) => {
        sell.execute(wallet, price, amount, orderType);
      }}
      isLoading={sell.loading}
      onClose={onClose}
    />
  );
};

export default SellDialog;
