import { useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import createEndpointBase from "../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import {
  useNotificationDispatch,
  createErrorHandlingOptions,
} from "../context/notification-context";
import { useInteractionContext } from "../context/interaction-context";
import { Wallet } from "../model";

const endPointBase = createEndpointBase();

export type Account = {
  id: number;
  name: string;
  apiKey: string;
  secretKey: string;
  notificationsEnabled: boolean;
  loanRatio: number;
  loanRatioBtc: number;
  loanRepayRatio: number;
  exchange: string;
};

export const useAccounts = () => {
  const key = "/api/accounts";
  const { data, isLoading, error, refetch } = useQuery<Account[]>(key, () => {
    return endPointBase.load(key, endPointBase.createGetOptions());
  });

  return { data, isLoading, error, refetch };
};

export const useAddAccount = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    async (
      data: Omit<
        Account,
        "id" | "loanRatio" | "loanRatioBtc" | "loanRepayRatio"
      >
    ) => {
      return endPointBase.load(
        "/api/accounts",
        endPointBase.createPostOptions(data)
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useSetAccountNotifications = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    async (data: Pick<Account, "id" | "notificationsEnabled">) => {
      return endPointBase.load(
        "/api/accounts/notifications",
        endPointBase.createPostOptions(data)
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export type LoanRatio = {
  baseRatio: number;
  quoteRatio: number;
};

export const useSetAccountLoanRatio = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    async (wallet: Wallet, quoteRatio: number, baseRatio: number) => {
      return endPointBase.load(
        "/api/accounts/loan_ratio",
        endPointBase.createPostOptions({
          accountId,
          wallet,
          ticker,
          repayRatio: 1,
          baseRatio,
          quoteRatio,
        })
      );
    },
    {
      onSuccess: () => {
        notificationDispatch({
          type: "success",
          payload: "Значение максимального займа установлнено",
        });
        onSuccess();
      },
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useGetAccountLoanRatio = (wallet: Wallet) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  const query = `/api/accounts/loan_ratio/${ticker}/${wallet}`;
  return useQuery<LoanRatio>(
    [query, accountId],
    async () => {
      return endPointBase.load(
        `${query}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    },
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useGetAllowedWallets = () => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const query = `/api/accounts/${accountId}/allowed_wallets`;
  return useQuery<Wallet[]>(
    [query],
    async () => {
      return endPointBase.load(query, endPointBase.createGetOptions());
    },
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};
