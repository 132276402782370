import {
  createNumberColumnFormatter,
  formatDateColumn,
  formatNumber2,
} from "./utils/format";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import OrderDialog from "./components/order-dialog";
import { useInteractionContext } from "./context/interaction-context";
import renderDirectionCell from "./components/render-direction-cell";
import { Wallet } from "./model";
import { useOrders } from "./services/orders-api";
import { usePriceStream } from "./services/trade-api";
import Typography from "@mui/material/Typography";
import RenderOrderStatusCell from "./components/render-order-status-cell";
import { useState } from "react";
import Modal from "./components/modal";
import { OrderTradesTable } from "./order-trades-table";
import { usePriceFormatter } from "./binance/hooks/use-price-formatter";

const columns = [
  {
    field: "time",
    headerName: "Дата Время",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },
  {
    field: "price",
    headerName: "Цена",
    type: "number",
    width: 100,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "origQty",
    headerName: "Количество",
    type: "number",
    width: 150,
    renderCell: (params: any) => {
      return `${params.row.executedQty} / ${params.value}`;
    },
  },
  {
    field: "side",
    headerName: "Направление",
    type: "string",
    width: 125,
    renderCell: (params: any) =>
      renderDirectionCell({
        ...params,
        value: params.value === "BUY" ? 1 : -1,
      }),
  },
  {
    field: "status",
    headerName: "Статус",
    type: "string",
    width: 150,
    renderCell: RenderOrderStatusCell,
  },
  {
    field: "type",
    headerName: "Тип",
    type: "string",
    width: 150,
  },
];

type Props = {
  wallet: Wallet;
  q: number;
};

export const Orders = ({ wallet, q }: Props) => {
  const { from, to } = useInteractionContext();
  const dataResponse = useOrders(from, to);
  const currentPrice = usePriceStream();
  const data = dataResponse.data || [];
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const formatPrice = usePriceFormatter();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          getRowId={(x) => x.orderId}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          loading={dataResponse.isLoading}
          onRowClick={(row) => {
            setSelectedRowId(row.id.toString());
          }}
        />
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2 }}>
        <Typography variant="body1">
          Цена: {formatPrice(currentPrice)}
        </Typography>
        <Box sx={{ marginLeft: "auto" }}>
          <OrderDialog
            q={q}
            onClose={() => {
              dataResponse.refetch();
            }}
            wallet={wallet}
          />
        </Box>
      </Paper>
      {selectedRowId && (
        <Modal
          isOpen={selectedRowId !== null}
          onClose={() => setSelectedRowId(null)}
        >
          <OrderTradesTable
            onClose={() => setSelectedRowId(null)}
            orderId={selectedRowId}
          />
        </Modal>
      )}
    </Box>
  );
};

export default Orders;
