import { useAsync, useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import createEndpointBase from "../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../context/notification-context";
import { useInteractionContext } from "../context/interaction-context";

const endPointBase = createEndpointBase();

export type DataItem = {
  m: number;
  t: string;
  eb: number;
  eh: number;
  pok: number;
  pr: number;
  pu: number;
  pup: number;
  bb: number;
  ev: number;
  n: number;
  bv: number;
};

export type BtcData = {
  id: number;
  created_at: Date;
  price: number;
};

export const useSourceDate = (
  date: string,
  f: number,
  a: number,
  b: number
) => {
  return useAsync(
    async (
      date: string,
      f: number,
      a: number,
      b: number
    ): Promise<DataItem[] | null> => {
      if (!date) {
        return null;
      }

      return endPointBase.load(
        `/api/data?date=${date}&f=${f}&a=${a}&b=${b}`,
        endPointBase.createGetOptions()
      );
    },
    [date, f, a, b]
  );
};

export const usePagedSourceData = (
  date: string,
  endDate: string,
  page: number,
  pageSize: number
) => {
  const { ticker } = useInteractionContext();
  return useAsync(
    (
      date: string,
      endDate: string,
      page: number,
      pageSize: number
    ): Promise<{ data: BtcData[]; totalCount: number }> => {
      return endPointBase.load(
        `/api/source-data/${ticker}/paged?date=${date}&end=${endDate}&page=${page}&page_size=${pageSize}`,
        endPointBase.createGetOptions()
      );
    },
    [date, endDate, page, pageSize]
  );
};

export const useDataCallback = () => {
  return useAsyncCallback(
    async (
      date: string,
      end: string,
      f: number,
      a: number,
      b: number
    ): Promise<DataItem[] | null> => {
      if (!date) {
        return null;
      }

      return endPointBase.load(
        `/api/data?date=${date}&end=${end}&f=${f}&a=${a}&b=${b}`,
        endPointBase.createGetOptions()
      );
    }
  );
};

export const useData = (date: string) => {
  const { ticker } = useInteractionContext();
  return useAsync(
    async (date: string, ticker: string): Promise<BtcData[] | null> => {
      if (!date) {
        return null;
      }

      return endPointBase.load(
        `/api/source-data/${ticker}?date=${date}`,
        endPointBase.createGetOptions()
      );
    },
    [date, ticker]
  );
};

type Balance = {
  asset: string;
  free: number;
  locked: number;
};

export const useBalance = () => {
  const query = `/api/binance/balance`;
  const account = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  return useQuery<Balance[]>(
    [query, account],
    () =>
      endPointBase.load(
        `${query}?account_id=${account}`,
        endPointBase.createGetOptions()
      ),
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const useTotalBalance = () => {
  const query = `/api/binance/total_balance`;
  const account = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  return useQuery<number>(
    [query, account],
    () =>
      endPointBase.load(
        `${query}?account_id=${account}`,
        endPointBase.createGetOptions()
      ),
    createErrorHandlingOptions(notificationDispatch)
  );
};

export type LoanHistoryItem = {
  amount: number;
  asset: string;
  timestamp: number;
  tranId: string;
  type: string;
};

export const useLoanHistory = (asset: string) => {
  const query = `/api/binance/loan-history`;
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  return useQuery<LoanHistoryItem[]>(
    [asset, accountId, query],
    () => {
      return endPointBase.load(
        `${query}?account_id=${accountId}&asset=${asset}`,
        endPointBase.createGetOptions()
      );
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export type BalanceHistory = {
  id: number;
  createAt: Date;
  balance: number;
};

export const useBalanceHistory = (date: string, endDate: string) => {
  const query = `/api/binance/balance-history`;
  const account = useAccountContext();
  return useQuery<Balance[]>([query, date, endDate, account], () =>
    endPointBase.load(
      `${query}?date=${date}&end=${endDate}&account_id=${account}`,
      endPointBase.createGetOptions()
    )
  );
};

export type OHLC = {
  o: number;
  h: number;
  l: number;
  c: number;
  createdAt: Date;
  ticks: number;
};

export const useOHLC = (date: string, endDate: string, interval: string) => {
  const { ticker } = useInteractionContext();
  const account_id = useAccountContext();
  return useAsync(
    (
      date: string,
      endDate: string,
      interval: string,
      ticker: string,
      account_id: number
    ): Promise<OHLC[]> => {
      const params = encodeURIComponent(
        `date=${date}&end=${endDate}&interval=${interval}&account_id=${account_id}`
      );
      return endPointBase.load(
        `/api/ohlc/${ticker}?${params}`,
        endPointBase.createGetOptions()
      );
    },
    [date, endDate, interval, ticker, account_id]
  );
};

export const useTickers = () => {
  const query = `/api/binance/tickers`;
  return useQuery<string[]>(query, () =>
    endPointBase.load(query, endPointBase.createGetOptions())
  );
};

export const useAddTicker = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    async (data: { base: string; quote: string }) => {
      return endPointBase.load(
        `/api/binance/tickers`,
        endPointBase.createPostOptions(data)
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export type TickerInfo = {
  stepSize: number;
  minQty: number;
  minNotional: number;
  baseAsset: string;
  quoteAsset: string;
  tickSize: number;
};

export const useTickerInfo = (ticker: string) => {
  const accountId = useAccountContext();
  const query = `/api/binance/tickers/${ticker}/info?account_id=${accountId}`;
  const [baseAsset, quoteAsset] = ticker.split("_");
  const notificationDispatch = useNotificationDispatch();
  return useQuery<TickerInfo>(
    query,
    async () => {
      const data = await endPointBase.load(
        query,
        endPointBase.createGetOptions()
      );
      return { ...data, baseAsset, quoteAsset };
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export type DepositAddress = {
  address: string;
  coin: string;
  tag: string;
  url: string;
};

export const useDepositAddress = (coin: string, network: string) => {
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  const query = `/api/binance/deposit_address/${coin}/${network}?account_id=${accountId}`;
  return useQuery<DepositAddress>(
    query,
    () => {
      if (network === "") {
        return {
          address: "",
          coin,
          tag: "",
          url: "",
        };
      }
      return endPointBase.load(query, endPointBase.createGetOptions());
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export interface Network {
  addressRegex: string;
  addressRule: string;
  busy: boolean;
  coin: string;
  contractAddress: string;
  contractAddressUrl: string;
  depositDesc: string;
  depositDust: string;
  depositEnable: boolean;
  estimatedArrivalTime: number;
  isDefault: boolean;
  minConfirm: number;
  name: string;
  network: string;
  resetAddressStatus: string;
  sameAddress: string;
  specialTips: string;
  specialWithdrawTips: string;
  unLockConfirm: number;
  withdrawDesc: string;
  withdrawEnable: boolean;
  withdrawFee: string;
  withdrawIntegerMultiple: string;
  withdrawMax: string;
  withdrawMin: string;
}

export const useNetworkList = (coin: string) => {
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  const query = `/api/binance/network_list/${coin}?account_id=${accountId}`;
  return useQuery<Network[]>(
    query,
    () => {
      return endPointBase.load(query, endPointBase.createGetOptions());
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export interface CoinInfo {
  coin: string;
}

export const useAssetList = () => {
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  const query = `/api/binance/coin_list/?account_id=${accountId}`;
  return useQuery<CoinInfo[]>(
    query,
    () => {
      return endPointBase.load(query, endPointBase.createGetOptions());
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};
