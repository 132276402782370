import { useAsyncCallback } from "react-async-hook";
import { useQuery, useQueryClient } from "react-query";
import createEndpointBase from "../api/utils/endpoint-base";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../context/notification-context";

const QUERY_KEY = "/auth";

const endPointBase = createEndpointBase();

export const useAuth = () => {
  const { data, isLoading, error } = useQuery(QUERY_KEY, () => {
    return endPointBase.load(
      "/auth/current-user",
      endPointBase.createGetOptions()
    );
  });

  return { data, isLoading, error };
};

export const useAuthenticate = () => {
  const queryClient = useQueryClient();

  const notificationDispatch = useNotificationDispatch();

  return useAsyncCallback(
    async (params: { login: string; password: string }) => {
      const currentUser = await endPointBase.load(
        "/auth/login",
        endPointBase.createPostOptions(params)
      );

      queryClient.setQueryData(QUERY_KEY, () => currentUser);
      queryClient.invalidateQueries();
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const useCreateAccount = () => {
  const queryClient = useQueryClient();

  const notificationDispatch = useNotificationDispatch();

  return useAsyncCallback(
    async (params: { name: string; login: string; password: string }) => {
      const currentUser = await endPointBase.load(
        "/auth/register",
        endPointBase.createPostOptions(params)
      );

      queryClient.setQueryData(QUERY_KEY, () => currentUser);
      queryClient.invalidateQueries();
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const notificationDispatch = useNotificationDispatch();

  return useAsyncCallback(async () => {
    const currentUser = await endPointBase.load(
      "/auth/logout",
      endPointBase.createGetOptions()
    );

    queryClient.setQueryData(QUERY_KEY, () => currentUser);
    queryClient.invalidateQueries();
  }, createErrorHandlingOptions(notificationDispatch));
};

export type Permission = "read" | "write" | "admin" | "bcs";

export const useCan = (permission: Permission) => {
  const key = "/auth/can";
  return useQuery<boolean>([key, permission], () => {
    return endPointBase.load(
      `${key}?permission=${permission}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useTwoFactorAuth = (login: string) => {
  const notificationDispatch = useNotificationDispatch();
  const query = `/auth/two-factor-enabled/${login}`;
  const { data, isLoading, error } = useQuery(
    query,
    () => {
      return endPointBase.load(query, endPointBase.createGetOptions());
    },
    createErrorHandlingOptions(notificationDispatch)
  );

  return { data, isLoading, error };
};

export const useTwoFactorAuthCallback = () => {
  const notificationDispatch = useNotificationDispatch();

  return useAsyncCallback(
    async (login: string): Promise<boolean> =>
      await endPointBase.load(
        `/auth/two-factor-enabled/${login}`,
        endPointBase.createGetOptions()
      ),
    createErrorHandlingOptions(notificationDispatch)
  );
};
