import { useBorrow, useMaxBorrowable } from "../../services/margin";
import BaseBorrowDialog from "./base-borrow-dialog";

type Props = {
  onClose: () => void;
  asset: string;
};

const MarginBorrowDialog = ({ onClose, asset }: Props) => {
  const borrow = useBorrow(onClose);
  const maxValueResponse = useMaxBorrowable(asset);

  return (
    <BaseBorrowDialog
      onClose={onClose}
      asset={asset}
      borrow={borrow.execute}
      isLoading={borrow.loading || maxValueResponse.loading}
      maxBorrowable={maxValueResponse.result || 0}
    />
  );
};

export default MarginBorrowDialog;
