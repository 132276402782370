import Can from "../../components/auth/can";
import Layout from "../../Layout";
import { AccountContextProvider } from "../context/account-context";
import { Board, BoardContextProvider } from "../context/board-context";
import Navigation from "./navigation";
import { Outlet } from "react-router-dom";

const BcsApp = () => {
  return (
    <Can perform="bcs">
      <AccountContextProvider initValue="Si" storeRecord="bcsFuturesAccount">
        <BoardContextProvider board={Board.SPBFUT}>
          <Layout navigation={<Navigation />}>
            {/* <BcsTradeApp /> */}
            <Outlet />
          </Layout>
        </BoardContextProvider>
      </AccountContextProvider>
    </Can>
  );
};

export default BcsApp;
