import { useQuery, useQueryClient } from "react-query";
import createEndpointBase from "../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import { useInteractionContext } from "../context/interaction-context";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../context/notification-context";
import { useAsyncCallback } from "react-async-hook";

export interface Order {
  symbol: string;
  orderId: number;
  orderListId: number; //Unless OCO, the value will always be -1
  clientOrderId: string;
  price: string;
  origQty: string;
  executedQty: string;
  cummulativeQuoteQty: string;
  status: "NEW" | "FILLED" | "CANCELED";
  timeInForce: "GTC";
  type: "LIMIT" | "MARKET";
  side: "BUY" | "SELL";
  stopPrice: string;
  icebergQty: string;
  time: number;
  updateTime: number;
  isWorking: true;
  origQuoteOrderQty: string;
  workingTime: number;
  selfTradePreventionMode: "NONE";
}

export interface Trade {
  symbol: string;
  id: number;
  orderId: number | string;
  price: string;
  qty: string;
  quoteQty: string;
  commission: string;
  commissionAsset: string;
  time: number;
  isBuyer: boolean;
  isMaker: boolean;
  isBestMatch: true;
  isIsolated: false;
}

const endPointBase = createEndpointBase();

export const useOrders = (date: string, endDate: string) => {
  const accountId = useAccountContext();
  const { ticker, wallet } = useInteractionContext();
  const notificationDispatch = useNotificationDispatch();
  const options = createErrorHandlingOptions(notificationDispatch);
  const key = `/api/orders/${wallet}/${ticker}?date=${date}&end=${endDate}&account_id=${accountId}`;
  return useQuery<Order[]>(
    key,
    async () => {
      return endPointBase.load(key, endPointBase.createGetOptions());
    },
    options
  );
};

export const useOrderTrades = (orderId: string) => {
  const accountId = useAccountContext();
  const { ticker, wallet } = useInteractionContext();
  const notificationDispatch = useNotificationDispatch();
  const options = createErrorHandlingOptions(notificationDispatch);
  const key = `/api/orders/${wallet}/${ticker}/${orderId}/trades?account_id=${accountId}`;
  return useQuery<Trade[]>(
    key,
    async () => {
      return endPointBase.load(key, endPointBase.createGetOptions());
    },
    options
  );
};

export const useCancelOrder = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker, wallet } = useInteractionContext();
  const client = useQueryClient();

  return useAsyncCallback(
    async (orderNumber: number) => {
      const key = `/api/orders/${orderNumber}/${wallet}/${ticker}?account_id=${accountId}`;
      return endPointBase.load(key, endPointBase.createDeleteOptions());
    },
    {
      onSuccess: () => {
        notificationDispatch({ type: "success", payload: "Заявка отменена" });
        setTimeout(() => {
          client.refetchQueries({
            predicate: (q) => {
              return q.queryKey.includes(`/api/orders/${wallet}/${ticker}`);
            },
          });
        }, 1000);
        onSuccess();
      },
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useCancelUnknownWalletOrder = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker, wallet } = useInteractionContext();
  const client = useQueryClient();

  return useAsyncCallback(
    async (orderNumber: number) => {
      const key = `/api/orders/${orderNumber}/${ticker}?account_id=${accountId}`;
      return endPointBase.load(key, endPointBase.createDeleteOptions());
    },
    {
      onSuccess: () => {
        notificationDispatch({ type: "success", payload: "Заявка отменена" });
        setTimeout(() => {
          client.refetchQueries({
            predicate: (q) => {
              return q.queryKey.includes(`/api/orders/${wallet}/${ticker}`);
            },
          });
        }, 1000);
        onSuccess();
      },
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};
