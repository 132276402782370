import LoginPage from "../../pages/login-page";
import { useAuth } from "../../services/auth";

const withAuthenticator = (Component: React.FC) => {
  const App: React.FC = (props) => {
    const currentUser = useAuth();

    if (currentUser.isLoading) {
      return null;
    }

    if (!currentUser.isLoading && !currentUser.data) {
      return <LoginPage />;
    }
    return <Component {...props} />;
  };

  return App;
};

export default withAuthenticator;
