import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import AccountSelector from "./account-selector";

const Navigation = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 4,
        marginBottom: 2,
        alignItems: "center",
      }}
    >
      <Link component={NavLink} underline="hover" to={`/bcs/trading/`}>
        {"<"} Акции
      </Link>

      <Link component={NavLink} underline="hover" to={`/bcs/futures/trading/`}>
        Торговля
      </Link>

      <Box
        sx={{
          marginLeft: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <AccountSelector />
      </Box>
    </Box>
  );
};

export default Navigation;
