import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Algorithm2Params, { useAlgorithm2Params } from "./algorithm2-params";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SourceTable from "./source-table";
import { useAlgorithm2DataCallback } from "./services/algorithm2";
import Algorithm2AnalysisTable from "./algorithm2-analysis-table";
import Chart from "./components/chart";
import DateRangeSelector from "./components/date-range-selector";
import { useInteractionContext } from "./context/interaction-context";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Algorithm2Analysis = () => {
  const { from, to } = useInteractionContext();
  const [tabValue, setTabValue] = useState(0);

  const params = useAlgorithm2Params();
  const response = useAlgorithm2DataCallback();
  const { ticker } = useInteractionContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: 1, marginBottom: 2 }}
      >
        <DateRangeSelector />
        <Algorithm2Params {...params} isAnalysis />
        <Button
          disabled={response.loading}
          onClick={() => {
            response.execute(
              from,
              to,
              Number(params.f),
              Number(params.pok),
              Number(params.pr),
              ticker
            );
          }}
        >
          Получить данные
        </Button>
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="trading tabs"
        >
          <Tab label="Результат" {...a11yProps(0)} />
          <Tab label="Исходные данные" {...a11yProps(1)} />
          <Tab label="График" {...a11yProps(2)} />
        </Tabs>
      </Box>

      {tabValue === 0 && (
        <Algorithm2AnalysisTable
          data={response.result?.table || []}
          total={response.result?.total}
          loading={response.loading}
        />
      )}
      {tabValue === 1 && <SourceTable date={from} endDate={to} />}
      {tabValue === 2 && <Chart date={from} endDate={to} />}
    </Box>
  );
};

export default Algorithm2Analysis;
