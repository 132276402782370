import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export type Params = {
  a: number;
  f: number;
  b: number;
};

export const useAlgorithm1Params = (params?: Params | null) => {
  const [f, setF] = useState("0.06");
  const [a, setA] = useState("1.01");
  const [b, setB] = useState("1.0012");

  useEffect(() => {
    if (params) {
      setB(params.b.toString());
      setA(params.a.toString());
      setF(params.f.toString());
    }
  }, [params]);

  return {
    f,
    a,
    b,
    onAChange: setA,
    onBChange: setB,
    onFChange: setF,
  };
};

type Props = {
  a: string;
  f: string;
  b: string;
  onAChange: (value: string) => void;
  onBChange: (value: string) => void;
  onFChange: (value: string) => void;
  disabled?: boolean;
};

const Algorithm1Params = (props: Props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      <TextField
        disabled={props.disabled}
        label={`F`}
        type={"number"}
        value={props.f}
        onChange={(e) => props.onFChange(e.target.value)}
        inputProps={{ step: "0.01" }}
      />
      <TextField
        disabled={props.disabled}
        label="a"
        type={"number"}
        value={props.a}
        onChange={(e) => props.onAChange(e.target.value)}
        inputProps={{ step: "0.01" }}
      />
      <TextField
        disabled={props.disabled}
        label="b"
        type={"number"}
        value={props.b}
        onChange={(e) => props.onBChange(e.target.value)}
        inputProps={{ step: "0.001" }}
      />
    </Box>
  );
};

export default Algorithm1Params;
