import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WalletSelector from "./components/wallet-selector";
import { usePrice } from "./services/trade-api";
import { formatNumber2 } from "./utils/format";
import {
  useInteractionContext,
  useInteractionDispatch,
} from "./context/interaction-context";
import { Wallet } from "./model";
import OrderTypeSelector, { OrderType } from "./components/order-type-selector";

export type Params = {
  f: number;
  pok: number;
  pr: number;
  q: number;
  wallet: Wallet;
  orderType: OrderType;
};

export const useAlgorithm2Params = (params?: Params | null) => {
  const [f, setF] = useState("200");
  const [pok, setPok] = useState("0");
  const [pr, setPr] = useState("0");
  const [q, setQ] = useState("0.001");
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Market);

  const { wallet } = useInteractionContext();
  const dispatch = useInteractionDispatch();

  const setWallet = useCallback(
    (wallet: Wallet) => {
      dispatch({
        type: "SET_WALLET",
        payload: wallet,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (params) {
      setF(params.f.toString());
      setPok(params.pok.toString());
      setPr(params.pr.toString());
      setQ(params.q.toString());
      setWallet(params.wallet);
      setOrderType(params.orderType);
    }
  }, [params, setWallet]);

  return {
    f,
    pok,
    pr,
    q,
    wallet,
    orderType,
    onOrderTypeChange: setOrderType,
    onFChange: setF,
    onPokChange: setPok,
    onPrChange: setPr,
    onQChange: setQ,
    onWalletChange: setWallet,
  };
};

type Props = {
  f: string;
  pok: string;
  pr: string;
  wallet: Wallet;
  q: string;
  orderType: OrderType;
  onOrderTypeChange: (value: OrderType) => void;
  onQChange: (value: string) => void;
  onFChange: (value: string) => void;
  onPokChange: (value: string) => void;
  onPrChange: (value: string) => void;
  onWalletChange: (value: Wallet) => void;
  disabled?: boolean;
  isAnalysis?: boolean;
  displayWallet?: boolean;
};

const Algorithm2Params = (props: Props) => {
  const price = usePrice().result?.price || 0;
  const { ticker } = useInteractionContext();
  const quoteSymbol = ticker.split("_")[1];
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      <TextField
        disabled={props.disabled}
        label={`F (≈${formatNumber2((Number(props.f) / price) * 100)}  %)`}
        type={"number"}
        value={props.f}
        onChange={(e) => props.onFChange(e.target.value)}
        inputProps={{ step: "0.01" }}
      />
      <Box sx={{ display: "none" }}>
        <TextField
          disabled={props.disabled}
          label="pok"
          type={"number"}
          value={props.pok}
          onChange={(e) => props.onPokChange(e.target.value)}
          inputProps={{ step: "1" }}
        />
        <TextField
          disabled={props.disabled}
          label="pr"
          type={"number"}
          value={props.pr}
          onChange={(e) => props.onPrChange(e.target.value)}
          inputProps={{ step: "1" }}
        />
      </Box>
      <TextField
        disabled={props.disabled}
        label={`q (≈${formatNumber2(price * Number(props.q))}  ${quoteSymbol})`}
        type={"number"}
        value={props.q}
        onChange={(e) => props.onQChange(e.target.value)}
        inputProps={{ step: "0.001" }}
        style={props.isAnalysis ? { display: "none" } : undefined}
      />
      <OrderTypeSelector
        disabled={props.disabled}
        orderType={props.orderType}
        onOrderTypeChange={props.onOrderTypeChange}
      />
      {props.displayWallet !== false && (
        <WalletSelector
          disabled={props.disabled}
          wallet={props.wallet}
          onWalletChange={props.onWalletChange}
        />
      )}
    </Box>
  );
};

export default Algorithm2Params;
