import { useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import createEndpointBase from "../../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import { useInteractionContext } from "../../context/interaction-context";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../../context/notification-context";
const endPointBase = createEndpointBase();

export type BalanceHistory = {
  created_at: Date;
  balance: number;
  benchmark: number;
};

export type PortfolioHistory = {
  createdAt: Date;
  amount: number;
  asset: string;
};

export const useBalanceHistory = (date: string, endDate: string) => {
  const key = "/api/bcs/balance/history";
  return useQuery<BalanceHistory[]>([key, date, endDate], async () => {
    return endPointBase.load(
      `${key}?date=${date}&end=${endDate}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useDeleteBalanceHistory = (onSuccess: () => void) => {
  const key = "/api/bcs/balance/history";
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    (date: string, endDate: string) => {
      return endPointBase.load(
        `${key}?date=${date}&end=${endDate}`,
        endPointBase.createDeleteOptions()
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const usePortfolioHistory = (date: string, endDate: string) => {
  const key = "/api/bcs/balance/portfolio-history";
  return useQuery<BalanceHistory[]>([key, date, endDate], async () => {
    return endPointBase.load(
      `${key}?date=${date}&end=${endDate}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useDeletePortfolioHistory = (onSuccess: () => void) => {
  const key = "/api/bcs/balance/portfolio-history";
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    (date: string, endDate: string) => {
      return endPointBase.load(
        `${key}?date=${date}&end=${endDate}`,
        endPointBase.createDeleteOptions()
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useAssetHistory = () => {
  const key = "/api/bcs/balance/portfolio-history";
  const asset = useAccountContext();
  const { from, to } = useInteractionContext();
  return useQuery<PortfolioHistory[]>([asset, from, to], async () => {
    return endPointBase.load(
      `${key}/${asset}?from=${from}&to=${to}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useBalanceSnapshot = () => {
  return useAsyncCallback(async () => {
    return endPointBase.load(
      `/api/bcs/balance/snapshot`,
      endPointBase.createGetOptions()
    );
  });
};

export const useAddBenchmarkValue = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    async (value: number, asset: string) => {
      return endPointBase.load(
        `/api/bcs/balance/add_benchmark/${asset}?value=${value}`,
        endPointBase.createGetOptions()
      );
    },
    {
      onSuccess: () => {
        notificationDispatch({
          type: "success",
          payload: "Знвчение обновлено",
        });
        onSuccess();
      },
      onError: (e) => {
        notificationDispatch({
          type: "error",
          payload: e.message,
        });
      },
    }
  );
};
