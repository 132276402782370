import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  useGetAccountLoanRatio,
  useSetAccountLoanRatio,
} from "../services/accounts";
import { usePrice } from "../services/trade-api";
import { Wallet } from "../model";
import { useInteractionContext } from "../context/interaction-context";

type Props = {
  totalBalance: number;
  multiplier: number;
  wallet: Wallet;
};

const AutoLoansRatio = ({ totalBalance, multiplier, wallet }: Props) => {
  const [quoteRatio, setQuoteRatio] = useState(1.5);
  const [baseRatio, setBaseRatio] = useState(1.5);
  const maxLoan = (totalBalance * multiplier) / quoteRatio;
  const maxLoanBtc = (totalBalance * multiplier) / baseRatio;
  const price = usePrice().result;
  const currentRatio = useGetAccountLoanRatio(wallet);
  const setCurrentRatio = useSetAccountLoanRatio(() => {
    currentRatio.refetch();
  });
  const { ticker } = useInteractionContext();

  const [baseCurrency, quoteCurrency] = ticker.split("_");

  useEffect(() => {
    if (currentRatio.data?.quoteRatio) {
      setQuoteRatio(currentRatio.data.quoteRatio);
    }
    if (currentRatio.data?.baseRatio) {
      setBaseRatio(currentRatio.data.baseRatio);
    }
  }, [currentRatio.data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        marginBottom: 1,
        color: (theme) => theme.palette.primary[theme.palette.mode],
      }}
    >
      <Typography variant="body1">Автозаймы</Typography>

      <Box sx={{ width: 200 }}>
        <Slider
          size="small"
          value={quoteRatio}
          min={1}
          max={20}
          step={0.01}
          aria-label="ratio"
          onChange={(e, value) =>
            typeof value === "number" &&
            setQuoteRatio(value === 20 ? 100000 : value)
          }
          valueLabelDisplay="off"
        />
      </Box>

      <TextField
        disabled
        size="small"
        label={`Максимальный займ (${quoteCurrency})`}
        type="number"
        value={maxLoan.toFixed(2)}
      />

      <Box sx={{ width: 200 }}>
        <Slider
          size="small"
          value={baseRatio}
          min={1}
          max={20}
          step={0.01}
          aria-label="ratio-btc"
          onChange={(e, value) =>
            typeof value === "number" &&
            setBaseRatio(value === 20 ? 100000 : value)
          }
          valueLabelDisplay="off"
        />
      </Box>

      <TextField
        disabled
        size="small"
        label={`Максимальный займ (${baseCurrency})`}
        type="number"
        value={(maxLoanBtc / (price?.price || 1)).toFixed(5)}
      />

      <Button
        disabled={
          (quoteRatio === currentRatio.data?.quoteRatio &&
            baseRatio === currentRatio.data?.baseRatio) ||
          currentRatio.isLoading ||
          setCurrentRatio.loading ||
          totalBalance === 0
        }
        onClick={() => {
          setCurrentRatio.execute(wallet, quoteRatio, baseRatio);
        }}
      >
        Установить
      </Button>
    </Box>
  );
};

export default AutoLoansRatio;
