import {
  createNumberColumnFormatter,
  formatNumber0,
  formatNumber2,
} from "../../utils/format";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";

import { PortfolioLimit, usePortfolioLimits } from "../services/asset";
import renderAssetCell from "./render-asset-cell";
import RenderMarginCell from "./render-margin-cell";
import { useNavigate } from "react-router-dom";
import { useAccountDispatch } from "../context/account-context";

const columns = [
  {
    field: "asset",
    headerName: "Код",
    width: 150,
    type: "string",
    renderCell: renderAssetCell,
  },
  {
    field: "balance",
    headerName: "Кол-во",
    width: 170,
    type: "number",
    valueGetter: (data: { row: PortfolioLimit }) => {
      return data.row.free + data.row.locked;
    },
    valueFormatter: createNumberColumnFormatter(formatNumber0),
  },
  {
    field: "volume",
    headerName: "Объем",
    width: 170,
    type: "number",
    valueGetter: (data: { row: PortfolioLimit }) => {
      return (
        (data.row.free + data.row.locked) *
        (data.row.price || data.row.avgPrice)
      );
    },
    valueFormatter: createNumberColumnFormatter(formatNumber0),
  },
  {
    field: "avgPrice",
    headerName: "Цена покупки",
    width: 170,
    type: "number",
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "price",
    headerName: "Цена",
    width: 170,
    type: "number",
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "varMargin",
    headerName: "Вар.Маржа",
    type: "number",
    width: 170,
    renderCell: RenderMarginCell,
    valueGetter: (params: { row: PortfolioLimit }) => {
      const {
        avgPrice,
        free,
        locked,
        price: assetPrice,
      }: PortfolioLimit = params.row;
      const price = assetPrice || 0;

      return (free + locked) * (price - avgPrice);
    },
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
  {
    field: "margin",
    headerName: "Прибыль",
    type: "number",
    width: 170,
    renderCell: RenderMarginCell,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
];

export const AssetAnalysisTable = () => {
  const dataResponse = usePortfolioLimits();
  const navigate = useNavigate();
  const setAccount = useAccountDispatch();
  const data = dataResponse.data || [];
  const varMargin = data.reduce((sum, d) => {
    return sum + (d.free + d.locked) * ((d.price || d.avgPrice) - d.avgPrice);
  }, 0);

  const margin = data.reduce((sum, d) => {
    return sum + (d.margin || 0);
  }, 0);

  const volume = data.reduce((sum, d) => {
    return sum + (d.free + d.locked) * (d.price || d.avgPrice);
  }, 0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          getRowId={(x) => x.asset}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          loading={dataResponse.isLoading}
          onRowClick={(e: GridRowParams<PortfolioLimit>) => {
            setAccount(e.row.asset);
            navigate("/bcs/trading");
          }}
        />
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2 }}>
        <Typography variant="body1">
          Вар. маржа: {formatNumber0(varMargin)}
        </Typography>
        <Typography variant="body1">
          {margin >= 0 ? "Прибыль" : "Убытки"}: {formatNumber0(margin)}
        </Typography>
        <Typography variant="body1">Объем: {formatNumber0(volume)}</Typography>
      </Paper>
    </Box>
  );
};

export default AssetAnalysisTable;
