import React from "react";
import { Wallet } from "../model";
import { getAppSettings, setAppSettings } from "../storage";
import { formatISO } from "date-fns";

type State = {
  from: string;
  to: string;
  ticker: string;
  wallet: Wallet;
  allowedWallets: Wallet[];
};

const INITIAL_STATE: State = {
  from: formatISO(new Date(), { representation: "date" }),
  to: formatISO(new Date(), { representation: "date" }),
  ticker: "BTC_USDT",
  wallet: Wallet.MARGIN,
  allowedWallets: [Wallet.SPOT, Wallet.MARGIN, Wallet.ISOLATED],
};
const InteractionContext = React.createContext(INITIAL_STATE);

type Action =
  | {
      type: "SET_FROM_VALUE";
      payload: string;
    }
  | {
      type: "SET_TO_VALUE";
      payload: string;
    }
  | {
      type: "SET_TICKER";
      payload: string;
    }
  | {
      type: "SET_WALLET";
      payload: Wallet;
    }
  | {
      type: "SET_ALLOWED_WALLETS";
      payload: Wallet[];
    };

function interactionStateReducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_FROM_VALUE": {
      return {
        ...state,
        from: action.payload,
      };
    }

    case "SET_TO_VALUE": {
      return {
        ...state,
        to: action.payload,
      };
    }

    case "SET_TICKER": {
      return {
        ...state,
        ticker: action.payload,
      };
    }

    case "SET_WALLET": {
      return {
        ...state,
        wallet: action.payload,
      };
    }

    case "SET_ALLOWED_WALLETS": {
      const allowedWallets = action.payload;
      const wallet = allowedWallets.includes(state.wallet)
        ? state.wallet
        : allowedWallets[0];
      return {
        ...state,
        wallet,
        allowedWallets,
      };
    }

    default: {
      return state;
    }
  }
}

const InteractionDispatchContext = React.createContext<React.Dispatch<Action>>(
  () => {}
);

export const InteractionContextProvider: React.FC = ({ children }) => {
  const appSettings = getAppSettings();
  const [state, dispatch] = React.useReducer(interactionStateReducer, {
    ...INITIAL_STATE,
    ticker: appSettings.ticker || INITIAL_STATE.ticker,
  });

  setAppSettings({
    ticker: state.ticker,
  });
  return (
    <InteractionContext.Provider value={state}>
      <InteractionDispatchContext.Provider value={dispatch}>
        {children}
      </InteractionDispatchContext.Provider>
    </InteractionContext.Provider>
  );
};

export function useInteractionContext() {
  return React.useContext(InteractionContext);
}

export function useInteractionDispatch() {
  return React.useContext(InteractionDispatchContext);
}
