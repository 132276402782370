import { useBalance } from "../../services/api";
import BaseLoanDialog from "./base-loan-form";

type Props = {
  onClose: () => void;
  asset: string;
  recharge: (asset: string, amount: number) => Promise<any>;
  isLoading: boolean;
};

const BaseRechargeDialog = ({ onClose, asset, recharge, isLoading }: Props) => {
  const balanceResponse = useBalance();
  const maxValue = balanceResponse.data
    ? balanceResponse.data.find((b) => b.asset === asset)?.free || 0
    : 0;
  return (
    <BaseLoanDialog
      title={`Пополнить ${asset}`}
      onSubmit={(amount) => {
        recharge(asset, amount);
      }}
      maxValue={maxValue}
      isLoading={isLoading || balanceResponse.isLoading}
      onClose={onClose}
    />
  );
};

export default BaseRechargeDialog;
