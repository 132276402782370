import { useAsync, useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import createEndpointBase from "../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../context/notification-context";
import { useInteractionContext } from "../context/interaction-context";

const endPointBase = createEndpointBase();

export type IsolatedBalance = {
  asset: string;
  free: number;
  locked: number;
  borrowed: number;
  interest: number;
  netAsset: number;
  liquidatePrice: number;
};

export const useIsolatedBalance = () => {
  const query = `/api/isolated-margin/balance`;
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useQuery<IsolatedBalance[]>([accountId, query, ticker], () => {
    return endPointBase.load(
      `${query}/${ticker}?account_id=${accountId}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useIsolatedTotalBalance = () => {
  const query = `/api/isolated-margin/total-balance`;
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  const notificationDispatch = useNotificationDispatch();
  return useQuery<number>(
    [accountId, query, ticker],
    () => {
      return endPointBase.load(
        `${query}/${ticker}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const useIsolatedMarginEnable = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    () => {
      return endPointBase.load(
        `/api/isolated-margin/enable/${ticker}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    },
    { ...createErrorHandlingOptions(notificationDispatch), onSuccess }
  );
};

export const useIsolatedBorrow = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();

  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    (asset: string, amount: number) => {
      return endPointBase.load(
        `/api/isolated-margin/borrow/${ticker}?account_id=${accountId}`,
        endPointBase.createPostOptions({
          asset,
          amount,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useIsolatedMaxBorrowable = (asset: string) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsync(
    async (asset: string, accountId: number) => {
      const result: number = await endPointBase.load(
        `/api/isolated-margin/max-borrowable/${ticker}/${asset}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );

      return result;
    },
    [asset, accountId],
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useIsolatedRecharge = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    (asset: string, amount: number) => {
      return endPointBase.load(
        `/api/isolated-margin/recharge/${ticker}?account_id=${accountId}`,
        endPointBase.createPostOptions({
          asset,
          amount,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useIsolatedRepay = (onSuccess: () => void) => {
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    (asset: string, amount: number) => {
      return endPointBase.load(
        `/api/isolated-margin/repay/${ticker}?account_id=${accountId}`,
        endPointBase.createPostOptions({
          asset,
          amount,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useIsolatedMaxRepayable = (asset: string) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();

  return useAsync(
    async (asset: string, accountId: number, ticker: string) => {
      const result: number = await endPointBase.load(
        `/api/isolated-margin/max-repayable/${ticker}/${asset}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );

      return result;
    },
    [asset, accountId, ticker],
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useIsolatedWithdraw = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();

  return useAsyncCallback(
    (asset: string, amount: number) => {
      return endPointBase.load(
        `/api/isolated-margin/withdraw/${ticker}?account_id=${accountId}`,
        endPointBase.createPostOptions({
          asset,
          amount,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useIsolatedMaxTransferable = (asset: string) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();

  return useAsync(
    async (asset: string, accountId: number) => {
      const result: number = await endPointBase.load(
        `/api/isolated-margin/max-transferable/${ticker}/${asset}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );

      return result;
    },
    [asset, accountId],
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};
