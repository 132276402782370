import { useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import createEndpointBase from "../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../context/notification-context";
import { useInteractionContext } from "../context/interaction-context";
import { Wallet } from "../model";
import { Balance } from "../bcs/services/client";

const endPointBase = createEndpointBase();

export const useBuy = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    (wallet: Wallet, price: number, amount: number, orderType: string) => {
      return endPointBase.load(
        `/api/binance/buy/${ticker}?account_id=${accountId}`,
        endPointBase.createPostOptions({
          wallet,
          amount,
          price,
          orderType,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useSell = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    (wallet: Wallet, price: number, amount: number, orderType: string) => {
      return endPointBase.load(
        `/api/binance/sell/${ticker}?account_id=${accountId}`,
        endPointBase.createPostOptions({
          wallet,
          amount,
          price,
          orderType,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export type BinanceLimit = {
  createdAt: Date;
  sapiUsed1M: number | null;
  mbxUsed: number | null;
  mbxUsed1M: number | null;
  query: string;
  accountId: number;
};

export const useApiLimitsData = (date: string, endDate: string) => {
  const key = "/api/binance/limits";
  const accountId = useAccountContext();
  return useQuery<BinanceLimit[]>([key, date, endDate, accountId], async () => {
    return endPointBase.load(
      `${key}?date=${date}&end=${endDate}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useWalletBalance = (wallet: Wallet) => {
  const { ticker } = useInteractionContext();
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  const query = `/api/binance/balance/${wallet}/${ticker}?account_id=${accountId}`;
  return useQuery<Balance[]>(
    query,
    () => {
      return endPointBase.load(query, endPointBase.createGetOptions());
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};
