import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Wallet } from "../model";
import { useInteractionContext } from "../context/interaction-context";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
    },
  },
};

type Props = {
  disabled?: boolean;
  wallet: Wallet;
  allowedWallets?: Wallet[];
  onWalletChange: (wallet: Wallet) => void;
};

export const WALLETS = [Wallet.SPOT, Wallet.MARGIN, Wallet.ISOLATED];

export const formatWallet = (wallet: number) => {
  switch (wallet) {
    case Wallet.MARGIN: {
      return "Маржинальный";
    }
    case Wallet.SPOT: {
      return "Спотовый";
    }
    case Wallet.ISOLATED: {
      return "Изоллированный";
    }
    default: {
      return "Не задано";
    }
  }
};

const WalletSelector = ({
  disabled,
  wallet,
  onWalletChange,
  ...props
}: Props) => {
  const { allowedWallets } = useInteractionContext();
  return (
    <FormControl
      sx={{
        minWidth: 170,
      }}
    >
      <InputLabel focused variant="outlined">
        Кошелек
      </InputLabel>
      <Select
        disabled={disabled}
        labelId="Wallet"
        value={wallet}
        onChange={({ target: { value } }) => {
          if (typeof value === "string") {
            return;
          }
          onWalletChange(value);
        }}
        input={<OutlinedInput label="Кошелек" />}
        renderValue={formatWallet}
        MenuProps={MenuProps}
      >
        {(props.allowedWallets || allowedWallets).map((w) => (
          <MenuItem key={w} value={w}>
            <ListItemText primary={formatWallet(Number(w))} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default WalletSelector;
