import { useAsync, useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import createEndpointBase from "../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../context/notification-context";

const endPointBase = createEndpointBase();

export type MarginBalance = {
  asset: string;
  free: number;
  locked: number;
  borrowed: number;
  interest: number;
  netAsset: number;
};

export const useMarginBalance = () => {
  const query = `/api/margin/balance`;
  const accountId = useAccountContext();
  return useQuery<MarginBalance[]>([accountId, query], () => {
    return endPointBase.load(
      `${query}?account_id=${accountId}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useMarginTotalBalance = () => {
  const query = `/api/margin/total-balance`;
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  return useQuery<number>(
    [accountId, query],
    () => {
      return endPointBase.load(
        `${query}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const useBorrow = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();

  const accountId = useAccountContext();
  return useAsyncCallback(
    (asset: string, amount: number) => {
      return endPointBase.load(
        `/api/margin/borrow?account_id=${accountId}`,
        endPointBase.createPostOptions({
          asset,
          amount,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useMaxBorrowable = (asset: string) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();

  return useAsync(
    async (asset: string, accountId: number) => {
      const result: number = await endPointBase.load(
        `/api/margin/max-borrowable/${asset}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );

      return result;
    },
    [asset, accountId],
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useRepay = (onSuccess: () => void) => {
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    (asset: string, amount: number) => {
      return endPointBase.load(
        `/api/margin/repay?account_id=${accountId}`,
        endPointBase.createPostOptions({
          asset,
          amount,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useMaxRepayable = (asset: string) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();

  return useAsync(
    async (asset: string, accountId: number) => {
      const result: number = await endPointBase.load(
        `/api/margin/max-repayable/${asset}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );

      return result;
    },
    [asset, accountId],
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useRecharge = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  return useAsyncCallback(
    (asset: string, amount: number) => {
      return endPointBase.load(
        `/api/margin/recharge?account_id=${accountId}`,
        endPointBase.createPostOptions({
          asset,
          amount,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useWithdraw = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();

  return useAsyncCallback(
    (asset: string, amount: number) => {
      return endPointBase.load(
        `/api/margin/withdraw?account_id=${accountId}`,
        endPointBase.createPostOptions({
          asset,
          amount,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useMaxTransferable = (asset: string) => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();

  return useAsync(
    async (asset: string, accountId: number) => {
      const result: number = await endPointBase.load(
        `/api/margin/max-transferable/${asset}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );

      return result;
    },
    [asset, accountId],
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useLiquidationPrice = () => {
  const notificationDispatch = useNotificationDispatch();
  const accountId = useAccountContext();
  const query = `/api/margin/liquidation-price/BTC`;

  return useQuery<number>(
    [query],
    async () => {
      const result: number = await endPointBase.load(
        `${query}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );

      return result;
    },
    {
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};
