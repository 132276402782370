import { useAsync, useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import { Params } from "../algorithm2-params";
import createEndpointBase from "../api/utils/endpoint-base";
import { useAccountContext } from "../context/account-context";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../context/notification-context";
import { State } from "./trade-api";
import { useInteractionContext } from "../context/interaction-context";
import { Wallet } from "../model";

const endPointBase = createEndpointBase();

export type DataItem = {
  k: number;
  n: number;
  i: number;
  m: number;
  t: Date;
  pok: number;
  pr: number;
  kpok: number;
  kpr: number;
  bb: number;
  bv: number;
};

export type Total = {
  rez: number;
  z: number;
  mm: number;
  rent: number;
  lastBb: number;
  lastBv: number;
  lastX: number;
};

type Algorithm2Result = {
  table: DataItem[];
  total: Total;
};

export const useAlgorithm2DataCallback = () => {
  return useAsyncCallback(
    async (
      date: string,
      end: string,
      f: number,
      pok: number,
      pr: number,
      ticker: string
    ): Promise<Algorithm2Result | null> => {
      if (!date) {
        return null;
      }

      return endPointBase.load(
        `/api/algorithm_2/analysis?date=${date}&end=${end}&f=${f}&pok=${pok}&pr=${pr}`,
        endPointBase.createGetOptions()
      );
    }
  );
};

export const useAlgorithm2TradeDebug = () => {
  const { ticker } = useInteractionContext();
  const key = "/api/algorithm_2/debug";
  const accountId = useAccountContext();
  return useQuery<Algorithm2Result | null>([key, accountId, ticker], () =>
    endPointBase.load(
      `${key}/${ticker}?account_id=${accountId}`,
      endPointBase.createGetOptions()
    )
  );
};

export const useAlgorithm2TradeStart = () => {
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    async (
      accountId: number,
      ticker: string,
      params: Params
    ): Promise<State | null> => {
      return endPointBase.load(
        `/api/algorithm_2/start/${ticker}?account_id=${accountId}`,
        endPointBase.createPostOptions(params)
      );
    },
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const useAlgorithm2TradeStop = () => {
  return useAsyncCallback(
    async (
      accountId: number,
      ticker: string,
      wallet: Wallet
    ): Promise<State | null> => {
      return endPointBase.load(
        `/api/algorithm_2/stop/${wallet}/${ticker}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      );
    }
  );
};

export const useAlgorithm2TradeParams = () => {
  const accountId = useAccountContext();
  const { ticker } = useInteractionContext();
  return useAsync(
    (): Promise<(Params & { q: number; sd: number }) | null> =>
      endPointBase.load(
        `/api/algorithm_2/params/${ticker}?account_id=${accountId}`,
        endPointBase.createGetOptions()
      ),
    [accountId, ticker]
  );
};

export const useAlgorithm2SetTradeParams = (onSuccess: () => void) => {
  const accountId = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  const { ticker } = useInteractionContext();
  return useAsyncCallback(
    (
      data: Params & { sd: number }
    ): Promise<(Params & { sd: number }) | null> =>
      endPointBase.load(
        `/api/algorithm_2/params/${ticker}?account_id=${accountId}`,
        endPointBase.createPostOptions(data)
      ),
    { ...createErrorHandlingOptions(notificationDispatch), onSuccess }
  );
};
