import { createNumberColumnFormatter, formatNumber8 } from "../utils/format";
import Box from "@mui/material/Box";
import { DataGrid, GridApi, GridCellValue } from "@mui/x-data-grid";
import { MarginBalance } from "../services/margin";
import Button from "@mui/material/Button/Button";
import renderAssetCell from "../components/grid/render-asset-cell";

const columns = [
  {
    field: "asset",
    headerName: "Монета",
    width: 130,
    type: "string",
    renderCell: renderAssetCell,
  },
  {
    field: "free",
    headerName: "Доступно",
    type: "number",
    width: 150,
    valueFormatter: createNumberColumnFormatter(formatNumber8),
  },
  {
    field: "locked",
    headerName: "Заблокировано",
    type: "number",
    width: 150,
    valueFormatter: createNumberColumnFormatter(formatNumber8),
  },
  {
    field: "borrowed",
    headerName: "Долг",
    type: "number",
    width: 150,
    valueFormatter: createNumberColumnFormatter(formatNumber8),
  },
];

type Props = {
  data: MarginBalance[];
  isLoading: boolean;
  onRepayClick: (asset: string) => void;
  onBorrowClick: (asset: string) => void;
  onRechargeClick: (asset: string) => void;
  onWithdrawClick: (asset: string) => void;
};

export const MarginBalanceTable = ({
  data,
  isLoading,
  onRepayClick,
  onBorrowClick,
  onRechargeClick,
  onWithdrawClick,
}: Props) => {
  const columnsWithAction = [
    ...columns,
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 450,
      renderCell: (params: any) => {
        const getAsset = (
          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ): string => {
          e.stopPropagation(); // don't select this row after clicking

          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          return thisRow.asset?.toString() || "";
        };

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Button
              onClick={(e) => {
                const asset = getAsset(e);
                onBorrowClick(asset);
              }}
            >
              Взять
            </Button>
            <span>/</span>
            <Button
              onClick={(e) => {
                const asset = getAsset(e);
                onRepayClick(asset);
              }}
            >
              Вернуть
            </Button>
            <span>/</span>
            <Button
              onClick={(e) => {
                const asset = getAsset(e);
                onRechargeClick(asset);
              }}
            >
              Пополнить
            </Button>
            <span>/</span>
            <Button
              onClick={(e) => {
                const asset = getAsset(e);
                onWithdrawClick(asset);
              }}
            >
              Вывести
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box style={{ flexGrow: 1 }}>
      <DataGrid
        getRowId={(x) => x.asset}
        rows={data}
        columns={columnsWithAction}
        disableSelectionOnClick
        loading={isLoading}
      />
    </Box>
  );
};

export default MarginBalanceTable;
