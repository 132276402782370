import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useCancelOrder } from "../../services/order-api";

type Props = {
  onClose: () => void;
  orderNumber: number;
};

const CloseOrderDialog = ({ onClose, orderNumber }: Props) => {
  const cancelOrder = useCancelOrder(onClose);

  return (
    <Box sx={{ width: 500, color: (theme) => theme.palette.grey[400] }}>
      <DialogTitle>Отмена заявки</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          flexGrow: 1,
          flexShrink: 1,
          flex: 1,
        }}
      >
        Отменить заявку № {orderNumber}?
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Оставить заявку
        </Button>
        <Button
          disabled={cancelOrder.loading}
          onClick={() => cancelOrder.execute(orderNumber)}
        >
          Отменить заявку
        </Button>
      </DialogActions>
    </Box>
  );
};

export default CloseOrderDialog;
