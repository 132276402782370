import React from "react";

export enum NotificationType {
  error = "error",
  success = "success",
}

type NotificationState = {
  message: string;
  type: NotificationType;
};

type NotificationAction =
  | { type: "error"; payload: string }
  | { type: "success"; payload: string }
  | { type: "reset" };

const initialState: NotificationState = {
  message: "",
  type: NotificationType.error,
};

const NotificationStateContext =
  React.createContext<NotificationState>(initialState);

const NotificationDispatchContext = React.createContext<
  React.Dispatch<NotificationAction>
>(() => {});

function notificationReducer(
  state: NotificationState,
  action: NotificationAction
): NotificationState {
  switch (action.type) {
    case "error": {
      return {
        type: NotificationType.error,
        message: action.payload,
      };
    }
    case "success": {
      return {
        type: NotificationType.success,
        message: action.payload,
      };
    }
    case "reset": {
      return {
        type: NotificationType.error,
        message: "",
      };
    }
  }
}

export const NotificationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(notificationReducer, initialState);
  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};

export function useNotificationState(): NotificationState {
  return React.useContext(NotificationStateContext);
}

export function useNotificationDispatch() {
  return React.useContext(NotificationDispatchContext);
}

export const createErrorHandlingOptions = (
  notificationDispatch: React.Dispatch<NotificationAction>
) => ({
  onError: (e: unknown) => {
    if (e instanceof Error) {
      notificationDispatch({
        type: "error",
        payload: e.message,
      });
    } else {
      notificationDispatch({
        type: "error",
        payload: "Неизвестная ошибка",
      });
    }
  },
});
