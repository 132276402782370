import ModalBase from "@mui/material/Modal";
import Box from "@mui/material/Box";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Modal: React.FC<Props> = ({ isOpen, onClose, children }) => (
  <ModalBase open={isOpen} onClose={onClose}>
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        outline: "none",
      }}
    >
      {children}
    </Box>
  </ModalBase>
);

export default Modal;
