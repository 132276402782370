const STORAGE_KEY = "BINANCE_TRADE_BOT_APP_4";
export type AppSettings = {
  accountId: number;
  bcsAccount: string;
  bcsFuturesAccount: string;
  lastPage: string;
  ticker: string;
  showAvgPrice: boolean;
};

export const getAppSettings = () => {
  const storedObject: Partial<AppSettings> = JSON.parse(
    localStorage.getItem(STORAGE_KEY) ||
      JSON.stringify({
        lastPage: "/binance/trading/algorithm2",
        showAvgPrice: true,
      })
  );
  return storedObject;
};

export const setAppSettings = (settings: Partial<AppSettings>) => {
  const mergedObject = {
    ...getAppSettings(),
    ...settings,
  };

  localStorage.setItem(STORAGE_KEY, JSON.stringify(mergedObject));
  return mergedObject;
};
