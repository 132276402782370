import { useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import createEndpointBase from "../../../api/utils/endpoint-base";
import {
  useNotificationDispatch,
  createErrorHandlingOptions,
} from "../../../context/notification-context";

const endPointBase = createEndpointBase();

export type Account = {
  asset: string;
  notificationsEnabled: boolean;
};

export const useAccounts = () => {
  const key = "/api/bcs/futures-accounts";
  const { data, isLoading, error, refetch } = useQuery<Account[]>(key, () => {
    return endPointBase.load(key, endPointBase.createGetOptions());
  });

  return { data, isLoading, error, refetch };
};

export const useAddAccount = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    async (data: Account) => {
      return endPointBase.load(
        "/api/bcs/futures-accounts",
        endPointBase.createPostOptions(data)
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useSetAccountNotifications = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    async (data: Pick<Account, "asset" | "notificationsEnabled">) => {
      return endPointBase.load(
        "/api/bcs/futures-accounts/notifications",
        endPointBase.createPostOptions(data)
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};
