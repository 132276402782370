import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { formatNumber2 } from "../../utils/format";
import WalletSelector from "../wallet-selector";
import FormControl from "@mui/material/FormControl";
import { Wallet } from "../../model";
import { usePriceStream } from "../../services/trade-api";
import { useInteractionContext } from "../../context/interaction-context";
import { TickerInfo, useTickerInfo } from "../../services/api";
import CircularProgress from "@mui/material/CircularProgress";
import { getPrecision } from "../../utils/number";
import { useQueryClient } from "react-query";
import Checkbox from "@mui/material/Checkbox";
import { useGetAllowedWallets } from "../../services/accounts";

const getErrorText = (
  price: number,
  amount: number,
  tickerInfo: TickerInfo | undefined
) => {
  if (!tickerInfo) {
    return "Загрузка...";
  }

  if (isNaN(amount)) {
    return "Введите число";
  }

  if (amount < tickerInfo.minQty) {
    return `Минимальное количество ${tickerInfo.minQty} ${tickerInfo.baseAsset}`;
  }

  if (amount * price < tickerInfo.minNotional) {
    return `Минимальное объем ${tickerInfo.minNotional} ${tickerInfo.quoteAsset}`;
  }

  if (getPrecision(amount) > getPrecision(tickerInfo.stepSize)) {
    return `Шаг цены ${tickerInfo.stepSize}`;
  }
};

const getPriceErrorText = (
  price: number,
  tickerInfo: TickerInfo | undefined
) => {
  if (!tickerInfo) {
    return "";
  }
  if (isNaN(price)) {
    return "Введите число";
  }
  if (getPrecision(price) > getPrecision(tickerInfo.tickSize)) {
    return `Шаг цены ${tickerInfo.tickSize}`;
  }
};

type Props = {
  onClose: () => void;
  onSubmit: (
    amount: number,
    price: number,
    wallet: Wallet,
    orderType: string
  ) => void;
  isLoading: boolean;
  maxValue?: number;
  title: string;
  q: number;
  initialWallet: Wallet;
};

const BaseOrderDialog = ({
  onClose,
  onSubmit,
  title,
  isLoading,
  maxValue = 0,
  initialWallet,
  q,
}: Props) => {
  const [amount, setAmount] = useState("0");
  const value = parseFloat(amount);
  const queryClient = useQueryClient();
  const currentPrice = usePriceStream();
  const [wallet, setWallet] = useState(initialWallet);
  const { ticker } = useInteractionContext();
  const tickerInfoResponse = useTickerInfo(ticker);
  const tickerInfo = tickerInfoResponse.data;
  const step = Math.max(q, tickerInfo?.stepSize || 0);

  const allowedWallets = useGetAllowedWallets();

  const tickSize = tickerInfo?.tickSize || 1;

  const [market, setMarket] = useState(false);

  const [price, setPrice] = useState(currentPrice.toString());

  const selectedPrice = market ? currentPrice : Number(price);

  useEffect(() => {
    setAmount(Math.max(step, tickerInfo?.minQty || 0).toString());
  }, [step, tickerInfo?.minQty]);

  useEffect(() => {
    if (!parseFloat(price) && currentPrice) {
      setPrice(currentPrice.toString());
    }
  }, [price, currentPrice]);

  const errorMessage = getErrorText(currentPrice, value, tickerInfo);

  const isValid = !errorMessage;

  const priceErrorMessage = getPriceErrorText(Number(price), tickerInfo);

  const isPriceValid = !priceErrorMessage;

  return (
    <Box sx={{ width: 600, color: (theme) => theme.palette.grey[400] }}>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {title}
        {tickerInfoResponse.isLoading || isLoading ? (
          <CircularProgress sx={{ marginLeft: "auto" }} size={16} />
        ) : null}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          flexGrow: 1,
          flexShrink: 1,
          flex: 1,
        }}
      >
        {!market && (
          <FormControl
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              flex: 1,
            }}
          >
            <TextField
              label={`Цена`}
              autoFocus
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              inputProps={{
                step: tickSize.toString(),
              }}
              error={!isPriceValid}
              helperText={priceErrorMessage}
              FormHelperTextProps={{
                sx: {
                  position: "absolute",
                  left: 0,
                  bottom: -25,
                },
              }}
              type="number"
              margin="dense"
            />
          </FormControl>
        )}
        <FormControl
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            flex: 1,
          }}
        >
          <TextField
            label={`Количество (≈${formatNumber2(value * selectedPrice)} USDT)`}
            autoFocus
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            inputProps={{
              step: step.toString(),
            }}
            error={!isValid}
            helperText={errorMessage}
            FormHelperTextProps={{
              sx: {
                position: "absolute",
                left: 0,
                bottom: -25,
              },
            }}
            type="number"
            margin="dense"
          />
        </FormControl>
        <WalletSelector
          wallet={wallet}
          onWalletChange={setWallet}
          allowedWallets={allowedWallets.data}
        />
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={market}
            onChange={(e) => setMarket(e.target.checked)}
          />
          <Box>Рыночная заявка</Box>
        </Box>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Отмена
        </Button>
        <Button
          disabled={isLoading || !isValid || !price || !isPriceValid}
          onClick={() => {
            onSubmit(value, selectedPrice, wallet, market ? "MARKET" : "LIMIT");
            queryClient.invalidateQueries();
          }}
        >
          {title}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default BaseOrderDialog;
