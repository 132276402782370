import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { useState } from "react";
import { useAddTicker, useAssetList } from "../services/api";
import CoinSelector from "./coin-selector";

type Props = {
  onClose: () => void;
};

const AddTickerDialog = ({ onClose }: Props) => {
  const [base, setBase] = useState("");
  const [quote, setQuote] = useState("");

  const coinInfoResponse = useAssetList();

  const coinInfo = coinInfoResponse.data || [];

  const addATicker = useAddTicker(onClose);

  return (
    <Box sx={{ width: 500 }}>
      <DialogTitle sx={{ color: (theme) => theme.palette.grey[400] }}>
        Добавить пару
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          margin: 1,
          height: 80,
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <CoinSelector
          options={coinInfo}
          value={base}
          onValueChange={setBase}
          label="Базисная валюта"
        />
        <CoinSelector
          options={coinInfo}
          value={quote}
          onValueChange={setQuote}
          label="Котируемая валюта"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button
          disabled={addATicker.loading || !quote || !base}
          onClick={() => {
            addATicker.execute({
              base,
              quote,
            });
          }}
        >
          Добавить
        </Button>
      </DialogActions>
    </Box>
  );
};

export default AddTickerDialog;
