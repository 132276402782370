import Box from "@mui/material/Box";
import { useState } from "react";
import Modal from "../components/modal";
import BorrowDialog from "../components/loan-dialog/margin-borrow-dialog";
import {
  // useLiquidationPrice,
  useMarginBalance,
  useMarginTotalBalance,
} from "../services/margin";
import MarginBalanceTable from "./balance-table";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatNumber0 } from "../utils/format";
import AutoLoansRatio from "./auto-loans-ratio";
import { useQueryClient } from "react-query";
import MarginRechargeDialog from "../components/loan-dialog/margin-recharge-dialog";
import MarginRepayDialog from "../components/loan-dialog/margin-repay-dialog";
import MarginWithdrawDialog from "../components/loan-dialog/margin-withdraw-dialog";
import { Wallet } from "../model";
import OrderDialog from "../components/order-dialog";

function App() {
  const queryClient = useQueryClient();
  const [borrowDialogOpen, setBorrowDialogOpen] = useState("");
  const [repayDialogOpen, setRepayDialogOpen] = useState("");
  const [rechargeDialogOpen, setRechargeDialogOpen] = useState("");
  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState("");
  const totalBalance = useMarginTotalBalance();

  // const liquidationPrice = useLiquidationPrice();

  const response = useMarginBalance();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <AutoLoansRatio
        totalBalance={totalBalance.data || 0}
        multiplier={2}
        wallet={Wallet.MARGIN}
      />
      <MarginBalanceTable
        data={response.data || []}
        isLoading={response.isLoading}
        onBorrowClick={(asset) => setBorrowDialogOpen(asset)}
        onRepayClick={(asset) => setRepayDialogOpen(asset)}
        onRechargeClick={(asset) => setRechargeDialogOpen(asset)}
        onWithdrawClick={(asset) => setWithdrawDialogOpen(asset)}
      />
      <Paper sx={{ display: "flex", gap: 3, padding: 2 }}>
        {/* <Typography variant="body1">
          Цена ликвидации: {formatNumber0(liquidationPrice.data || 0)}
        </Typography> */}
        <Typography variant="body1">
          Собственные средства: {formatNumber0(totalBalance.data || 0)} USDT
        </Typography>
        <Box sx={{ marginLeft: "auto" }}>
          <OrderDialog q={0} onClose={() => {}} wallet={Wallet.MARGIN} />
        </Box>
      </Paper>
      <Modal
        isOpen={!!borrowDialogOpen}
        onClose={() => setBorrowDialogOpen("")}
      >
        <BorrowDialog
          asset={borrowDialogOpen}
          onClose={() => {
            setBorrowDialogOpen("");
            queryClient.invalidateQueries();
          }}
        />
      </Modal>
      <Modal isOpen={!!repayDialogOpen} onClose={() => setRepayDialogOpen("")}>
        <MarginRepayDialog
          asset={repayDialogOpen}
          onClose={() => {
            setRepayDialogOpen("");
            queryClient.invalidateQueries();
          }}
        />
      </Modal>
      <Modal
        isOpen={!!rechargeDialogOpen}
        onClose={() => setRechargeDialogOpen("")}
      >
        <MarginRechargeDialog
          asset={rechargeDialogOpen}
          onClose={() => {
            setRechargeDialogOpen("");
            queryClient.invalidateQueries();
          }}
        />
      </Modal>
      <Modal
        isOpen={!!withdrawDialogOpen}
        onClose={() => setWithdrawDialogOpen("")}
      >
        <MarginWithdrawDialog
          asset={withdrawDialogOpen}
          onClose={() => {
            setWithdrawDialogOpen("");
            queryClient.invalidateQueries();
          }}
        />
      </Modal>
    </Box>
  );
}

export default App;
