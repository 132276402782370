import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import Arrow from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";

const AnalysisNavigation = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        sx={{
          margin: 0,
          padding: 0,
          textTransform: "none",
        }}
        endIcon={<Arrow />}
      >
        <Typography variant="body1">Анализ</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link
            component={NavLink}
            underline="hover"
            to="/binance/analysis/algorithm1"
          >
            Алгоритм 1
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link component={NavLink} underline="hover" to="/binance/algorithm2">
            Алгоритм 2
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AnalysisNavigation;
