import { useInteractionContext } from "../../../context/interaction-context";
import { useTradeTotalMargin } from "../../services/trade-api";
import { TotalMarginChartCore } from "./total-margin-chart-core";

export const AssetTotalMarginChart = () => {
  const { from, to } = useInteractionContext();
  const response = useTradeTotalMargin(from, to);

  return <TotalMarginChartCore data={response.data || []} />;
};
