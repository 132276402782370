import Box from "@mui/material/Box";
import ChartCore, {
  ArgumentAxis,
  Legend,
  IVisualRangeProps,
  ZoomAndPan,
  CommonSeriesSettings,
  Series,
  Point,
  Tooltip,
} from "devextreme-react/chart";
import { useMemo, useState } from "react";
import { isToday } from "date-fns";
import { useInteractionContext } from "../../../context/interaction-context";
import { TotalMargin } from "../../services/trade-api";

const minVisualRangeLength = { minutes: 10 };

type Props = {
  data: TotalMargin[];
};

export const TotalMarginChartCore = ({ data }: Props) => {
  const { from, to } = useInteractionContext();

  const endDate = new Date(to);

  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  const [visualRange, setVisualRange] = useState<IVisualRangeProps>({
    startValue: from,
    endValue: isToday(new Date(to))
      ? new Date().toString()
      : endDate.toString(),
  });

  const defaultVisualRange = useMemo(() => ({ startValue: from }), [from]);

  return (
    <Box
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          flexGrow: 1,
        }}
      >
        <ChartCore
          height="100%"
          dataSource={data}
          onLegendClick={(e: any) => {
            e.target.isVisible() ? e.target.hide() : e.target.show();
          }}
          autoHidePointMarkers={false}
        >
          <CommonSeriesSettings argumentField="createdAt" type="line">
            <Point visible={false} />
          </CommonSeriesSettings>
          <Series name={"Margin"} valueField="margin" />
          <Series name={"Value"} valueField="value" visible={false} />

          <ZoomAndPan argumentAxis="both" />
          <ArgumentAxis
            defaultVisualRange={defaultVisualRange}
            minVisualRangeLength={minVisualRangeLength}
            visualRange={visualRange}
            onVisualRangeChange={setVisualRange}
            argumentType="datetime"
          />

          <Legend
            visible={true}
            verticalAlignment="bottom"
            horizontalAlignment="center"
          />
          <Tooltip enabled format={{ precision: 0 }} />
        </ChartCore>
      </Box>
    </Box>
  );
};
