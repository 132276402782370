import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useAddAccount } from "../services/accounts";
import CurrencySelector, { Currency } from "./currency-selector";

type Props = {
  onClose: () => void;
};

const AddAccountDialog = ({ onClose }: Props) => {
  const [asset, setAsset] = useState("");
  const [currency, setCurrency] = useState<Currency>("SUR");

  const addAccount = useAddAccount(onClose);

  return (
    <Box sx={{ width: 1000 }}>
      <DialogTitle sx={{ color: (theme) => theme.palette.grey[400] }}>
        Добавить аккаунт
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Asset"
          autoFocus
          value={asset}
          onChange={(e) => setAsset(e.target.value)}
          fullWidth
          margin="dense"
        ></TextField>
        <CurrencySelector value={currency} onChange={setCurrency} />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Отмена
        </Button>
        <Button
          disabled={addAccount.loading || !asset}
          onClick={() => {
            addAccount.execute({
              asset,
              notificationsEnabled: true,
              currency,
            });
          }}
        >
          Добавить
        </Button>
      </DialogActions>
    </Box>
  );
};

export default AddAccountDialog;
