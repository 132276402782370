import Box from "@mui/material/Box";
import { useAllTrades, useTradeMargin } from "../services/trade-api";
import { useInteractionContext } from "../../context/interaction-context";
import { PivotGrid } from "devextreme-react";
import { useMemo } from "react";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { StateStoring } from "devextreme-react/pivot-grid";
import { fields } from "./all-trade-pivot-fields";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatNumber2 } from "../../utils/format";

const PivotComponent: any = PivotGrid;
const StateStoringComponent: any = StateStoring;

export const AllTradesPivotTable = () => {
  const { from, to } = useInteractionContext();
  const dataResponse = useAllTrades(from, to);

  const marginResponse = useTradeMargin(from, to);

  const dataSource = useMemo(() => {
    return new PivotGridDataSource({
      fields: fields,
      store: dataResponse.data || [],
    });
  }, [dataResponse.data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <PivotComponent
          dataSource={dataSource}
          allowFiltering
          allowSorting
          allowSortingBySummary
        >
          <StateStoringComponent
            enabled
            type="localStorage"
            storageKey="binance-TradeBot-pivotgrid-storing2"
          />
        </PivotComponent>
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2 }}>
        <Typography variant="body1">
          FIFO Margin:{" "}
          {formatNumber2(
            (marginResponse.data?.fifo || 0) -
              (marginResponse.data?.commission || 0)
          )}
        </Typography>
      </Paper>
    </Box>
  );
};

export default AllTradesPivotTable;
